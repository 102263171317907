import * as angular from 'angular';
import * as moment from 'moment';
import toastr from 'toastr';
import { Convert, Js } from '../../utils';

angular
  .module('screeningcanvas')
  .controller('ProgettoDettaglioCtrl', ProgettoDettaglioCtrl);

  ProgettoDettaglioCtrl.$inject = ['$scope', 'newApiClient', '$stateParams', 'User', '$state', '$uibModal'];

  function ProgettoDettaglioCtrl($scope, newApiClient, $stateParams, User, $state, $uibModal) {
    $scope.now = (new Date()).getTime();
    $scope.activeTab = getActiveTabIndex();

    $scope.user = User.getUser();
    $scope.project = [];

    $scope.activityFilter = {
      taskId: null,
    };
    $scope.activityPaginator = {
      page: 0,
      perPage: 50,
      totalPages: 0,
    };

    const taskFragment = `
      id
      name
      assignedArea {
        id
        name
      }
      assignedUser {
        id
        fullName
      }
      deadline
      estimatedTime
      isCompleted
      activities {
        totalTime
      }
    `;

    const activityFragment = `
      id
      duration
      description
      user {
        id
        fullName
      }
      parent {
        ... on Task {
          id
          name
        }
      }
      startsAt
      endsAt
      isBillable
    `;

    const projectExpenseFragment = `
      id
      supplier {
        id
        name
      }
      purchaseInvoice {
        id
        code
        date
      }
      price
      note
    `;

    const projectFragment = `
      id
      createdAt
      deadline
      customer {
        id
        name
      }
      type {
        id
        name
      }
      folderCode
      folderName
      title
      memberships {
        isReferent
        user {
          id
          fullName
        }
      }
      pricing {
        fixedPrice
        unitPrice
      }
      price
      billablePrice
      billedPrice
      purchaseOrderCIG
      purchaseOrderCode
      customerProductCode
      contractCode
      quantity
      isResale
      includeInTimesheet
      paymentMethod {
        id
        label
      }
      paymentTerm {
        id
        label
      }
      status {
        type
        updatedAt
      }
      unit
      isEmpty
      invoiceReferences(order: DATE_DESC) {
        id
        recipient {
          id
          name
        }
        yearlyCode
        date
        pdfFile
      }
      waybillReferences(order: DATE_DESC) {
        id
        yearlyCode
        date
        pdfFile
      }
      attachments {
        id
        description
        file {
          id
          originalFileName
          mimeType
          createdAt
          url
          sizeInBytes
        }
      }
      activities {
        totalTime
        totalBillableTime: totalTime(filter: { isBillable: true })
        totalNonBillableTime: totalTime(filter: { isBillable: false })
        count
        list(pagination: $activityPagination) {
          ${activityFragment}
        }
      }
      expenses {
        ${projectExpenseFragment}
      }
      tasks {
        ${taskFragment}
      }
      note
      administrativeNote
    `;

    $scope.loadActivities = function(page = 0) {
      page = (page == null) ? $scope.activityPaginator.page : page;
      newApiClient.call(`
        query($id: Int!, $activityPagination: Pagination!, $activityFilter: ActivityFilter!) {
          projects {
            project(id: $id) {
              activities {
                count(filter: $activityFilter)
                list(filter: $activityFilter, pagination: $activityPagination) {
                  ${activityFragment}
                }
              }
            }
          }
        }
      `, {
        id: parseInt($stateParams.id),
        activityPagination: {
          take: $scope.activityPaginator.perPage,
          skip: $scope.activityPaginator.perPage * page,
        },
        activityFilter: {
          taskIds: Convert.toSingletonList($scope.activityFilter.taskId),
        },
      }).then(function(data) {
        $scope.project.activities.list = data.projects.project.activities.list;

        const recordsCount = data.projects.project.activities.count;
        $scope.activityPaginator.totalPages = Math.ceil(recordsCount / $scope.activityPaginator.perPage);
        $scope.activityPaginator.page = page;
      }, function(errors) {
        toastr.error(errors[0].message, "Impossibile caricare le attività");
      });
    }
    $scope.filterActivitiesByTask = function(task) {
      const isFilteredBy = !!task.isFilteredBy;
      $scope.project.tasks.forEach(t => {
        t.isFilteredBy = false;
      });
      if (isFilteredBy) {
        task.isFilteredBy = false;
        $scope.activityFilter.taskId = null;
        $scope.loadActivities();
      } else {
        task.isFilteredBy = true;
        $scope.activityFilter.taskId = task.id;
        $scope.loadActivities();
      }
    };

    $scope.getProgetto = function (loading = true) {
      $scope.loading = loading;

      newApiClient.call(`
        query($id: Int!, $activityPagination: Pagination!) {
          projects {
            project(id: $id) {
              ${projectFragment}
            }
          }
        }
      `, {
        id: parseInt($stateParams.id),
        activityPagination: {
          take: $scope.activityPaginator.perPage,
          skip: 0,
        },
      }).then(function(data) {
        const loadedProject = data.projects.project;
        const totalExpenses = loadedProject.expenses.map(x => x.price).reduce((acc, x) => x + acc, 0);
        const totalGains = loadedProject.price - totalExpenses;
        $scope.project = {
          ...loadedProject,
          totalExpenses: totalExpenses,
          totalGains: totalGains,
        };

        const activitiesCount = data.projects.project.activities.count;
        $scope.activityPaginator.totalPages = Math.ceil(activitiesCount / $scope.activityPaginator.perPage);
        $scope.activityPaginator.page = 0;

        $scope.loading = false;
      }, function(errors) {
        toastr.error(errors[0].message, "Impossibile caricare i dati");
      });
    }

    $scope.fileId = null;
    $scope.removeProject = (id) => {
      if (window.confirm("Sei sicuro di voler eliminare questo progetto?")) {
        newApiClient.call(`
          mutation($projectId: Int!) {
            projects {
              removeProject(projectId: $projectId)
            }
          }`,
          { projectId: parseInt(id) },
        ).then(function(data) {
          toastr.success("Progetto eliminato");
          $state.go('progetti', {});
        }, function(errors) {
          toastr.error(errors[0].extensions.userMessage, "Impossibile eliminare il progetto", { timeOut: 0, extendedTimeOut: 0 });
        });
      }
    };

    $scope.getProgetto();

    /* Activities */
    $scope.selectedActivityCount = () => $scope.project.activities.list.filter((x) => x.isSelected).length;
    $scope.openMoveActivitiesModal = function() {
      var selectedIds = $scope.project.activities.list.filter((x) => x.isSelected).map((x) => x.id);
      $uibModal.open({
        templateUrl: 'templates/extras/modal_move.html',
        controller: 'ModalExtraMoveCtrl',
        resolve: {},
      }).result.then(function (data) {
        newApiClient.call(`
          mutation($input: UpdateActivityBatchInput!) {
            activities {
              updateActivityBatch(input: $input) {
                id
              }
            }
          }
        `, {
          input: {
            activityIds: selectedIds,
            parent: {
              taskId: parseInt(data.taskId),
            }
          },
        }).then(function(data) {
          toastr.success('Attività spostate correttamente!');
          $scope.getProgetto(false);
        }, function() {
          toastr.error("Non è stato possibile spostare le attività");
        });
      });
    };
    $scope.openEditActivityModal = function(activity) {
      $uibModal.open({
        templateUrl: 'templates/progettodettaglio/modals/edit_activity.html',
        controller: 'ModalProjectEditActivityCtrl',
        resolve: {
          title: function() { return 'Modifica attività'; },
          data: function () {
            return {
              description: activity.description,
              isBillable: activity.isBillable,
            };
          }
        },
      }).result.then(function (input) {
        newApiClient.call(`
          mutation($input: UpdateActivityInput!) {
            activities {
              updateActivity(input: $input) {
                ${activityFragment}
              }
            }
          }
        `,
        {
          input: {
            activityId: activity.id,
            description: input.description,
            isBillable: input.isBillable,
          },
        }).then(function(response) {
          toastr.success('Attività modificata!');
          angular.extend(activity, response.activities.updateActivity);
        }, function(error) {
          toastr.error('Attività non modificata!');
          console.error(error);
        });
      });
    };

    /* Pricing */
    $scope.aggiornaPrezzoProgetto = function() {
      const input = {
        projectId: parseInt($scope.project.id),
        pricing: (!Js.isNull($scope.project.pricing.unitPrice))
          ? { unitPrice: parseFloat($scope.project.pricing.unitPrice_temp) }
          : ((!Js.isNull($scope.project.pricing.fixedPrice))
            ? { fixedPrice : parseFloat($scope.project.pricing.fixedPrice_temp) }
            : null),
        administrativeNote: Convert.toNonEmptyString($scope.project.administrativeNote_temp),
      };

      newApiClient.call(`
        mutation($input: UpdateProjectInput!) {
          projects {
            updateProject(input: $input) {
              id
            }
          }
        }
      `, { input: input })
      .then(function(data) {
        $scope.project.editing = false;
        $scope.getProgetto(false);
        toastr.success("Prezzo modificato");
      }, function(error) {
        toastr.error("Impossibile modificare progetto");
      });
    };

    $scope.editCostoProgetto = function() {
      $scope.project.editing = true;
      $scope.project.pricing.fixedPrice_temp = $scope.project.pricing.fixedPrice;
      $scope.project.pricing.unitPrice_temp = $scope.project.pricing.unitPrice;
      $scope.project.administrativeNote_temp = $scope.project.administrativeNote;
    }

    $scope.duplicaProgetto = function() {
      if (confirm("ATTENZIONE!\n\nSei sicuro? Procedo alla copia del progetto?\nIl progetto verrà subito salvato!")) {
        newApiClient.call(`
          mutation ($id: Int!) {
            projects {
              copyProject(input: { projectId: $id, copyTasks: true, copyEstimate: true }) {
                id
              }
            }
          }
        `, {
          id: parseInt($scope.project.id),
        }).then(function(data) {
          const newId = data.projects.copyProject.id;
          $state.go('progettoedit', { id: newId });
        }, function(errors) {
          toastr.error(errors[0].message, "Impossibile duplicare il progetto");
        });
      };
    };

    $scope.updateProjectStatus = function(statusType) {
      newApiClient.call(`
        mutation ($input: UpdateProjectInput!) {
          projects {
            updateProject(input: $input) {
              id
            }
          }
        }
      `, {
        input: {
          projectId: parseInt($scope.project.id),
          status: {
            type: statusType,
            date: statusType != 'OPEN'
              ? moment().format('YYYY-MM-DD')
              : null,
          },
        }
      }).then(function(data) {
        toastr.success("Stato progetto aggiornato");
        $scope.getProgetto();
      }, function(error) {
        toastr.error("Impossibile modificare stato progetto");
        console.error(error);
      });
    };

    $scope.toggleCompleteTask = function(task) {
      newApiClient.call(`
        mutation ($taskId: Int!) {
          tasks {
            toggleCompleteTask(taskId: $taskId) {
              ${taskFragment}
            }
          }
        }
      `, { taskId: task.id }).then(function(data) {
        const newTask = data.tasks.toggleCompleteTask;
        angular.extend(task, newTask);
        toastr.success("Stato task aggiornato");
      }, function(error) {
        toastr.error("Impossibile aggiornare stato task");
        console.error(error);
      });
    };

    function getActiveTabIndex() {
      var at = 0;
      if(typeof $stateParams.tab !== 'undefined') {
        switch ($stateParams.tab) {
          case 'estimate': at = 1; break;
          case 'contracts': at = 2; break;
          case 'task': at = 3; break;
          case 'costs':
            at = 4;
            break;
        }
      }

      return at;
    }

    /* Task */
    $scope.openNewTaskModal = function(project) {
      $uibModal.open({
        templateUrl: 'templates/progettodettaglio/modals/edit_task.html',
        controller: 'ModalProjectEditTaskCtrl',
        resolve: {
          title: function() { return 'Nuovo task'; },
          data: function () {
            return {
              assignedAreaId: null,
              assignedUserId: null,
              deadline: moment().format('YYYY-MM-DD'),
              estimatedTime: '0:01:00:00',
              name: '',
            };
          }
        },
      }).result.then(function (input) {
        newApiClient.call(`
          mutation($input: CreateTaskInput!) {
            tasks {
              createTask(input: $input) {
                id
              }
            }
          }
        `,
        {
          input: {
            projectId: project.id,
            assignedAreaId: input.assignedAreaId,
            assignedUserId: input.assignedUserId,
            deadline: input.deadline,
            estimatedTime: input.estimatedTime,
            name: input.name,
          },
        }).then(function(response) {
          toastr.success('Task creato!');
          $scope.getProgetto(false);
        }, function(errors) {
          toastr.error(errors[0].extensions.userMessage, 'Non è stato possibile creare il task!');
        });
      });
    };
    $scope.openEditTaskModal = function(task) {
      $uibModal.open({
        templateUrl: 'templates/progettodettaglio/modals/edit_task.html',
        controller: 'ModalProjectEditTaskCtrl',
        resolve: {
          title: function() { return 'Modifica task'; },
          data: function () {
            return {
              assignedAreaId: task.assignedArea?.id,
              assignedUserId: task.assignedUser?.id,
              deadline: task.deadline,
              estimatedTime: task.estimatedTime,
              name: task.name,
            };
          }
        },
      }).result.then(function (input) {
        newApiClient.call(`
          mutation($input: UpdateTaskInput!) {
            tasks {
              updateTask(input: $input) {
                ${taskFragment}
              }
            }
          }
        `,
        {
          input: {
            taskId: task.id,
            assignedAreaId: input.assignedAreaId,
            assignedUserId: input.assignedUserId,
            deadline: input.deadline,
            estimatedTime: input.estimatedTime,
            name: input.name,
          },
        }).then(function(response) {
          toastr.success('Task modificato!');
          angular.extend(task, response.tasks.updateTask);
        }, function(errors) {
          toastr.error(errors[0].extensions.userMessage, 'Non è stato possibile modificare il task!');
        });
      });
    };
    $scope.openDeleteTaskModal = function(task) {
      $uibModal.open({
        templateUrl: `templates/progettodettaglio/modals/question.html?t=${new Date()}`,
        controller: 'ModalProjectQuestionCtrl',
        resolve: {
          title: function() { return 'Eliminazione task'; },
          question: function () { return 'Sei sicuro di voler rimuovere questo task?'; }
        },
      }).result.then(function (input) {
        newApiClient.call(`
          mutation($id: Int!) {
            tasks {
              removeTask(taskId: $id)
            }
          }
        `,
        { id: task.id }).then(function(response) {
          toastr.success('Task rimosso!');
          $scope.getProgetto(false);
        }, function(errors) {
          toastr.error(errors[0].extensions.userMessage, 'Task non rimosso!');
        });
      });
    };

    /* Expenses */
    $scope.openNewExpenseModal = function() {
      const projectId = $scope.project.id;
      $uibModal.open({
        templateUrl: 'templates/progettodettaglio/modals/edit_expense.html',
        controller: 'ModalProjectEditExpenseCtrl',
        resolve: {
          title: function() { return 'Nuova spesa'; },
          data: function () {
            return {
              supplierId: null,
              price: 0,
              note: null,
            };
          }
        },
      }).result.then(function (input) {
        newApiClient.call(`
          mutation($input: CreateProjectExpenseInput!) {
            projectExpenses {
              createProjectExpense(input: $input) {
                id
              }
            }
          }
        `,
        {
          input: {
            projectId: projectId,
            supplierId: input.supplierId,
            price: input.price,
            note: input.note,
          },
        }).then(function(response) {
          toastr.success('Spesa creata!');
          $scope.getProgetto(false);
        }, function(errors) {
          toastr.error(errors[0].extensions.userMessage, 'Spesa non creata!');
        });
      });
    };
    $scope.openEditExpenseModal = function(projectExpense) {
      $uibModal.open({
        templateUrl: 'templates/progettodettaglio/modals/edit_expense.html',
        controller: 'ModalProjectEditExpenseCtrl',
        resolve: {
          title: function() { return 'Modifica spesa'; },
          data: function () {
            return {
              supplierId: projectExpense.supplier?.id,
              price: projectExpense.price,
              note: projectExpense.note,
            };
          }
        },
      }).result.then(function (input) {
        newApiClient.call(`
          mutation($id: Int!, $input: UpdateProjectExpenseInput!) {
            projectExpenses {
              updateProjectExpense(projectExpenseId: $id, input: $input) {
                ${projectExpenseFragment}
              }
            }
          }
        `,
        {
          id: projectExpense.id,
          input: {
            supplierId: input.supplierId,
            price: input.price,
            note: input.note,
          },
        }).then(function(response) {
          toastr.success('Spesa modificata!');
          angular.extend(projectExpense, response.projectExpenses.updateProjectExpense);
          $scope.getProgetto(false);
        }, function(errors) {
          toastr.error(errors[0].extensions.userMessage, 'Spesa non modificata!');
        });
      });
    };
    $scope.openDeleteExpenseModal = function(projectExpense) {
      $uibModal.open({
        templateUrl: `templates/progettodettaglio/modals/question.html?t=${new Date()}`,
        controller: 'ModalProjectQuestionCtrl',
        resolve: {
          title: function() { return 'Eliminazione spesa'; },
          question: function () { return 'Sei sicuro di voler rimuovere questa spesa?'; }
        },
      }).result.then(function (input) {
        newApiClient.call(`
          mutation($id: Int!) {
            projectExpenses {
              removeProjectExpense(id: $id)
            }
          }
        `,
        { id: projectExpense.id }).then(function(response) {
          toastr.success('Spesa rimossa!');
          $scope.getProgetto(false);
        }, function(errors) {
          toastr.error(errors[0].extensions.userMessage, 'Spesa non rimossa!');
        });
      });
    };

    /* Project report */
    $scope.openProjectReportModal = function(project) {
      $uibModal.open({
        templateUrl: `templates/progettodettaglio/modals/create_report.html?t=${new Date()}`,
        controller: 'ModalProjectCreateReportCtrl',
        resolve: {
          project: function() { return project; },
          data: function () {
            return {
              fromDate: moment().startOf('month').format('YYYY-MM-DD'),
              toDate: moment().endOf('month').format('YYYY-MM-DD'),
            };
          }
        },
      }).result.then(function (input) {

      });
    };

    /* Project attachment */
    $scope.openCreateProjectAttachmentModal = function(project) {
      $uibModal.open({
        templateUrl: `templates/progettodettaglio/modals/create_attachment.html?t=${new Date()}`,
        size: 'lg',
        controller: 'ModalProjectCreateAttachmentCtrl',
        resolve: {
          project: function() { return project; },
        },
      }).result.then(function (input) {
        input.projectId = $scope.project.id;
        newApiClient.call(`
          mutation($input: CreateProjectAttachmentInput!) {
            projectAttachments {
              createProjectAttachment(input: $input) {
                id
              }
            }
          }
        `,
        { input: input }).then(function(response) {
          toastr.success('Allegato aggiunto!');
          $scope.getProgetto(false);
        }, function(errors) {
          toastr.error(errors[0].extensions.userMessage, 'Errore!', { timeOut: 0, extendedTimeOut: 0 });
        });
      });
    };

    $scope.openUpdateProjectAttachmentModal = function(projectAttachment) {
      $uibModal.open({
        templateUrl: `templates/progettodettaglio/modals/update_attachment.html?t=${new Date()}`,
        controller: 'ModalProjectUpdateAttachmentCtrl',
        resolve: {
          projectAttachment: function() { return projectAttachment; },
        },
      }).result.then(function (input) {
        newApiClient.call(`
          mutation($id: Int!, $input: UpdateProjectAttachmentInput!) {
            projectAttachments {
              updateProjectAttachment(id: $id, input: $input) {
                id
                description
              }
            }
          }
        `,
        { id: projectAttachment.id, input: input }).then(function(response) {
          toastr.success('Allegato modificato!');
          projectAttachment.description = response.projectAttachments.updateProjectAttachment.description;
        }, function(errors) {
          toastr.error(errors[0].extensions.userMessage, 'Errore!', { timeOut: 0, extendedTimeOut: 0 });
        });
      });
    };

    $scope.openRemoveProjectAttachmentModal = function(projectAttachment) {
      $uibModal.open({
        templateUrl: `templates/progettodettaglio/modals/question.html?t=${new Date()}`,
        controller: 'ModalProjectQuestionCtrl',
        resolve: {
          title: function() { return 'Eliminazione allegato'; },
          question: function () { return 'Sei sicuro di voler rimuovere questo allegato?'; }
        },
      }).result.then(function (input) {
        newApiClient.call(`
          mutation($id: Int!) {
            projectAttachments {
              removeProjectAttachment(id: $id)
            }
          }
        `,
        { id: projectAttachment.id }).then(function(response) {
          toastr.success('Allegato rimosso!');
          $scope.getProgetto(false);
        }, function(errors) {
          toastr.error(errors[0].extensions.userMessage, 'Errore!', { timeOut: 0, extendedTimeOut: 0 });
        });
      });
    };

  }