import * as angular from 'angular';
import toastr from 'toastr';
import { Convert } from '../utils';

angular
  .module('screeningcanvas')
  .directive('projectSelector', ['newApiClient', function (newApiClient) {
    return {
      restrict: 'A',
      scope: {
        ngModel: '=?',
        companyId: '=?',
        status: '=?',
        optional: '=?',
        optionalText: "@",
      },
      template: '<option ng-if="optional" value="">{{ (!!optionalText) ? optionalText : "Nessuno" }}</option><option value="{{ item.id }}" ng-repeat="item in items">{{ item.title }} ({{ item.folderCode }})</option>',
      replace: false,
      controller: ['$scope', function ($scope) {
        $scope.items = [];

        $scope.fetch = function () {
          newApiClient.call(`
            query($filter: ProjectFilter, $order: ProjectOrder!, $pagination: Pagination!) {
              projects {
                allProjects {
                  list(filter: $filter, order: $order, pagination: $pagination) {
                    id
                    folderCode
                    title
                  }
                }
              }
            }`,
            {
              filter: {
                includeIds: Convert.toSingletonIntList($scope.ngModel),
                statusTypes: Convert.toSingletonList($scope.status),
                customerIds: Convert.toSingletonIntList($scope.companyId),
              },
              order: 'CREATED_AT_DESC',
              pagination: { skip: 0, take: 300 }
            },
          ).then(function (data) {
            $scope.items = data.projects.allProjects.list;
          }, function () {
            toastr.error("Impossibile caricare i progetti");
          });
        };

        $scope.fetch();

        $scope.$watch('companyId', $scope.fetch);
        $scope.$watch('status', $scope.fetch);

      }],
    };
  }]);
