import * as angular from 'angular';
import toastr from 'toastr';

angular
  .module('screeningcanvas')
  .directive('purchaseInvoiceCategorySelector', ['newApiClient', function (newApiClient) {
    return {
      restrict: 'A',
      scope: {
        optional: '=?',
        optionalText: "@",
      },
      template: '<option ng-if="optional" value="">{{ (!!optionalText) ? optionalText : "Nessuna" }}</option><option value="{{ item.id }}" ng-repeat="item in items">{{ item.name }}</option>',
      replace: false,
      controller: ['$scope', function ($scope) {
        $scope.items = [];

        $scope.fetch = function () {
          newApiClient.call(`
            query {
              purchaseInvoiceCategories {
                allPurchaseInvoiceCategories {
                  id
                  name
                }
              }
            }`,
            {},
          ).then(function (data) {
            $scope.items = data.purchaseInvoiceCategories.allPurchaseInvoiceCategories;
          }, function () {
            toastr.error("Impossibile caricare le categorie fattura d'acquisto");
          });
        };
        $scope.fetch();
      }],
    };
  }]);
