import * as angular from 'angular';
import { Convert } from '../../utils';
import toastr from 'toastr';

angular
  .module('screeningcanvas')
  .controller('ElencoFattureAcquistoCtrl', ElencoFattureAcquistoController);

ElencoFattureAcquistoController.$inject = ['$scope', '$state', 'fileClient', '$uibModal', 'clientStorage', 'Utility', 'newApiClient'];

function ElencoFattureAcquistoController($scope, $state, fileClient, $uibModal, clientStorage, Utility, newApiClient) {
  $scope.fattureacquisto = [];
  $scope.ordinamento = '-progressivo';
  $scope.toUpdate = false;
  $scope.animationsEnabled = true;
  $scope.loading = false;
  $scope.loadingModalCosti = false;
  $scope.categories = [];

  $scope.displayFilters = true;
  $scope.hideFilters = function() { $scope.displayFilters = false; };
  $scope.showFilters = function() { $scope.displayFilters = true; };

  $scope.paginator = {
    page: 0,
    perPage: 50,
    totalPages: 0,
  };

  var nomeCookie = 'fattureacquisto_filtri_v2';
  $scope.defaultFilters = {
    type: null,
    query: null,
    categoryId: null,
    paymentMethodId: null,
    statuses: null,
    supplierId: null,
    fromDate: null,
    toDate: null,
  };

  $scope.filtri = Utility.getAndFixFilters(nomeCookie, $scope.defaultFilters);

  getRecords(0, true);

  function getRecords(page = 0, loading = false) {
    $scope.loading = loading;
    page = (page == null) ? $scope.paginator.page : page;

    newApiClient.call(`
        query($filter: PurchaseInvoiceFilter!, $pagination: Pagination!) {
          purchaseInvoices {
            allPurchaseInvoices {
              list(filter: $filter, pagination: $pagination) {
                id
                type
                code
                date
                status
                category {
                  id
                  name
                }
                supplier {
                  id
                  name
                }
                total {
                  netTotal
                  taxes
                  grossTotal
                }
                associatedExpenses
                paymentMethod {
                  id
                  label
                }
                note
                transactions {
                  id
                  date
                  amount
                }
                digitalInvoice {
                  id
                }
              }
              count(filter: $filter)
            }
          }
        }`,
      {
        filter: {
          types: Convert.toSingletonList($scope.filtri.type),
          query: $scope.filtri.query,
          categoryIds: !!($scope.filtri.categoryId) ? [parseInt($scope.filtri.categoryId)] : null,
          supplierIds: !!($scope.filtri.supplierId) ? [parseInt($scope.filtri.supplierId)] : null,
          paymentMethodIds: !!($scope.filtri.paymentMethodId) ? [parseInt($scope.filtri.paymentMethodId)] : null,
          status: !!($scope.filtri.status) ? $scope.filtri.status : null,
          fromDate: $scope.filtri.fromDate,
          toDate: $scope.filtri.toDate,
        },
        pagination: {
          take: $scope.paginator.perPage,
          skip: $scope.paginator.perPage * page,
        },
      },
    ).then(function (data) {
      clientStorage.put(nomeCookie, $scope.filtri);
      $scope.fattureacquisto = data.purchaseInvoices.allPurchaseInvoices.list.map(x =>
      ({
        ...x,
        associatedExpensesPercentage: (x.associatedExpenses / x.total.netTotal) * 100,
        locked: true,
      }));
      $scope.loading = false;
      $scope.paginator.totalPages = Math.ceil(data.purchaseInvoices.allPurchaseInvoices.count / $scope.paginator.perPage);
      $scope.paginator.page = page;
    }, function () {
      toastr.error("Impossibile caricare le fatture d'acquisto");
      $scope.loading = false;
    });
  }

  $scope.cerca = function (page = 0, loading = false) {
    getRecords(page, loading);
  }

  $scope.showXML = (supplierInvoiceId) => {
    newApiClient.call(`
        query($id: Int!) {
          purchaseInvoices {
            purchaseInvoice(id: $id) {
              digitalInvoice {
                xmlFile
              }
            }
          }
        }`,
      { id: parseInt(supplierInvoiceId) },
    ).then(function (data) {
      const digitalInvoice = data.purchaseInvoices.purchaseInvoice.digitalInvoice;
      if (!!digitalInvoice) {
        fileClient.displayStyledXML(digitalInvoice.xmlFile,
          "/xsl/digital_invoice.xsl");
      } else {
        toastr.error("Fattura elettronica non trovata");
      }
    }, function () {
      toastr.error("Impossibile caricare la fattura elettronica");
    });
  };

  $scope.resetFilters = function () {
    $scope.filtri = angular.copy($scope.defaultFilters);
  }

  $scope.setStatus = function (f, status) {
    newApiClient.call(`
        mutation($id: Int!, $status: PurchaseInvoiceStatus!) {
          purchaseInvoices {
            setPurchaseInvoiceStatus(purchaseInvoiceId: $id, status: $status) {
              id
              status
            }
          }
        }`,
      { id: f.id, status: status },
    ).then(function (data) {
      f.status = data.purchaseInvoices.setPurchaseInvoiceStatus.status;
      f.locked = true;
    }, function () {
      toastr.error("Impossibile modificare lo stato della fattura d'acquisto");
    });
  }

  $scope.remove = function (f) {
    $uibModal.open({
      animation: $scope.animationsEnabled,
      templateUrl: 'templates/purchaseInvoices/modalRemove.html?v=' + Date.now(),
      controller: 'ModalRemovePurchaseInvoiceCtrl',
      resolve: {}
    }).result.then(function() {
      newApiClient.call(`
        mutation($id: Int!) {
          purchaseInvoices {
            removePurchaseInvoice(purchaseInvoiceId: $id)
          }
        }`,
        { id: f.id, status: status },
      ).then(function (data) {
        $scope.cerca($scope.paginator.page, false);
      }, function (errors) {
        toastr.error(errors[0].extensions.userMessage, "Errore!");
      });
    });
  };

  $scope.isIncomplete = (f) =>
    (f.type !== 'CREDIT_NOTE' && (!f.paymentMethod || !f.transactions || f.transactions.length == 0))
    || !f.supplier
    || !f.category;

  $scope.openModalCostiFatturaAcquisto = function (f) {
    $scope.loadingModalCosti = true;
    $scope.items = [];
    newApiClient.call(`
        query ($id: Int!) {
          purchaseInvoices {
            purchaseInvoice(id: $id) {
              id
              projectExpenses {
                id
                price
                project {
                  folderCode
                  title
                }
              }
            }
          }
        }`,
      { id: f.id, status: status },
    ).then(function (data) {
      $scope.items = data.purchaseInvoices.purchaseInvoice.projectExpenses;
      $scope.open('lg');
    }, function () {
      toastr.error("Impossibile caricare le spese associate alla fattura d'acquisto");
    });
  }

  $scope.open = function (size) {
    var modalInstance = $uibModal.open({
      animation: $scope.animationsEnabled,
      templateUrl: 'templates/purchaseInvoices/modalSpeseFatturaAcquisto.html?v=' + Date.now(),
      controller: 'ModalInstanceCtrlFA',
      size: size,
      resolve: {
        toUpdate: function () { return $scope.toUpdate; },
        items: function () {
          return $scope.items;
        }
      }
    });
    modalInstance.result.then(function (toUpdate) {
      $scope.toUpdate = toUpdate;
      if ($scope.toUpdate) { // se dalla modal mi dice che devo aggiornare, allora ricarico i dettagli delle fatture di acquisto
        getRecords();
        $scope.toUpdate = false;
      };
      $scope.loadingModalCosti = false;
    }, function () {
      console.info('Modal dismissed at: ' + new Date());
      $scope.loadingModalCosti = false;
    });
  };

  $scope.changeLocked = function (f, boolValue) {
    f.locked = boolValue;
  }

  $scope.navigateToFatturaAcquistoEdit = function (f_id) {
    $state.go('fatturaacquisto_edit', { id: f_id });
  }

  /**
   * Funzione chiamata con il doppio-clicl su una nota, per editarla
   */
  $scope.editNota = function (f) {
    f.editingNote = true;
    f.noteTemp = f.note;
  }
  /**
   * Funzione chiamata con il doppio-clicl su una nota, per editarla
   */
  $scope.editCategory = function (f) {
    f.editingCategory = true;
    f.categoryTempId = "" + f.category.id;
  }

  $scope.aggiornaNota = function (f) {
    newApiClient.call(`
        mutation($id: Int!, $input: UpdatePurchaseInvoiceInput!) {
          purchaseInvoices {
            updatePurchaseInvoice(purchaseInvoiceId: $id, input: $input) {
              id
              note
            }
          }
        }`,
      {
        id: f.id,
        input: {
          note: (!!f.noteTemp.trim()) ? f.noteTemp : null,
        }
      },
    ).then(function (data) {
      f.editingNote = false;
      f.note = data.purchaseInvoices.updatePurchaseInvoice.note;
    }, function () {
      f.editingNote = false;
      toastr.error("Impossibile aggiornare le note");
    });
  }
  $scope.aggiornaCategory = function (f) {
    newApiClient.call(`
        mutation($id: Int!, $input: UpdatePurchaseInvoiceInput!) {
          purchaseInvoices {
            updatePurchaseInvoice(purchaseInvoiceId: $id, input: $input) {
              id
              category {
                id
                name
              }
            }
          }
        }`,
      {
        id: f.id,
        input: {
          categoryId: parseInt(f.categoryTempId),
        }
      },
    ).then(function (data) {
      f.editingCategory = false;
      f.category = data.purchaseInvoices.updatePurchaseInvoice.category;
    }, function () {
      f.editingCategory = false;
      toastr.error("Impossibile aggiornare la categoria");
    });
  }
  $scope.annullaEditingNota = function (f) {
    f.editingNote = false;
  }
  $scope.annullaEditingCategory = function (f) {
    f.editingCategory = false;
  }
}

