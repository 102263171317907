import * as angular from 'angular';
import toastr from 'toastr';

angular
  .module('screeningcanvas')
  .directive('areaSelector', ['newApiClient', function (newApiClient) {
    return {
      restrict: 'A',
      scope: {
        optional: '=?',
        optionalText: '@',
      },
      template: `
        <option ng-if="optional" value="">{{ (!!optionalText) ? optionalText : "Tutte le aziende" }}</option>
        <option value="{{ item.id }}" ng-repeat="item in items">{{ item.name }}</option>
      `,
      replace: false,
      require: 'ngModel',
      link: function (scope, element, attrs, ngModel) {
        ngModel.$parsers.push(function (val) {
          return val != null ? parseInt(val, 10) : null;
        });
        ngModel.$formatters.push(function (val) {
          return val != null ? '' + val : null;
        });
      },
      controller: ['$scope', function ($scope) {
        $scope.items = [];
        newApiClient.call(`
          query {
            areas {
              allAreas {
                id
                name
              }
            }
          }`,
          {},
        ).then(function (data) {
          $scope.items = data.areas.allAreas;
        }, function () {
          toastr.error("Impossibile caricare le aree");
        });
      }],
    };
  }]);
