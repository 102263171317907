import * as angular from 'angular';
import { Convert } from '../../../utils';

angular
  .module('screeningcanvas')
  .controller('ModalProjectEditTaskCtrl', ModalProjectEditTaskCtrl);

  ModalProjectEditTaskCtrl.$inject = ['$scope', 'data', '$uibModalInstance', 'title'];

  function ModalProjectEditTaskCtrl($scope, data, $uibModalInstance, title) {
    $scope.title = title;

    $scope.data = {
        assignedAreaId: data.assignedAreaId,
        assignedUserId: data.assignedUserId,
        deadline: data.deadline,
        estimatedHours: Convert.fromDurationToHours(data.estimatedTime),
        name: data.name,
    };
    $scope.save = function () {
      $uibModalInstance.close({
        assignedAreaId: Convert.toInt($scope.data.assignedAreaId),
        assignedUserId: Convert.toInt($scope.data.assignedUserId),
        deadline: Convert.toNonEmptyString($scope.data.deadline),
        estimatedTime: Convert.fromHoursToDuration($scope.data.estimatedHours),
        name: Convert.toNonEmptyString($scope.data.name),
      });
    };
  }