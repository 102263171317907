import * as angular from 'angular';
import toastr from 'toastr';

angular
  .module('screeningcanvas')
  .directive('projectTypeSelector', ['newApiClient', function (newApiClient) {
    return {
      restrict: 'A',
      scope: {
        optionalText: '@',
        optionalValue: '@',
      },
      template: '<option ng-if="!!optionalText || !!optionalValue" value="{{ optionalValue }}">{{ optionalText }}</option><option value="{{ item.id }}" ng-repeat="item in items">{{ item.name }}</option>',
      replace: false,
      controller: ['$scope', function ($scope) {
        $scope.items = [];

        $scope.fetch = function () {
          newApiClient.call(`
            query {
              projectTypes {
                allProjectTypes {
                  id
                  name
                }
              }
            }`,
            {},
          ).then(function (data) {
            $scope.items = data.projectTypes.allProjectTypes;
          }, function () {
            toastr.error("Impossibile caricare le tipologie di progetto");
          });
        };
        $scope.fetch();
      }],
    };
  }]);
