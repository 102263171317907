import * as angular from 'angular';
import toastr from 'toastr';

angular
  .module('screeningcanvas')
  .directive('vatOperationTypeSelector', ['newApiClient', function (newApiClient) {
    return {
      restrict: 'A',
      scope: {
        ngModel: '=?',
      },
      template: '<option value="">Seleziona...</option><option value="{{ item.id }}" ng-repeat="item in items">{{ item.digitalInvoiceId }}: {{ item.label }}</option>',
      replace: false,
      controller: ['$scope', function ($scope) {
        $scope.items = [];

        $scope.fetch = function () {
          newApiClient.call(`
            query {
              vatOperationTypes {
                allVatOperationTypes {
                  id
                  digitalInvoiceId
                  label
                  isDeprecated
                }
              }
            }`,
            {},
          ).then(function (data) {
            $scope.items = data.vatOperationTypes.allVatOperationTypes.filter(x => {
              if (!!$scope.ngModel && x.id == $scope.ngModel)
                return true;
              return !x.isDeprecated;
            });
          }, function () {
            toastr.error("Impossibile caricare le nature IVA");
          });
        };
        $scope.fetch();
      }],
    };
  }]);
