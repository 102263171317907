import * as angular from 'angular';
import * as moment from 'moment';
import toastr from 'toastr';
import { Convert } from '../../../utils';

angular
  .module('screeningcanvas')
  .controller('ModalProjectCreateReportCtrl', ModalProjectCreateReportCtrl);

  ModalProjectCreateReportCtrl.$inject = ['$scope', 'newApiClient', 'project', 'data'];

  function ModalProjectCreateReportCtrl($scope, newApiClient, project, data) {
    $scope.downloadLink = null;

    $scope.data = {
      fromDate: data.fromDate,
      toDate: data.toDate,
    };

    $scope.refreshDownloadLink = function() {
      if (!$scope.data.fromDate || !$scope.data.toDate)
        return;

      $scope.downloadLink = null;
      newApiClient.call(`
        query($projectId: Int!, $activityFilter: ActivityFilter!) {
          projects {
            project(id: $projectId) {
              activities {
                totalTime(filter: $activityFilter)
              }
            }
          }
        }
      `,
      {
        projectId: project.id,
        activityFilter: {
          from: moment($scope.data.fromDate).startOf('day').toISOString(),
          to: moment($scope.data.toDate).endOf('day').toISOString(),
          isBillable: true,
        },
      }).then(function(data) {
        $scope.totalTime = data.projects.project.activities.totalTime;
        const totalHours = Convert.fromDurationToHours($scope.totalTime);
        $scope.hasHours = totalHours > 0;
        if (!$scope.hasHours) {
          $scope.downloadLink = null;
          return;
        }

        newApiClient.call(`
          mutation CreateProjectReport($input: CreateProjectReportInput!) {
            projects {
              createProjectReport(input: $input)
            }
          }
        `,
        {
          input: {
            projectId: project.id,
            fromDate: $scope.data.fromDate,
            toDate: $scope.data.toDate,
          },
        }).then(function(data) {
          $scope.downloadLink = data.projects.createProjectReport;
        }, function(error) {
          toastr.error('Errore!');
          console.error(error);
        });

      }, function(error) {
        toastr.error('Errore!');
        console.error(error);
      });
    };
    $scope.refreshDownloadLink();

    $scope.$watch('data.fromDate', $scope.refreshDownloadLink);
    $scope.$watch('data.toDate', $scope.refreshDownloadLink);
  }