import * as angular from 'angular';
import toastr from 'toastr';

angular
  .module('screeningcanvas')
  .controller('ModalInstanceCtrlFA', ModalInstanceController);

ModalInstanceController.$inject = ['$scope', '$uibModalInstance', 'items', 'toUpdate', 'newApiClient'];

function ModalInstanceController($scope, $uibModalInstance, items, toUpdate, newApiClient) {
  $scope.items = items;
  $scope.toUpdate = toUpdate;
  $scope.ok = function () { $uibModalInstance.close($scope.toUpdate); };
  $scope.cancel = function () { $uibModalInstance.dismiss('cancel'); };
  $scope.rimuoviAssociazione = function (item) {
    newApiClient.call(`
        mutation($id: Int!, $input: UpdateProjectExpenseInput!) {
          projectExpenses {
            updateProjectExpense(projectExpenseId: $id, input: $input) {
              id
            }
          }
        }`,
      {
        id: item.id,
        input: {
          purchaseInvoiceId: null,
        }
      },
    ).then(function (data) {
      item._deleted = true;
      $scope.toUpdate = true; // mi serve per poter dire al controller "padre" che deve ricaricare i dati
    }, function () {
      toastr.error("Impossibile rimuovere costo dalla fattura d'acquisto");
    });
  }
}
