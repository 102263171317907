import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .filter('formatSize', function () {
    return function (input) {
      const allRepresentations = [
        { unit: 'GB', amount: input / (1024 * 1024 * 1024) },
        { unit: 'MB', amount: input / (1024 * 1024) },
        { unit: 'KB', amount: input / 1024 },
        { unit: 'B', amount: input },
      ];
      const representation = allRepresentations.filter(x => x.amount >= 1)[0];
      const unitStr = representation.unit;
      const amountStr = representation.amount.toLocaleString('it-IT', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return `${amountStr} ${unitStr}`;
    };
  });