import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .controller('ModalExtraEdit', ModalExtraEdit);

  ModalExtraEdit.$inject = ['$scope', 'data', '$uibModalInstance'];

  function ModalExtraEdit($scope, data, $uibModalInstance) {
    $scope.data = {
      typeId: data.typeId,
      isBillable: data.isBillable,
    };
    $scope.save = function () {
      $uibModalInstance.close({
        typeId: $scope.data.typeId,
        isBillable: $scope.data.isBillable,
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }
