import * as angular from 'angular';
import * as moment from 'moment';
import toastr from 'toastr';

angular
  .module('screeningcanvas')
  .directive("thSortable", thSortable);

function thSortable() {
  return {
    restrict: "A",
    template: "<th></th>",
    scope: false,
    transclude: true,
    link: function (scope, element, attrs) {
      var html = '', classe = '',
        theLabel = attrs.label;

      element.bind('click', function () {
        if (scope.ordinamento.indexOf(attrs.what) < 0) {
          scope.ordinamento = attrs.what;
        } else {
          scope.ordinamento = (scope.ordinamento.indexOf('-') < 0) ? '-' + attrs.what : attrs.what;
        };
        scope.$apply();
      });

      scope.$watch('ordinamento', function (newValue, oldValue) {
        if (newValue) {
          html = theLabel;
          if (newValue === attrs.what || newValue === '-' + attrs.what) {
            if (newValue == attrs.what) {
              classe = 'fa-sort-amount-asc';
            } else if (newValue == '-' + attrs.what) {
              classe = 'fa-sort-amount-desc';
            };
            html += ' <i class="fa ' + classe + '"></i>';
          };
          element.html(html);
          element.addClass('th-sortable');
        };
      });
    }
  };
};
