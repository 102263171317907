import * as angular from 'angular';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, Validators } from '@angular/forms';

export interface DashboardModalResult {
  name: string,
  surname: string,
  email: string,
  roleId: number,
  areaIds: number[],
}

@Component({
  selector: 'user-edit-modal',
  templateUrl: 'new/pages/users/user-edit-modal.component.html',
})
export class UserEditModal implements OnInit {
  @Input() title: string;
  @Input() initial?: any;
  @Output() onClose = new EventEmitter<DashboardModalResult>();

  private form = this.formBuilder.group({
    name: [null, Validators.required],
    surname: [null, Validators.required],
    email: [null, Validators.required],
    roleId: [null, Validators.required],
    areaIds: [[], Validators.required],
  });

  constructor(
    @Inject(BsModalRef) private modalRef: BsModalRef,
    @Inject(FormBuilder) private formBuilder: FormBuilder,
  ) {}
  
  ngOnInit() {
    if (this.initial) {
      this.form.get('name').setValue(this.initial.name);
      this.form.get('surname').setValue(this.initial.surname);
      this.form.get('email').setValue(this.initial.email);
      this.form.get('roleId').setValue(this.initial.roleId);
      this.form.get('areaIds').setValue(this.initial.areaIds);
    }
  }

  onSubmit() {
    const data = this.form.getRawValue();
    this.onClose.emit({
      name: data.name,
      surname: data.surname,
      email: data.email,
      roleId: data.roleId,
      areaIds: data.areaIds,
    });
    this.modalRef.hide();
  }

  onCancel() {
    this.modalRef.hide();
  }
}

angular.module('screeningcanvas').directive('userEditModal', downgradeComponent({ component: UserEditModal }));