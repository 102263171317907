import * as angular from 'angular';
import toastr from 'toastr';
import { Convert } from '../../utils';

  angular
    .module('screeningcanvas')
    .controller('ClienteDettaglioCtrl', ClienteDettaglioCtrl);

    ClienteDettaglioCtrl.$inject = ['newApiClient', '$stateParams'];

    function ClienteDettaglioCtrl(newApiClient, $stateParams) {
        var vm = this;
        vm.customerId = $stateParams.id;
        vm.loading = false;

        const companyFragment = `
          id
          name
          billingAddress {
            country { id }
            region { id }
            zip
            city
            streetAddress
          }
          taxCode
          vatNumber
          bank
          iban
          phone
          fax
          email
          pec
          digitalInvoiceChannel
          website
          note
          purchaseInvoiceCategory { id }
          preferredPaymentMethod { id }
          preferredPaymentTerm { id }
          paymentDateOffset { id }
        `;

        vm.getCliente = getCliente;
        vm.aggiornaCliente = aggiornaCliente;

        vm.getCliente();

        function formFromCompany(company) {
          return {
            name: company.name,
            billingAddress: (!!company.billingAddress) 
              ? {
                countryId: ""+company.billingAddress.country.id,
                regionId: (!!company.billingAddress.region)
                  ? ""+company.billingAddress.region.id
                  : null,
                zip: company.billingAddress.zip,
                city: company.billingAddress.city,
                streetAddress: company.billingAddress.streetAddress,
              }
              : {
                countryId: null,
                regionId: null,
                zip: null,
                city: null,
                streetAddress: null,
              },
            taxCode: company.taxCode,
            vatNumber: company.vatNumber,
            bank: company.bank,
            iban: company.iban,
            phone: company.phone,
            fax: company.fax,
            email: company.email,
            pec: company.pec,
            digitalInvoiceChannel: company.digitalInvoiceChannel,
            website: company.website,
            note: company.note,
            purchaseInvoiceCategoryId: (!!company.purchaseInvoiceCategory) ? ""+company.purchaseInvoiceCategory.id : null,
            preferredPaymentMethodId: (!!company.preferredPaymentMethod) ? ""+company.preferredPaymentMethod.id : null,
            preferredPaymentTermId: (!!company.preferredPaymentTerm) ? ""+company.preferredPaymentTerm.id : null,
            paymentDateOffsetId: (!!company.paymentDateOffset) ? ""+company.paymentDateOffset.id : null,
          };
        }

        function getCliente() {
          vm.loading = true;
          newApiClient.call(`
            query($id: Int!) {
              companies {
                company(id: $id) {
                  ${companyFragment}
                }
              }
            }`,
            { id: parseInt(vm.customerId) },
          ).then(function(data) {
            vm.loading = false;
            const company = data.companies.company;
            vm.cliente = formFromCompany(company);
          }, function(errors) {
            toastr.error(errors[0].extensions.userMessage, "Errore di caricamento anagrafica");
          });
        }

        function aggiornaCliente() {
          vm.loading = true;
          newApiClient.call(`
            mutation($input: UpdateCompanyInput!) {
              companies {
                updateCompany(input: $input) {
                  ${companyFragment}
                }
              }
            }`,
            { 
              input: {
                companyId: Convert.toInt(vm.customerId),
                name: Convert.toNonEmptyString(vm.cliente.name),
                billingAddress: (!!vm.cliente.billingAddress.streetAddress) 
                  ? {
                    countryId: Convert.toInt(vm.cliente.billingAddress.countryId),
                    regionId: Convert.toInt(vm.cliente.billingAddress.regionId),
                    zip: Convert.toNonEmptyString(vm.cliente.billingAddress.zip),
                    city: Convert.toNonEmptyString(vm.cliente.billingAddress.city),
                    streetAddress: Convert.toNonEmptyString(vm.cliente.billingAddress.streetAddress),
                  }
                  : null,
                taxCode: Convert.toNonEmptyString(vm.cliente.taxCode),
                vatNumber: Convert.toNonEmptyString(vm.cliente.vatNumber),
                bank: Convert.toNonEmptyString(vm.cliente.bank),
                iban: Convert.toNonEmptyString(vm.cliente.iban),
                phone: Convert.toNonEmptyString(vm.cliente.phone),
                fax: Convert.toNonEmptyString(vm.cliente.fax),
                email: Convert.toNonEmptyString(vm.cliente.email),
                pec: Convert.toNonEmptyString(vm.cliente.pec),
                digitalInvoiceChannel: Convert.toNonEmptyString(vm.cliente.digitalInvoiceChannel),
                website: Convert.toNonEmptyString(vm.cliente.website),
                note: Convert.toNonEmptyString(vm.cliente.note),
                purchaseInvoiceCategoryId: Convert.toInt(vm.cliente.purchaseInvoiceCategoryId),
                preferredPaymentMethodId: Convert.toInt(vm.cliente.preferredPaymentMethodId),
                preferredPaymentTermId: Convert.toInt(vm.cliente.preferredPaymentTermId),
                paymentDateOffsetId: Convert.toInt(vm.cliente.paymentDateOffsetId),
              } 
            },
          ).then(function(data) {
            const company = data.companies.updateCompany;
            vm.cliente = formFromCompany(company);
            vm.loading = false;
            toastr.success("Anagrafica salvata con successo!");
          }, function(errors) {
            toastr.error(errors[0].extensions.userMessage, "Impossibile modificare l'anagrafica");
          });
        };

        vm.isAddressEmpty = function(address) {
          if (!address)
            return true;
          return !address.city
            && !address.zip
            && !address.countryId
            && !address.regionId
            && !address.streetAddress
        };
    }
