import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .controller('ModalRemovePurchaseInvoiceCtrl', ModalRemovePurchaseInvoiceCtrl);

  ModalRemovePurchaseInvoiceCtrl.$inject = ['$scope', '$uibModalInstance'];

  function ModalRemovePurchaseInvoiceCtrl($scope, $uibModalInstance) {
    $scope.save = function () {
      $uibModalInstance.close({});
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }
  