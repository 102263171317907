import * as angular from 'angular';
import { Convert } from '../../../utils';

angular
  .module('screeningcanvas')
  .controller('ModalProjectEditExpenseCtrl', ModalProjectEditExpenseCtrl);

  ModalProjectEditExpenseCtrl.$inject = ['$scope', 'data', '$uibModalInstance', 'title'];

  function ModalProjectEditExpenseCtrl($scope, data, $uibModalInstance, title) {
    $scope.title = title;

    $scope.data = {
        price: data.price,
        supplierId: (!!data.supplierId)
          ? data.supplierId.toString()
          : null,
        note: data.note,
    };
    $scope.save = function () {
      $uibModalInstance.close({
        price: Convert.toFloat($scope.data.price),
        supplierId: Convert.toInt($scope.data.supplierId),
        note: Convert.toNonEmptyString($scope.data.note),
      });
    };
  }