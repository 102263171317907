import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .directive('paginator', function () {
    return {
      restrict: 'E',
      scope: {
        pagesCount: '<',
        page: '<',
        onPageChange: '&',
      },
      templateUrl: 'templates/directives/paginator.html',
      link: function ($scope, element, attrs) {
        $scope.displayPages = [];

        $scope.goTo = (i) => {
          $scope.onPageChange({ page: i });
        };

        $scope.adjustDisplayPages = () => {
          $scope.displayPages = [$scope.page];
          var remainingPagesToShow = 12;
          var halves = remainingPagesToShow / 2;
          for (let page = $scope.page + 1; page <= $scope.page + halves && page < $scope.pagesCount && remainingPagesToShow > 0; page++) {
            $scope.displayPages.push(page);
            remainingPagesToShow--;
          }
          for (let page = $scope.page - 1; page >= $scope.page - halves && page >= 0 && remainingPagesToShow > 0; page--) {
            $scope.displayPages.unshift(page);
            remainingPagesToShow--;
          }
          for (let page = $scope.page + halves + 1; page < $scope.pagesCount && remainingPagesToShow > 0; page++) {
            $scope.displayPages.push(page);
            remainingPagesToShow--;
          }
          for (let page = $scope.page - halves - 1; page >= 0 && remainingPagesToShow > 0; page--) {
            $scope.displayPages.unshift(page);
            remainingPagesToShow--;
          }
        };

        $scope.$watch('page', (newVal, oldVal) => {
          $scope.adjustDisplayPages();
        });
        $scope.$watch('pagesCount', (newVal, oldVal) => {
          $scope.adjustDisplayPages();
        });
      }
    };
  });
