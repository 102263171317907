import * as angular from 'angular';
import toastr from 'toastr';
  
  angular
    .module('screeningcanvas')
    .controller('ClientiCtrl', ClientiController);

    ClientiController.$inject = ['$scope', 'clientStorage', 'Utility', 'User', '$uibModal', 'newApiClient'];

    function ClientiController($scope, clientStorage, Utility, User, $uibModal, newApiClient) {
      $scope.searchtext = {};
      $scope.searchtext.company = '';
      $scope.loading = false;
      $scope.companies = [];
      var nomeCookie = 'filtriClienti';
      var defaultFilters = {
        ricerca: '',
      };
      var searchParams = {
        ricerca: null,
        interactionFromYears: null,
      };
      $scope.user = User.getUser();

      $scope.aggiornaClienti = aggiornaClienti;
      $scope.resetFilters = resetFilters;
      $scope.cerca = cerca;
      $scope.init = init;
      $scope.removeCustomer = removeCustomer;

      $scope.paginator = {
        page: 0,
        perPage: 50,
        totalPages: 0,
      };

      $scope.init();

      function init() {
        $scope.filtri = Utility.getAndFixFilters(nomeCookie, defaultFilters);
        $scope.aggiornaClienti(0);
      }

      $scope.openMergeCompaniesModal = function(fromCompany) {
        $uibModal.open({
          templateUrl: 'templates/companies/modal_merge.html',
          controller: 'ModalMergeCompaniesCtrl',
          resolve: {
            fromCompany: function() { return fromCompany; },
          },
        }).result.then(function (input) {
          newApiClient.call(`
            mutation($fromCompanyId: Int!, $intoCompanyId: Int!) {
              companies {
                mergeCompanies(fromCompanyId: $fromCompanyId, intoCompanyId: $intoCompanyId) {
                  id
                }
              }
            }`,
            input,
          ).then(function(data) {
            toastr.success("Anagrafica unita");
            init();
          }, function(errors) {
            toastr.error(errors[0].extensions.userMessage, "Errore!");
          });
        });
      };

      function removeCustomer(id) {
        if (window.confirm("Sei sicuro di voler eliminare questa anagrafica?")) {
          newApiClient.call(`
            mutation($customerId: Int!) {
              companies {
                removeCompany(companyId: $customerId)
              }
            }`,
            { customerId: id },
          ).then(function(data) {
            toastr.success("Anagrafica eliminata");
            init();
          }, function(errors) {
            toastr.error(errors[0].extensions.userMessage, "Impossibile eliminare l'anagrafica");
          });
        }
      };

      function aggiornaClienti(page = 0) {
        page = (page == null) ? $scope.paginator.page : page;
        searchParams = getSearchParams();
        $scope.loading = true;
        newApiClient.call(`
          query($filter: CompanyFilter!, $pagination: Pagination!) {
            companies {
              allCompanies {
                list(filter: $filter, pagination: $pagination) {
                  id
                  name
                  projects { count }
                  invoices { count }
                  purchaseInvoices { count }
                  salesQuotations { count }
                  waybills { count }
                  createdAt
                }
                count(filter: $filter)
              }
            }
          }`,
          {
            filter: {
              query: (!!$scope.filtri.ricerca) ? $scope.filtri.ricerca : null,
            },
            pagination: {
              take: $scope.paginator.perPage,
              skip: $scope.paginator.perPage * page,
            },
          }
        ).then(function(data) {
          const companies = data.companies.allCompanies.list;
          const companiesCount = data.companies.allCompanies.count;
          $scope.paginator.totalPages = Math.ceil(companiesCount / $scope.paginator.perPage);
          $scope.paginator.page = page;
          $scope.companies = companies;
          $scope.loading = false;
        }, function(errors) {
          toastr.error(errors[0].extensions.userMessage, "Impossibile caricare le anagrafiche");
        });
      };

      function getSearchParams() {
        searchParams = {
          ricerca: null,
          interactionFromYears: null,
        };
        if ($scope.filtri.ricerca != '') {
          searchParams.ricerca = $scope.filtri.ricerca;
        };
        if ($scope.filtri.interactionFromYears != 'dasempre') {
          searchParams.interactionFromYears = $scope.filtri.interactionFromYears;
        };

        clientStorage.put(nomeCookie, $scope.filtri);
        return searchParams;
      }

      function cerca() {
        $scope.aggiornaClienti();
      };

      function resetFilters() {
        $scope.filtri = angular.copy(defaultFilters);
        $scope.aggiornaClienti();
      };
    }
