import * as angular from 'angular';
import * as moment from 'moment';

  angular
    .module('screeningcanvas')
    .controller('ModalAddProjectToCreditNoteCtrl', ModalAddProjectToCreditNoteCtrl);

    ModalAddProjectToCreditNoteCtrl.$inject = ['$scope', 'availableInvoices', '$uibModalInstance'];

    function ModalAddProjectToCreditNoteCtrl($scope, availableInvoices, $uibModalInstance) {
      $scope.availableInvoices = availableInvoices;

      var invoiceGroups = {};
      availableInvoices.forEach(function(invoice) {
        const hash = moment(invoice.date, 'YYYY-MM-DD').format('YYYY-MM');
        if (!invoiceGroups[hash])
          invoiceGroups[hash] = [];
        invoiceGroups[hash].push(invoice);
      });
      $scope.invoiceGroups = invoiceGroups;
      const maxDate = Object.keys($scope.invoiceGroups)
        .reduce((max, current) => (current > max) ? current : max);
      $scope.currentInvoiceGroup = maxDate;

      $scope.getCurrentGroup = function() {
        return $scope.invoiceGroups[$scope.currentInvoiceGroup];
      };

      $scope.getGroupTitle = function() {
        return moment($scope.currentInvoiceGroup, 'YYYY-MM').format('MMMM YYYY');
      };

      $scope.nextGroup = function() {
        const currentDate = $scope.currentInvoiceGroup;
        const allDates = Object.keys($scope.invoiceGroups);
        const futureDates = allDates.filter((date) => date > currentDate);

        if (futureDates.length == 0) {
          $scope.currentInvoiceGroup = allDates
            .reduce((min, current) => (current < min) ? current : min);
        } else {
          $scope.currentInvoiceGroup = futureDates
            .reduce((min, current) => (current < min) ? current : min);
        }
      };

      $scope.previousGroup = function() {
        const currentDate = $scope.currentInvoiceGroup;
        const allDates = Object.keys($scope.invoiceGroups);
        const pastDates = allDates.filter((date) => date < currentDate);

        if (pastDates.length == 0) {
          $scope.currentInvoiceGroup = allDates
            .reduce((max, current) => (current > max) ? current : max);
        } else {
          $scope.currentInvoiceGroup = pastDates
            .reduce((max, current) => (current > max) ? current : max);
        }
      };

      $scope.$on('modal.closing', function (event, reason, closed) {
        $scope.clear();
      });

      $scope.getSelected = function() {
        const selectedPerInvoice = $scope.availableInvoices.map(function(invoice) {
          const selectedRows = invoice.rows.filter(function(x) { return !!x.isSelected; });
          return selectedRows.map(function(row) {
            return {
              ...row,
              invoiceId: invoice.id,
              invoice: invoice,
            };
          });
        });
        return [].concat.apply([], selectedPerInvoice);
      };

      $scope.clear = function() {
        $scope.availableInvoices.forEach(function(invoice) {
          invoice.rows.forEach(function(row) {
            row.isSelected = null;
          });
        });
        $scope.availableInvoices = [];
      };

      $scope.addAll = function () {
        const selectedProjects = $scope.getSelected();
        $scope.clear();
        $uibModalInstance.close(selectedProjects);
      };

      $scope.canBeSelected = function(row) {
        return row.adjustedPrice > 0;
      };

      $scope.toggleAll = function(invoice) {
        if (invoice.allSelected) {
          $scope.selectAll(invoice);
        } else {
          $scope.deselectAll(invoice);
        }
      }

      $scope.checkAllSelected = function(invoice) {
        if ($scope.areAllSelected(invoice)) {
          invoice.allSelected = true;
        } else {
          invoice.allSelected = false;
        }
      }

      $scope.selectAll = function(invoice) {
        invoice.rows.forEach(function(row) {
          if ($scope.canBeSelected(row)) {
            row.isSelected = true;
          }
        });
      };
      $scope.deselectAll = function(invoice) {
        invoice.rows.forEach(function(row) {
          if ($scope.canBeSelected(row)) {
            row.isSelected = false;
          }
        });
      };
      $scope.areAllSelected = function(invoice) {
        const aNonSelectedOne = invoice.rows.find(function(row) {
          return !row.isSelected;
        });
        return !aNonSelectedOne;
      };
      $scope.anyCanBeSelected = function(invoice) {
        const selectableOne = invoice.rows.find(function(row) {
          return $scope.canBeSelected(row);
        });
        return !!selectableOne;
      };
    }
