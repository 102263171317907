import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .directive('visualizzaSomme', visualizzaSomme);

function visualizzaSomme() {
  return {
    restrict: 'AE',
    scope: {
      somme: '='
    },
    templateUrl: 'templates/directives/visualizzaSomme.html'
  };
}
