import * as angular from 'angular';
import * as moment from 'moment';
import toastr from 'toastr';

angular
  .module('screeningcanvas')
  .controller('ScadenzarioCtrl', ScadenzarioController);

ScadenzarioController.$inject = ['$scope', 'newApiClient', 'clientStorage', 'Utility', '$uibModal'];

function ScadenzarioController($scope, newApiClient, clientStorage, Utility, $uibModal) {
  $scope.sommeDaVisualizzare = [];
  $scope.loading = false;
  var filter_cookie_name = 'entrateuscite_filtri_v2';
  $scope.filtriBase = {
    pagato: 'pagato-e-non',
    defaulted: 'insoluto-e-non',
    fromPaymentDate: null,
    toPaymentDate: null,
    fromDocumentDate: null,
    toDocumentDate: null,
    tagId: null,
    paymentMethodId: null,
    companyId: null,
    query: null,
    entratauscita: 'entrata-e-uscita'
  };

  $scope.displayFilters = true;
  $scope.hideFilters = function() { $scope.displayFilters = false; };
  $scope.showFilters = function() { $scope.displayFilters = true; };

  $scope.filtri = Utility.getAndFixFilters(filter_cookie_name, $scope.filtriBase);
  $scope.records = [];
  $scope.euTemp = null;

  $scope.order = 'DATE_DESC';
  $scope.paginator = {
    page: 0,
    perPage: 50,
    totalPages: 0,
  };

  $scope.getRecords = function (page = 0) {
    page = (page == null) ? $scope.paginator.page : page;
    $scope.sommeDaVisualizzare = [];
    $scope.loading = true;

    var isPaid = null;
    switch ($scope.filtri.pagato) {
      case 'pagato': isPaid = true; break;
      case 'non-pagato': isPaid = false; break;
    }

    var isDefaulted = null;
    switch ($scope.filtri.defaulted) {
      case 'insoluto': isDefaulted = true; break;
      case 'non-insoluto': isDefaulted = false; break;
    }

    var type = null;
    switch ($scope.filtri.entratauscita) {
      case 'entrata': type = 'REVENUE'; break;
      case 'uscita': type = 'EXPENSE'; break;
    }

    newApiClient.call(`
        query($filter: TransactionFilter!, $order: TransactionOrder!, $pagination: Pagination!) {
          transactions {
            allTransactions {
              list(filter: $filter, pagination: $pagination, order: $order) {
                id
                paymentDate
                type
                tag {
                  id
                  name
                }
                paymentMethod {
                  id
                  label
                }
                company {
                  id
                  name
                }
                note
                invoice {
                  id
                  date
                  type
                  yearlyCode
                }
                purchaseInvoice {
                  id
                  type
                  date
                  code
                }
                isPaid
                paidAt
                isDefaulted
                documentDate
                documentCode
                amount
              }
              count(filter: $filter)
              amount(filter: $filter)
            }
          }
        }
      `, {
      filter: {
        isPaid: isPaid,
        isDefaulted: isDefaulted,
        fromPaymentDate: $scope.filtri.fromPaymentDate,
        toPaymentDate: $scope.filtri.toPaymentDate,
        fromDocumentDate: $scope.filtri.fromDocumentDate,
        toDocumentDate: $scope.filtri.toDocumentDate,
        tagIds: (!!$scope.filtri.tagId)
          ? [parseInt($scope.filtri.tagId)]
          : null,
        paymentMethodIds: (!!$scope.filtri.paymentMethodId)
          ? [parseInt($scope.filtri.paymentMethodId)]
          : null,
        companyIds: (!!$scope.filtri.companyId)
          ? [parseInt($scope.filtri.companyId)]
          : null,
        query: $scope.filtri.query,
        type: type,
      },
      pagination: {
        take: $scope.paginator.perPage,
        skip: $scope.paginator.perPage * page,
      },
      order: $scope.order,
    }).then(function (data) {
      $scope.loading = false;
      clientStorage.put(filter_cookie_name, $scope.filtri);

      $scope.records = data.transactions.allTransactions.list.map(x => ({
        ...x,
        delayInDays: (x.paidAt != null)
          ? moment(x.paidAt).diff(moment(x.paymentDate), 'days')
          : null,
        lock: true,
      }));

      const recordsCount = data.transactions.allTransactions.count;
      $scope.paginator.totalPages = Math.ceil(recordsCount / $scope.paginator.perPage);
      $scope.paginator.page = page;

      const total = data.transactions.allTransactions.amount;
      $scope.sommeDaVisualizzare.push({
        label: 'Totale importo',
        total: total.toFixed(2)
      });
    }, function (errors) {
      toastr.error("Impossibile caricare le entrate / uscite");
    });
  };

  $scope.getRecords();

  $scope.sortColumn = function (first, second) {
    if ($scope.order == first) {
      $scope.order = second;
    } else {
      $scope.order = first;
    }
    $scope.getRecords();
  };

  $scope.resetFilters = function () {
    $scope.filtri = angular.copy($scope.filtriBase);
    $scope.getRecords();
  };

  $scope.openNewModal = function () {
    $uibModal.open({
      templateUrl: `templates/scadenzario/modal_edit.html?t=${new Date()}`,
      controller: 'ModalEditTransactionCtrl',
      resolve: {
        data: function () {
          return {
            paymentDate: null,
            tagId: null,
            paymentMethodId: null,
            companyId: null,
            documentCode: null,
            documentDate: null,
            amount: null,
            note: null,
            paidAt: null,
            isDefaulted: false,
          };
        }
      },
    }).result.then(function (input) {
      newApiClient.call(`
          mutation($input: CreateTransactionInput!) {
            transactions {
              createTransaction(input: $input) {
                id
              }
            }
          }
        `,
        { input: input }).then(function (response) {
          toastr.success('Transazione creata!');
          $scope.getRecords();
        }, function (errors) {
          toastr.error(errors[0].extensions.userMessage, 'Errore nella creazione della transazione!');
        });
    });
  };

  $scope.openDuplicateModal = function (trans) {
    $uibModal.open({
      templateUrl: `templates/scadenzario/modal_edit.html?t=${new Date()}`,
      controller: 'ModalEditTransactionCtrl',
      resolve: {
        data: function () {
          return {
            paymentDate: trans.paymentDate,
            tagId: trans.tag?.id,
            paymentMethodId: trans.paymentMethod?.id,
            companyId: trans.company?.id,
            documentCode: trans.documentCode,
            documentDate: trans.documentDate,
            amount: trans.amount,
            note: trans.note,
            paidAt: null,
            isDefaulted: false,
          };
        }
      },
    }).result.then(function (input) {
      newApiClient.call(`
          mutation($input: CreateTransactionInput!) {
            transactions {
              createTransaction(input: $input) {
                id
              }
            }
          }
        `,
        { input: input }).then(function (response) {
          toastr.success('Transazione creata!');
          $scope.getRecords();
        }, function (errors) {
          toastr.error(errors[0].extensions.userMessage, 'Errore nella creazione della transazione!');
        });
    });
  };

  $scope.openEditModal = function (trans) {
    $uibModal.open({
      templateUrl: `templates/scadenzario/modal_edit.html?t=${new Date()}`,
      controller: 'ModalEditTransactionCtrl',
      resolve: {
        data: function () {
          return {
            paymentDate: trans.paymentDate,
            tagId: trans.tag?.id,
            paymentMethodId: trans.paymentMethod?.id,
            companyId: trans.company?.id,
            documentCode: trans.documentCode,
            documentDate: trans.documentDate,
            amount: trans.amount,
            note: trans.note,
            paidAt: trans.paidAt,
            isDefaulted: trans.isDefaulted,
          };
        }
      },
    }).result.then(function (input) {
      newApiClient.call(`
          mutation($id: Int!, $input: UpdateTransactionInput!) {
            transactions {
              updateTransaction(id: $id, input: $input) {
                id
              }
            }
          }
        `,
        { id: trans.id, input: input }).then(function (response) {
          toastr.success('Transazione aggiornata!');
          $scope.getRecords();
        }, function (errors) {
          toastr.error(errors[0].extensions.userMessage, 'Errore nella modifica della transazione!');
        });
    });
  };

  $scope.openEditMinimalModal = function (trans) {
    $uibModal.open({
      templateUrl: `templates/scadenzario/modal_edit_payed.html?t=${new Date()}`,
      controller: 'ModalEditTransactionPayedCtrl',
      resolve: {
        data: function () {
          return {
            paymentDate: trans.paymentDate,
            note: trans.note,
            tagId: trans.tag?.id,
            paidAt: trans.paidAt,
            isDefaulted: trans.isDefaulted,
          };
        }
      },
    }).result.then(function (input) {
      newApiClient.call(`
          mutation($id: Int!, $input: UpdateTransactionInput!) {
            transactions {
              updateTransaction(id: $id, input: $input) {
                id
                isPaid
                isDefaulted
                tag {
                  id
                  name
                }
                paidAt
                note
              }
            }
          }
        `,
        { id: trans.id, input: input }).then(function (response) {
          toastr.success('Transazione aggiornata!');
          const newTrans = response.transactions.updateTransaction;
          trans.isPaid = newTrans.isPaid;
          trans.tag = newTrans.tag;
          trans.paidAt = newTrans.paidAt;
          trans.delayInDays = (newTrans.paidAt != null)
            ? moment(newTrans.paidAt).diff(moment(trans.paymentDate), 'days')
            : null;
          trans.isDefaulted = newTrans.isDefaulted;
          trans.note = newTrans.note;
        }, function (errors) {
          toastr.error(errors[0].extensions.userMessage, 'Errore nella modifica della transazione!');
        });
    });
  };

  $scope.openDeleteModal = function (trans) {
    $uibModal.open({
      templateUrl: `templates/scadenzario/modal_remove.html?t=${new Date()}`,
      controller: 'ModalRemoveTransactionCtrl'
    }).result.then(function (input) {
      newApiClient.call(`
          mutation($id: Int!) {
            transactions {
              removeTransaction(id: $id)
            }
          }
        `,
        { id: trans.id }).then(function (response) {
          toastr.success('Transazione rimossa!');
          $scope.getRecords();
        }, function (errors) {
          toastr.error(errors[0].extensions.userMessage, 'Errore nella rimozione della transazione!');
        });
    });
  };

}
