import * as angular from 'angular';
import toastr from 'toastr';
import { parseDuration, Convert } from '../../../utils';

angular
  .module('screeningcanvas')
  .controller('ProjectsTabsEstimateCtrl', ProjectsTabsEstimateCtrl);

  ProjectsTabsEstimateCtrl.$inject = ['$scope', '$rootScope', 'newApiClient', '$stateParams', '$uibModal'];

  function ProjectsTabsEstimateCtrl($scope, $rootScope, newApiClient, $stateParams, $uibModal) {
    $scope.loading = false;
    $scope.softLoading = false;
    $scope.isEditing = false;
    $scope.project = null;

    $scope.load = (initialLoading, editingPartId) => {
      $scope.loading = initialLoading;
      newApiClient.call(`
        query($id: Int!) {
          projects {
            project(id: $id) {
              id
              yearlyCode
              title
              quantity
              description
              createdAt
              acceptedAt
              suspendedAt
              pdfFile
              estimateParts {
                id
                title
                description
                orderKey

                prices {
                  id
                  amount
                  unit
                  isDeposit
                }

                tasks {
                  id
                  label
                  estimatedTime
                  unit
                }
              }
            }
          }
        }`,
        {
          id: parseInt($stateParams.id),
        },
      ).then(function(data) {
        $scope.loading = false;
        $scope.project = data.projects.project;
        $scope.project._isEditing = false;
        $scope.isEditing = false;
        if (!!editingPartId) {
          $scope.project.estimateParts.forEach(x => { if (x.id == editingPartId) x._isEditing = true; });
          $scope.isEditing = true;
        }
      }, function(errors) {
        $scope.loading = false;
        $scope.isEditing = false;
        toastr.error(errors[0].message, "Impossibile caricare il progetto");
      });
    };

    $scope.load(true);

    $scope.initEdit = (estimatePart) =>
    {
      if ($scope.isEditing)
        return;
      $scope.isEditing = true;
      estimatePart._isEditing = true;
    };

    $scope.initProjectInfoEdit = () => {
      if ($scope.isEditing)
        return;
      $scope.isEditing = true;
      $scope.project._isEditing = true;
    };

    $scope.saveDescription = (description) =>
    {
      $scope.softLoading = true;
      newApiClient.call(`
        mutation($input: UpdateProjectInput!) {
          projects {
            updateProject(input: $input) {
              id
            }
          }
        }`,
        {
          input: {
            projectId: parseInt($stateParams.id),
            description: description,
          }
        },
      ).then(function(data) {
        $scope.softLoading = false;
        $scope.project._isEditing = false;
        $scope.isEditing = false;
        $scope.load(false);
      }, function(errors) {
        $scope.softLoading = false;
        $scope.project._isEditing = false;
        $scope.isEditing = false;
        toastr.error(errors[0].message, "Impossibile modificare la descrizione");
      });
    };

    $scope.createEstimatePart = (atOrderKey) =>
    {
      $scope.softLoading = true;
      newApiClient.call(`
        mutation($input: CreateEstimatePartInput!) {
          estimateParts {
            createEstimatePart(input: $input) {
              id
            }
          }
        }`,
        {
          input: {
            title: "",
            description: "",
            parent: {
              projectId: parseInt($stateParams.id),
            },
            orderKey: atOrderKey,
          }
        },
      ).then(function(data) {
        $scope.softLoading = false;
        $scope.load(false, data.estimateParts.createEstimatePart.id);
        $scope.isEditing = false;
      }, function(errors) {
        $scope.softLoading = false;
        toastr.error(errors[0].message, "Impossibile creare una sezione preventivo");
      });
    };

    $scope.saveEstimatePart = (estimatePart) =>
    {
      $scope.softLoading = true;
      newApiClient.call(`
        mutation($input: UpdateEstimatePartInput!) {
          estimateParts {
            updateEstimatePart(input: $input) {
              id
            }
          }
        }`,
        {
          input: {
            estimatePartId: estimatePart.id,
            title: estimatePart.title,
            description: estimatePart.description
          }
        },
      ).then(function(data) {
        $scope.softLoading = false;
        estimatePart._isEditing = false;
        $scope.load(false);
        $scope.isEditing = false;
      }, function(errors) {
        $scope.softLoading = false;
        estimatePart._isEditing = false;
        toastr.error(errors[0].message, "Impossibile modificare la sezione preventivo");
      });
    };

    $scope.removeEstimatePart = (estimatePart) =>
    {
      $scope.softLoading = true;
      newApiClient.call(`
        mutation($id: Int!) {
          estimateParts {
            removeEstimatePart(estimatePartId: $id)
          }
        }`,
        { id: estimatePart.id },
      ).then(function(data) {
        $scope.softLoading = false;
        $scope.isEditing = false;
        $scope.load(false);
      }, function(errors) {
        $scope.softLoading = false;
        $scope.isEditing = false;
        toastr.error(errors[0].message, "Impossibile rimuovere la sezione preventivo");
      });
    };

    $scope.isInEditMode = () => !!$scope.project.estimateParts.find(x => x._isEditing || x._isCreatingPrice);

    $scope.onDropPart = (orderKey, part) =>
    {
      var newOrderKey = (orderKey > part.orderKey)
        ? orderKey - 1 // dobbiamo contare che 1 è occupato dall'elemento che si sta muovendo
        : orderKey;
      if (newOrderKey != part.orderKey)
      {
        $scope.softLoading = true;
        newApiClient.call(`
          mutation($input: UpdateEstimatePartInput!) {
            estimateParts {
              updateEstimatePart(input: $input) {
                id
              }
            }
          }`,
          {
            input: {
              estimatePartId: part.id,
              orderKey: newOrderKey
            }
          },
        ).then(function(data) {
          $scope.softLoading = false;
          $scope.load(false);
        }, function(errors) {
          $scope.softLoading = false;
          toastr.error(errors[0].message, "Impossibile spostare la sezione preventivo");
        });
      }
    };

    /* Price management */
    $scope.createNewPrice = (estimatePart) => {
      estimatePart._isCreatingPrice = true;
      estimatePart.priceDraft = {
        estimatePartId: estimatePart.id,
        amount: 0.0,
        unit: 'ONCE',
        isDeposit: false,
      };
      $scope.isEditing = true;
    };

    $scope.createEstimatePrice = (estimatePart, data) => {
      $scope.softLoading = true;
      newApiClient.call(`
        mutation($input: CreateEstimatePriceInput!) {
          estimatePrices {
            createEstimatePrice(input: $input) {
              id
            }
          }
        }`,
        {
          input: data,
        }
      ).then(function(data) {
        $scope.softLoading = false;
        $scope.isEditing = false;
        $scope.load(false);
      }, function(errors) {
        $scope.softLoading = false;
        $scope.isEditing = false;
        toastr.error(errors[0].message, "Impossibile creare prezzo");
      });
    };

    $scope.initPriceEditing = (price) => {
      if ($scope.isEditing)
        return;
      $scope.isEditing = true;
      price._isEditing = true;
    };

    $scope.updateEstimatePrice = (price) => {
      $scope.softLoading = true;
      newApiClient.call(`
        mutation($input: UpdateEstimatePriceInput!) {
          estimatePrices {
            updateEstimatePrice(input: $input) {
              id
            }
          }
        }`,
        {
          input: {
            estimatePriceId: price.id,
            amount: price.amount,
            unit: price.unit,
            isDeposit: price.isDeposit,
          }
        },
      ).then(function(data) {
        $scope.softLoading = false;
        $scope.isEditing = false;
        $scope.load(false);
      }, function(errors) {
        $scope.softLoading = false;
        $scope.isEditing = false;
        toastr.error(errors[0].message, "Impossibile creare prezzo");
      });
    };

    $scope.removeEstimatePrice = (price) => {
      $scope.softLoading = true;
      newApiClient.call(`
        mutation($id: Int!) {
          estimatePrices {
            removeEstimatePrice(estimatePriceId: $id)
          }
        }`,
        { id: price.id, },
      ).then(function(data) {
        $scope.softLoading = false;
        $scope.isEditing = false;
        $scope.load(false);
      }, function(errors) {
        $scope.softLoading = false;
        $scope.isEditing = false;
        toastr.error(errors[0].message, "Impossibile rimuovere prezzo");
      });
    };

    /* Task management */
    $scope.createNewTask = (estimatePart) => {
      estimatePart._isCreatingTask = true;
      estimatePart.taskDraft = {
        estimatePartId: estimatePart.id,
        label: "Realizzazione",
        hoursAmount: 1,
        unit: 'ONCE',
      };
      $scope.isEditing = true;
    };

    $scope.createEstimateTask = (estimatePart, data) => {
      $scope.softLoading = true;
      newApiClient.call(`
        mutation($input: CreateEstimateTaskInput!) {
          estimateTasks {
            createEstimateTask(input: $input) {
              id
            }
          }
        }`,
        {
          input: {
            estimatePartId: estimatePart.id,
            label: data.label,
            estimatedTime: Convert.fromHoursToDuration(data.hoursAmount),
            unit: data.unit,
          }
        },
      ).then(function(data) {
        $scope.softLoading = false;
        $scope.isEditing = false;
        $scope.load(false);
      }, function(errors) {
        $scope.softLoading = false;
        $scope.isEditing = false;
        toastr.error(errors[0].message, "Impossibile creare task");
      });
    };

    $scope.initTaskEditing = (task) => {
      if ($scope.isEditing)
        return;
      $scope.isEditing = true;
      task._isEditing = true;
      task.hoursAmount = parseDuration(task.estimatedTime).asHours();
    };

    $scope.updateEstimateTask = (task) => {
      $scope.softLoading = true;
      newApiClient.call(`
        mutation($input: UpdateEstimateTaskInput!) {
          estimateTasks {
            updateEstimateTask(input: $input) {
              id
            }
          }
        }`,
        {
          input: {
            estimateTaskId: task.id,
            label: task.label,
            estimatedTime: Convert.fromHoursToDuration(task.hoursAmount),
            unit: task.unit,
          }
        },
      ).then(function(data) {
        $scope.softLoading = false;
        $scope.isEditing = false;
        $scope.load(false);
      }, function(errors) {
        $scope.softLoading = false;
        $scope.isEditing = false;
        toastr.error(errors[0].message, "Impossibile creare task");
      });
    };

    $scope.removeEstimateTask = (task) => {
      $scope.softLoading = true;
      newApiClient.call(`
        mutation($id: Int!) {
          estimateTasks {
            removeEstimateTask(estimateTaskId: $id)
          }
        }`,
        { id: task.id, },
      ).then(function(data) {
        $scope.softLoading = false;
        $scope.isEditing = false;
        $scope.load(false);
      }, function(errors) {
        $scope.softLoading = false;
        $scope.isEditing = false;
        toastr.error(errors[0].message, "Impossibile rimuovere task");
      });
    };

    $scope.openAcceptModal = async function() {
      try {
        const acceptData = await $uibModal.open({
          templateUrl: 'templates/progettodettaglio/modals/accept.html',
          controller: 'ProjectsModalAcceptCtrl',
          resolve: {},
        }).result;

        const pricingData = await $uibModal.open({
          templateUrl: 'templates/progettodettaglio/modals/pricing.html',
          controller: 'ProjectsModalPricingCtrl',
          resolve: {
            project: () => $scope.project,
          },
        }).result;

        const hasAnyTask = $scope.project.estimateParts.some(part => part.tasks.length > 0);
        var scheduleData = { estimateTasks: [] };
        if (hasAnyTask) {
          scheduleData = await $uibModal.open({
            templateUrl: 'templates/progettodettaglio/modals/schedule.html',
            controller: 'ProjectsModalScheduleCtrl',
            resolve: {
              project: () => $scope.project,
            },
          }).result;
        }

        $scope.softLoading = true;
        await newApiClient.call(`
          mutation($input: AcceptProjectInput!) {
            projects {
              acceptProject(input: $input) {
                id
              }
            }
          }`,
          {
            input: {
              projectId: $scope.project.id,
              date: acceptData.date,
            }
          },
        );

        await newApiClient.call(`
          mutation($input: UpdateProjectInput!) {
            projects {
              updateProject(input: $input) {
                id
              }
            }
          }`,
          {
            input: {
              projectId: $scope.project.id,
              pricing: {
                fixedPrice: pricingData.price,
              }
            },
          }
        );

        scheduleData.estimateTasks.forEach(async (task) =>
        {
          await newApiClient.call(`
            mutation($input: CreateTaskInput!) {
              tasks {
                createTask(input: $input) {
                  id
                }
              }
            }`,
            {
              input: {
                projectId: $scope.project.id,
                name: task.label,
                note: null,
                estimatedTime: task.estimatedTime,
              }
            },
          );
        });
        $scope.softLoading = false;
        toastr.success("Progetto accettato");

        // Il timeout è necessario perché a quanto pare altrimenti il tutto viene
        // cambiato troppo velocemente e le vecchie API (PHP) fanno il fetch della lista
        // vecchia.
        setTimeout(function(){ $rootScope.$broadcast("projectTabChange"); }, 3000);
        $scope.load(false);
      } catch (errors) {
        console.error(errors);
        if (errors === 'cancel' || errors === 'backdrop click') {
          return;
        }
        toastr.error(errors[0].message, "Errore");
      } finally {
        $scope.softLoading = false;
        $scope.isEditing = false;
      }
    };

    $scope.openRefuseModal = async function() {
      try {
        const refuseData = await $uibModal.open({
          templateUrl: 'templates/progettodettaglio/modals/refuse.html',
          controller: 'ProjectsModalRefuseCtrl',
          resolve: {},
        }).result;

        $scope.softLoading = true;
        await newApiClient.call(`
          mutation ($input: UpdateProjectInput!) {
            projects {
              updateProject(input: $input) {
                id
              }
            }
          }`,
          {
            input: {
              projectId: $scope.project.id,
              status: {
                type: 'SUSPENDED',
                date: refuseData.date,
              },
            }
          },
        );
        $scope.softLoading = false;
        toastr.success("Progetto rifiutato");

        // Il timeout è necessario perché a quanto pare altrimenti il tutto viene
        // cambiato troppo velocemente e le vecchie API (PHP) fanno il fetch della lista
        // vecchia.
        setTimeout(function(){ $rootScope.$broadcast("projectTabChange"); }, 3000);
        $scope.load(false);
      } catch (errors) {
        console.error(errors);
        if (errors === 'cancel' || errors === 'backdrop click') {
          return;
        }
        toastr.error(errors[0].message, "Errore");
      } finally {
        $scope.softLoading = false;
        $scope.isEditing = false;
      }
    };

  }