import { InjectionToken } from '@angular/core';

export const UIRouterState = new InjectionToken("UIRouterState");
export const uiRouterStateProvider = {
  provide: UIRouterState,
  useFactory: (i: any) => i.get('$state'),
  deps: ['$injector'],
}

export const UIRouterLocation = new InjectionToken("UIRouterLocation");
export const uiRouterLocationProvider = {
  provide: UIRouterLocation,
  useFactory: (i: any) => i.get('$location'),
  deps: ['$injector'],
}

export const User = new InjectionToken("User");
export const userProvider = {
  provide: User,
  useFactory: (i: any) => i.get('User'),
  deps: ['$injector'],
}