import * as angular from 'angular';
import toastr from 'toastr';

angular
  .module('screeningcanvas')
  .directive('paymentMethodSelector', ['newApiClient', function (newApiClient) {
    return {
      restrict: 'A',
      scope: {
        optional: '=?',
        optionalText: "@",
      },
      template: '<option ng-if="optional" value="">{{ (!!optionalText) ? optionalText : "Nessuno" }}</option><option value="{{ item.id }}" ng-repeat="item in items">{{ item.label }}</option>',
      replace: false,
      controller: ['$scope', function ($scope) {
        $scope.items = [];

        $scope.fetch = function () {
          newApiClient.call(`
            query {
              paymentMethods {
                allPaymentMethods(order: LABEL_ASC) {
                  id
                  label
                }
              }
            }`,
            {},
          ).then(function (data) {
            $scope.items = data.paymentMethods.allPaymentMethods;
          }, function () {
            toastr.error("Impossibile caricare i metodi di pagamento");
          });
        };
        $scope.fetch();
      }],
    };
  }]);
