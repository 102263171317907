import * as angular from 'angular';
import * as moment from 'moment';
import toastr from 'toastr';
import { Convert } from '../../utils';

angular
  .module('screeningcanvas')
  .controller('ProgettiCtrl', ProgettiController);

ProgettiController.$inject = ['$scope', 'clientStorage', 'Utility', 'User', '$state', 'newApiClient'];

function ProgettiController($scope, clientStorage, Utility, User, $state, newApiClient) {
  $scope.loading = false;
  const user = User.getUser();
  $scope.isSuperAdmin = user.isSuperAdmin;
  $scope.isAdmin = user.isAdmin;
  $scope.canSeePrice = $scope.isAdmin || $scope.isSuperAdmin;
  $scope.sommeDaVisualizzare = [];

  const filtersCookieName = 'filtri_progetti_v2';
  $scope.defaultFilters = {
    fromDate: null,
    toDate: null,
    year: null,
    query: '',
    companyId: null,
    projectTypeId: null,
    referenceUserId: null,
    statusType: null,
    fatturato: 'fatturato-e-non',
    consegnato: 'consegnato-e-non',
    stampa: 'stampa-e-non',
    ticket: 'ticket-e-non',
  };
  $scope.title = $state.current.data.pageTitle;
  $scope.fixedFilters = $state.current.data.fixedFilters;
  $scope.defaultFilters = { ...$scope.defaultFilters, ...$scope.fixedFilters };
  $scope.filtri = Utility.getAndFixFilters(filtersCookieName, $scope.defaultFilters);
  $scope.filtri = { ...$scope.filtri, ...$scope.fixedFilters };

  $scope.progetti = [];

  $scope.order = 'CREATED_AT_DESC';
  $scope.paginator = {
    page: 0,
    perPage: 50,
    totalPages: 0,
  };

  const projectFragment = `
    id
    status {
      type
      updatedAt
    }
    customer {
      name
    }
    title
    folderCode
    note
    referenceMemberships: memberships(filter: { isReferent: true }) {
      user {
        id
        fullName
      }
    }
    invoiceReferences {
      id
    }
    waybillReferences {
      id
    }
    isResale
    purchaseOrderCode
    customerProductCode
    quantity
    deliveredQuantity
    price @include(if: $canSeePrice)
    pricing @include(if: $canSeePrice) {
      fixedPrice
      unitPrice
    }
    billedPrice @include(if: $canSeePrice)
    unit
    hasQuantityAsBillableHours
    deadline
    createdAt
    activities {
      totalTime
    }
  `;

  $scope.cerca = function (page = 0) {
    page = (page == null) ? $scope.paginator.page : page;
    $scope.loading = true;

    var isBillable = null;
    switch ($scope.filtri.fatturato) {
      case 'fatturato': isBillable = false; break;
      case 'non-fatturato': isBillable = true; break;
    }

    var isDeliverable = null;
    switch ($scope.filtri.consegnato) {
      case 'consegnato': isDeliverable = false; break;
      case 'non-consegnato': isDeliverable = true; break;
    }

    var isResale = null;
    switch ($scope.filtri.stampa) {
      case 'stampa': isResale = true; break;
      case 'creativita': isResale = false; break;
    }

    var hasQuantityAsBillableHours = null;
    switch ($scope.filtri.ticket) {
      case 'ticket': hasQuantityAsBillableHours = true; break;
      case 'non-ticket': hasQuantityAsBillableHours = false; break;
    }

    newApiClient.call(`
      query ($filter: ProjectFilter!, $pagination: Pagination!, $order: ProjectOrder!, $canSeePrice: Boolean!) {
        projects {
          allProjects {
            list(filter: $filter, pagination: $pagination, order: $order) {
              ${projectFragment}
            }
            count(filter: $filter)
            price(filter: $filter) @include(if: $canSeePrice)
            billedPrice(filter: $filter) @include(if: $canSeePrice)
            expenses(filter: $filter) @include(if: $canSeePrice)
            totalTime(filter: $filter)
          }
        }
      }
    `, {
      filter: {
        fromDate: Convert.toNonEmptyString($scope.filtri.fromDate),
        toDate: Convert.toNonEmptyString($scope.filtri.toDate),
        query: Convert.toNonEmptyString($scope.filtri.query),
        customerIds: Convert.toSingletonIntList($scope.filtri.companyId),
        projectTypeIds: Convert.toSingletonIntList($scope.filtri.projectTypeId),
        referenceUserIds: Convert.toSingletonIntList($scope.filtri.referenceUserId),
        statusTypes: Convert.toSingletonList($scope.filtri.statusType),
        isBillable: isBillable,
        isDeliverable: isDeliverable,
        isResale: isResale,
        hasQuantityAsBillableHours: hasQuantityAsBillableHours,
      },
      pagination: {
        take: $scope.paginator.perPage,
        skip: $scope.paginator.perPage * page,
      },
      order: $scope.order,
      canSeePrice: $scope.canSeePrice,
    }).then(function (data) {
      $scope.loading = false;
      clientStorage.put(filtersCookieName, $scope.filtri);
      $scope.progetti = data.projects.allProjects.list;

      const recordsCount = data.projects.allProjects.count;
      $scope.count = recordsCount;
      $scope.paginator.totalPages = Math.ceil(recordsCount / $scope.paginator.perPage);
      $scope.paginator.page = page;

      const price = data.projects.allProjects.price;
      const billedPrice = data.projects.allProjects.billedPrice;
      const billablePrice = price - billedPrice;
      const expenses = data.projects.allProjects.expenses;
      const gains = price - expenses;
      const totalTime = Convert.fromDurationToHours(data.projects.allProjects.totalTime);

      if ($scope.canSeePrice) {
        $scope.sommeDaVisualizzare = [];
        $scope.sommeDaVisualizzare.push({ label: 'Ore lavorate', amount: totalTime });
        $scope.sommeDaVisualizzare.push({ label: 'Guadagno', amount: gains, prefix: "€" });
        $scope.sommeDaVisualizzare.push({ label: 'Fornitori', amount: expenses, prefix: "€" });
        $scope.sommeDaVisualizzare.push({ label: 'Prezzo da fatturare', amount: billablePrice, prefix: "€" });
        $scope.sommeDaVisualizzare.push({ label: 'Prezzo fatturato', amount: billedPrice, prefix: "€" });
        $scope.sommeDaVisualizzare.push({ label: 'Prezzo totale', amount: price, prefix: "€" });
      }
    }, function (errors) {
      $scope.loading = false;
      toastr.error("Impossibile caricare i progetti");
      console.error(errors);
    });

    $scope.sortColumn = function (first, second) {
      if ($scope.order == first) {
        $scope.order = second;
      } else {
        $scope.order = first;
      }
      $scope.cerca();
    };
  };
  $scope.cerca();

  $scope.resetFilters = function () {
    $scope.filtri = angular.copy($scope.defaultFilters);
  };

  $scope.editProject = function (p) {
    p.isEditing = true;
    p._data = {
      projectId: parseInt(p.id),
      statusType: p.status.type,
      pricing: angular.copy(p.pricing),
      quantity: p.quantity,
      deadline: Convert.toNonEmptyString(p.deadline),
    };
  };

  $scope.annullaEditing = function (p) {
    p.isEditing = false;
  };

  var annoCorrente = new Date().getFullYear();
  $scope.years = [];
  for (var i = 2015; i <= (annoCorrente + 1); i++) {
    $scope.years.push(i);
  };
  $scope.$watch('filtri.year', function (newVal, oldVal) {
    if (typeof (newVal) != 'undefined' && newVal != null) {
      const yearStart = moment([newVal]).format('YYYY-MM-DD');
      const yearEnd = moment([newVal]).endOf('year').format('YYYY-MM-DD');
      $scope.filtri.fromDate = yearStart;
      $scope.filtri.toDate = yearEnd;
    }
  });
  $scope.$watch('filtri.fromDate', function (newVal, oldVal) {
    if (typeof (newVal) != 'undefined' && newVal != null) {
      if (!!$scope.filtri.year) {
        const yearStart = moment([$scope.filtri.year]).format('YYYY-MM-DD');
        if (newVal != yearStart) {
          $scope.filtri.year = null;
        }
      }
    }
  });
  $scope.$watch('filtri.toDate', function (newVal, oldVal) {
    if (typeof (newVal) != 'undefined' && newVal != null) {
      if (!!$scope.filtri.year) {
        const yearEnd = moment([newVal]).endOf('year').format('YYYY-MM-DD');
        if (newVal != yearEnd) {
          $scope.filtri.year = null;
        }
      }
    }
  });

  $scope.inlineProjectUpdate = function (p) {
    const input = {
      projectId: parseInt(p._data.projectId),
      status: {
        type: p._data.statusType,
        date: (p._data.statusType != 'OPEN')
          ? moment().format('YYYY-MM-DD')
          : null,
      },
      pricing: (p._data.pricing.fixedPrice != null && typeof (p._data.pricing.fixedPrice) != 'undefined')
        ? { fixedPrice: Convert.toFloat(p._data.pricing.fixedPrice) }
        : { unitPrice: Convert.toFloat(p._data.pricing.unitPrice) },
      quantity: (p.hasQuantityAsBillableHours)
        ? null
        : Convert.toFloat(p._data.quantity),
      deadline: p._data.deadline,
    };

    newApiClient.call(`
      mutation($input: UpdateProjectInput!, $canSeePrice: Boolean!) {
        projects {
          updateProject(input: $input) {
            ${projectFragment}
          }
        }
      }
    `, {
      input: input,
      canSeePrice: $scope.canSeePrice,
    })
      .then(function (data) {
        p.isEditing = false;
        const newProject = data.projects.updateProject;
        angular.extend(p, newProject);
        toastr.success("Progetto modificato");
      }, function (error) {
        toastr.error("Impossibile modificare progetto");
      });
  };
}