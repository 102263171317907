import * as angular from 'angular';
import toastr from 'toastr';

angular.module('screeningcanvas')
.controller('GestioneAreeCtrl', ['$scope', 'newApiClient',
  function ($scope, newApiClient) {
    $scope.aggiornaAree = function() {
      newApiClient.call(`
        query {
          areas {
            allAreas {
              id
              name
            }
          }
        }`
      ).then(function(data) {
        $scope.aree = data.areas.allAreas.map(x => ({
          ...x, 
          editing: false,
          nome_temp: x.name,
        }));
      }, function(errors) {
        toastr.error(errors[0].extensions.userMessage, "Impossibile caricare le aree");
      });
    };

    $scope.aggiornaAree();

    $scope.rinominaArea = function(a) {
      a.editing = true;
    };

    $scope.annullaEditing = function(a) {
      a.editing = false;
      a.nome_temp = a.name;
    };

    $scope.aggiornaArea = function(a) {
      newApiClient.call(`
        mutation($id: Int!, $input: AreaInput!) {
          areas {
            updateArea(areaId: $id, input: $input) {
              id
              name
            }
          }
        }`,
        {
          id: a.id,
          input: { name: a.nome_temp }
        }
      ).then(function(data) {
        a.editing = false;
        a.name = data.areas.updateArea.name;
        $scope.aggiornaAree();
      }, function(errors) {
        toastr.error(errors[0].extensions.userMessage, "Impossibile modificare l'area");
      });
    };

    $scope.nuovaArea = function() {
      newApiClient.call(`
        mutation($input: AreaInput!) {
          areas {
            createArea(input: $input) {
              id
            }
          }
        }`,
        {
          input: { name: '- New Area -' }
        }
      ).then(function(data) {
        $scope.aggiornaAree();
      }, function(errors) {
        toastr.error(errors[0].extensions.userMessage, "Impossibile creare una nuova area");
      });
    };
  }
]);
