import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .filter('formatPrice', function () {
    return function (input) {
      if (input != null && typeof input !== 'undefined') {
        return "€ " + input.toLocaleString('it-IT', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      } else {
        return null;
      }
    };
  });
