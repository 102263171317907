import * as angular from 'angular';

angular.module('screeningcanvas').filter('mimeTypeIcon', function () {
  const iconClasses = {
    "text/css": "css.svg",

    "text/csv": "csv.svg",

    "application/msword": "doc.svg",
    "application/vnd.ms-word": "doc.svg",
    "application/vnd.oasis.opendocument.text": "doc.svg",
    "application/vnd.openxmlformats-officedocument.wordprocessingml": "doc.svg",

    "text/html": "html.svg",

    "application/json": "json-file.svg",

    "application/pdf": "pdf.svg",

    "image/jpeg": "jpg.svg",
    "image/png": "png.svg",
    "image/svg+xml": "svg.svg",

    "application/xml": "xml.svg",
    "text/xml": "xml.svg",

    "application/rtf": "rtf.svg",
    "text/plain": "txt.svg",

    "application/vnd.ms-excel": "xls.svg",
    "application/vnd.openxmlformats-officedocument.spreadsheetml": "xls.svg",
    "application/vnd.oasis.opendocument.spreadsheet": "xls.svg",

    "application/vnd.ms-powerpoint": "ppt.svg",
    "application/vnd.openxmlformats-officedocument.presentationml": "ppt.svg",
    "application/vnd.oasis.opendocument.presentation": "ppt.svg",

    "application/gzip": "zip.svg",
    "application/zip": "zip.svg",
  };

  return function (mimeType) {
    let fa = 'file.svg';
    for (let key in iconClasses) {
      if (iconClasses.hasOwnProperty(key) && mimeType.search(key) === 0) {
        fa = iconClasses[key];
      }
    }
    return `${fa}`;
  };
});