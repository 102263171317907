import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .directive('toolbarForFilters', function () {
    return {
      restrict: 'E',
      template: `
          <div class="page-toolbar">
            <div class="btn-group">
              <pulsantefiltro></pulsantefiltro>
            </div>
          </div>`
    };
  });
