import * as angular from 'angular';
import * as moment from 'moment';
import toastr from 'toastr';

angular
  .module('screeningcanvas')
  .controller('ElencoFattureCtrl', ElencoFattureController);

  ElencoFattureController.$inject = ['$scope', '$uibModal', 'fileClient', 'newApiClient', '$http'];

  function ElencoFattureController($scope, $uibModal, fileClient, newApiClient, $http) {
    $scope.ordinamento = '-data_fattura';
    $scope.clientefiltro = null;
    $scope.loading = false;
    $scope.fatture = [];
    $scope.clienti = [];
    $scope.filtriInterni = {};
    $scope.cerca = cerca;
    $scope.invoicesBeingSynced = new Set();
    $scope.meseIsDisabled = false;

    var annoCorrente = new Date().getFullYear();
    $scope.anni = [];
    for (var i = 2015; i <= annoCorrente; i++) {
      $scope.anni.push(i);
    };
    $scope.filters = {
      anno: annoCorrente,
      mese: "-1",
      customer_id: "",
      type: "invoice",
    };

    $scope.paginator = {
      page: 0,
      perPage: 50,
      totalPages: 0,
    };

    const invoiceFragment = `
      id
      type
      yearlyCode
      date
      status {
        type
        isIssued
        isBeingSent
      }
      recipient {
        id
        name
      }
      rows {
        project {
          billablePrice
          waybillReferences(order: DATE_ASC) {
            id
            yearlyCode
            date
            pdfFile
          }
        }
      }
      total {
        netTotal
        taxes
        grossTotal
      }
      xmlFile
      pdfFile
      digitalInvoices {
        id
        status {
          type
          isIssued
          isBeingSent
        }
        createdAt
        xmlFile
        xmlPdfFile
        notifications {
          id
          type
          createdAt
          xmlFile
        }
      }
    `;

    function mapInvoice(f) {
      const totalBillablePrice = f.rows.reduce((acc, val) => acc + val.project.billablePrice, 0);
      const allDDTs = f.rows.flatMap((row) => row.project.waybillReferences);
      const uniqueDDTs = allDDTs.filter((ddt, index, self) => self.findIndex((x) => x.id == ddt.id) === index);
      return {
        ...f,
        uniqueDDTs: uniqueDDTs,
        totalBillablePrice: totalBillablePrice,
      };
    };

    function cerca(page = 0, loading = false) {
      $scope.loading = loading;
      page = (page == null) ? $scope.paginator.page : page;

      var fromDate = moment({
        year: (!!$scope.filters.anno) ? $scope.filters.anno : "2015",
        month: ($scope.filters.mese != "-1") ? (parseInt($scope.filters.mese) - 1) : 0,
        day: 1
      });

      var toDateYear = (!!$scope.filters.anno) ? $scope.filters.anno : moment().year();
      var toDateMonth = ($scope.filters.mese != "-1") ? (parseInt($scope.filters.mese) - 1) : 11;
      var toDateDay = moment().clone().year(toDateYear).month(toDateMonth).endOf('month').date();
      var toDate = moment({
        year: toDateYear,
        month: toDateMonth,
        day: toDateDay,
      });

      var types = ['INVOICE', 'INVOICE_DEPOSIT'];
      if ($scope.filters.type === 'credit_note')
        types = ['CREDIT_NOTE'];

      newApiClient.call(`
        query($filter: InvoiceFilter!, $order: InvoiceOrder!, $pagination: Pagination!) {
          invoices {
            allInvoices {
              list(filter: $filter, order: $order, pagination: $pagination) {
                ${invoiceFragment}
              }
              count(filter: $filter)
            }
          }
        }
      `, {
        filter: {
          types: types,
          fromDate: fromDate.format('YYYY-MM-DD'),
          toDate: toDate.format('YYYY-MM-DD'),
          recipientIds: ($scope.filters.customer_id != "") ? [parseInt($scope.filters.customer_id)] : [],
        },
        pagination: {
          take: $scope.paginator.perPage,
          skip: $scope.paginator.perPage * page,
        },
        order: 'YEARLY_CODE_DESC',
      }).then(function(data) {
        $scope.fatture = data.invoices.allInvoices.list.map(mapInvoice);
        $scope.paginator.totalPages = Math.ceil(data.invoices.allInvoices.count / $scope.paginator.perPage);
        $scope.paginator.page = page;
        $scope.loading = false;
      }, function() {
        $scope.loading = false;
        toastr.error("Impossibile caricare le fatture");
      });
    }

    $scope.displayStatus = (invoice) =>
    {
      switch (invoice.status.type) {
        case 'DRAFT':
          return { color: 'inherit', text: 'Da inviare', isTerminal: false };
        case 'DISCARDED':
          return { color: 'red', text: 'Scartata', isTerminal: true };
        case 'DELIVERED':
          return { color: 'green', text: 'Consegnata', isTerminal: true };
        case 'NOT_DELIVERED':
          return { color: 'green', text: 'Mancata consegna', isTerminal: true };
        case 'AWAITING':
          return { color: 'orange', text: 'Inviata (in attesa)', isTerminal: false };
      }
    };

    $scope.showInvoiceXML = function(invoice) {
      const url = invoice.xmlFile;
      fileClient.displayStyledXML(url, "/xsl/digital_invoice.xsl");
    }

    // Mutations
    $scope.sendInvoice = (invoice) => {
      $uibModal.open({
        templateUrl: 'templates/elencofatture/modal_send.html',
        controller: 'ModalSendInvoiceCtrl',
        resolve: {
          invoice: () => invoice,
        },
      }).result.then(function (data) {
        newApiClient.call(`
          mutation($id: Int!) {
            invoices {
              sendInvoice(invoiceId: $id) {
                id
              }
            }
          }
        `, { id: invoice.id }).then(function(data) {
          toastr.success("Fattura inviata con successo");
          $scope.cerca();
        }, function(errors) {
          const message = errors[0].extensions.userMessage;
          toastr.error(message, "Impossibile inviare la fattura", { timeOut: 0, extendedTimeOut: 0 });
        });
      });
    };

    $scope.cerca(0, true);

    $scope.openDigitalInvoicesModal = function(invoice) {
      $uibModal.open({
        templateUrl: 'templates/elencofatture/modal_digital_invoices.html',
        controller: 'ModalDigitalInvoicesForInvoiceCtrl',
        resolve: {
          digitalInvoices: () => invoice.digitalInvoices,
        },
      }).result.then(function (data) {
        newApiClient.call(`
          mutation($id: Int!) {
            invoices {
              sendInvoice(invoiceId: $id) {
                id
              }
            }
          }
        `, { id: invoice.id }).then(function(data) {
          toastr.success("Fattura inviata con successo");
          $scope.cerca();
        }, function(errors) {
          const message = errors[0].extensions.userMessage;
          toastr.error(message, "Impossibile inviare la fattura", { timeOut: 0, extendedTimeOut: 0 });
        });
      });
    };

    $scope.$watch('filters.anno', function(newVal, oldVal) {
      if (!newVal) {
        $scope.filters.mese = "-1";
        $scope.meseIsDisabled = true;
      } else {
        $scope.meseIsDisabled = false;
      }
    });
  }
