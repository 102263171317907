import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .controller('ModalEditProjectType', ModalEditProjectType);

  ModalEditProjectType.$inject = ['$scope', '$uibModalInstance', 'title', 'defaults'];

  function ModalEditProjectType($scope, $uibModalInstance, title, defaults) {
    $scope.title = title;

    $scope.$on('modal.closing', function () {
      $scope.clear();
    });

    $scope.clear = function() {
      $scope.data = {
        name: defaults.name || '',
      };
    };
    $scope.clear();

    $scope.canSave = () => (
      !!$scope.data.name
    );

    $scope.save = function () {
      $uibModalInstance.close($scope.data);
    };
  }
