import * as angular from 'angular';
import toastr from 'toastr';

angular
  .module('screeningcanvas')
  .directive('projectMembershipSelector', ['newApiClient', function (newApiClient) {
    return {
      restrict: 'E',
      require: '?ngModel',
      template: `
        <div class="project-membership-selector">
          <div class="project-membership-user" ng-repeat="user in users">
            <div>
              <img width="29px" height="29px" class="img-circle" src="{{user.avatar}}" />
            </div>
            <div>
              <span>{{user.fullName}}</span>
              <small style="display: block">{{user.username}}</small>
            </div>
            <div style="font-size: 20px; text-align: right; width: 100%;">
              <button ng-disabled="!!user.isFrozen" class="btn btn-outline" ng-class="{'btn-success': !!user.isSelected}" ng-click="toggleIsSelected(user)">
                <span ng-if="!!user.isSelected"><i class="fa fa-check"></i> Invitato</span>
                <span ng-if="!user.isSelected"><i class="fa fa-check"></i> Invitato</span>
              </button>
              <button class="btn btn-outline" ng-class="{'btn-primary': !!user.isReferent}" ng-click="toggleIsReferent(user)">
                <span ng-if="!!user.isReferent"><i class="fa fa-star"></i> Riferimento</span>
                <span ng-if="!user.isReferent"><i class="fa fa-star"></i> Riferimento</span>
              </button>
            </div>
          </div>
        </div>
      `,
      replace: true,
      scope: {
        ngModel: '=',
        frozen: '=',
      },
      link: function ($scope, element, attrs, ngModel) {

        $scope.users = [];

        $scope.refreshUsers = function () {
          const memberships = $scope.ngModel;
          if (memberships == null || typeof (memberships) == 'undefined' || $scope.users == null || typeof ($scope.users) == 'undefined')
            return;
          $scope.users.forEach(u => {
            const membership = memberships.find(m => m.userId == u.id);
            if (!!membership) {
              u.isSelected = true;
              u.isReferent = membership.isReferent;
            }
            if ($scope.frozen != null && typeof ($scope.frozen) != 'undefined') {
              u.isFrozen = !!($scope.frozen.find(id => id == u.id));
            }
          });
        };

        $scope.refreshMemberships = function () {
          $scope.ngModel = $scope.users
            .filter(u => u.isSelected)
            .map(u => ({
              isReferent: !!u.isReferent,
              userId: u.id,
            }));
        };

        $scope.fetch = function () {
          newApiClient.call(`
            query($filter: UserFilter, $order: UserOrder!, $pagination: Pagination!) {
              users {
                allUsers {
                  list(filter: $filter, order: $order, pagination: $pagination) {
                    id
                    avatar
                    username
                    fullName
                  }
                }
              }
            }`,
            {
              filter: { isActive: true },
              order: 'EMAIL_ASC',
              pagination: { skip: 0, take: 1000 }
            },
          ).then(function (data) {
            $scope.users = data.users.allUsers.list;
            $scope.refreshUsers();
          }, function () {
            toastr.error("Impossibile caricare gli utenti");
          });
        };
        $scope.fetch();

        $scope.toggleIsReferent = function (user) {
          if (!!user.isReferent) {
            user.isReferent = false;
          } else {
            user.isReferent = true;
            user.isSelected = true;
          }
          $scope.refreshMemberships();
        };
        $scope.toggleIsSelected = function (user) {
          user.isSelected = !user.isSelected;
          $scope.refreshMemberships();
        };

        if (ngModel) {
          ngModel.$formatters.push(function (val) {
            $scope.refreshUsers();
          });
        }

      }
    };
  }]);
