import * as angular from 'angular';
import toastr from 'toastr';

  angular
    .module('screeningcanvas')
    .controller('CostiFornitoriCtrl', CostiFornitoriController);

    CostiFornitoriController.$inject = ['$scope', 'newApiClient', 'clientStorage'];

    function CostiFornitoriController($scope, newApiClient, clientStorage) {
      $scope.totale_costo_fornitori = 0;
      $scope.sommeDaVisualizzare = [];
      $scope.suppliers = [];
      $scope.thereIsACostoInEditMode = false;

      var filter_cookie_name = 'elencocostifornitori_filtri_v2';
      var filtriBase = {
        from: null,
        to: null,
        tipofattura: 'associato-e-non',
        supplierId: null,
      };
      $scope.filtri = clientStorage.get(filter_cookie_name) || angular.copy(filtriBase);

      function getFilter() {
        var isAssociated = null;
        if ($scope.filtri.tipofattura == 'associato')
          isAssociated = true;
        if ($scope.filtri.tipofattura == 'non-associato')
          isAssociated = false;
        return {
          from: $scope.filtri.from,
          to: $scope.filtri.to,
          isAssociated: isAssociated,
        };
      }

      $scope.getRecords = function() {
        
        newApiClient.call(
          `
          query($filter: ProjectExpenseFilter!) {
            companies {
              allCompanies {
                list(pagination: { take: 2000 }) {
                  id
                  name
                  projectExpenses {
                    amount(filter: $filter)
                  }
                }
              }
            }
          }
          `,
          { 
            filter: getFilter(),
          }
        ).then(function(customersData) {
          clientStorage.put(filter_cookie_name, $scope.filtri);
          $scope.suppliers = customersData.companies.allCompanies.list
            .filter(x => x.projectExpenses.amount > 0)
            .filter(x => (!$scope.filtri.supplierId) || (x.id == $scope.filtri.supplierId))
            .map(x => ({
              ...x,
              projectExpenses: {
                amount: x.projectExpenses.amount,
                list: [],
              },
              expanded: false,
            }));
          $scope.totale_costo_fornitori = $scope.suppliers
            .map(x => x.projectExpenses.amount)
            .reduce((a, b) => a + b, 0);
          $scope.sommeDaVisualizzare = [{
            label: 'Totale',
            total: parseFloat($scope.totale_costo_fornitori).toFixed(2),
          }];
        }, function(error) {
          toastr.error("Impossibile caricare i dati fornitori");
        });
      }

      $scope.getRecords();

      $scope.toggleExpand = function(f) {
        f.expanded = !f.expanded;
        if (f.expanded) {
          $scope.caricaDettagliCostiPerFornitore(f);
        };
      }

      $scope.toggleCostoLock = function(costo) {
        costo.locked = !costo.locked;
        $scope.thereIsACostoInEditMode = !costo.locked;
        costo.fattura_acquisto_id_temp = costo.fattura_acquisto_id;
      }

      const projectExpenseFragment = `
        id
        project {
          id
          title
          folderCode
          date
          customer {
            name
          }
        }
        purchaseInvoice {
          id
          type
          code
          date
        }
        price
      `;

      $scope.caricaDettagliCostiPerFornitore = function(f) {
        newApiClient.call(
          `
          query($filter: ProjectExpenseFilter!, $supplierId: Int!) {
            companies {
              company(id: $supplierId) {
                id
                name
                projectExpenses {
                  list(filter: $filter, order: PROJECT_DATE_DESC) {
                    ${projectExpenseFragment}
                  }
                }
              }
            }
          }
          `,
          { 
            supplierId: f.id,
            filter: getFilter(),
          }
        ).then(function(customersData) {
          f.projectExpenses = {
            ...f.projectExpenses,
            list: customersData.companies.company.projectExpenses.list.map(x => ({
              ...x,
              _purchaseInvoiceId: (!!x.purchaseInvoice) 
                ? ""+x.purchaseInvoice.id
                : null,
              locked: true,
            })),
          };
        }, function(error) {
          toastr.error("Impossibile caricare i dettagli fornitore");
        });
      }

      $scope.resetFilters = function() {
        $scope.filtri = angular.copy(filtriBase);
        $scope.getRecords();
      };

      /*
       * @params cid costo id
       *         fid fattura di acquisto id
       */
      $scope.aggiornaAssociazione = function(projectExpense, fornitore) {
        newApiClient.call(
          `mutation($id: Int!, $input: UpdateProjectExpenseInput!) {
            projectExpenses {
              updateProjectExpense(projectExpenseId: $id, input: $input) {
                id
              }
            }
          }
          `,
          { 
            id: projectExpense.id,
            input: {
              purchaseInvoiceId: parseInt(projectExpense._purchaseInvoiceId),
            },
          }
        ).then(function(data) {
          $scope.toggleCostoLock(projectExpense);
          $scope.caricaDettagliCostiPerFornitore(fornitore);
        }, function(error) {
          toastr.error("Impossibile modificare associazione costo");
        });
      }
    }