import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .directive('itemUnitSelector', function () {
    return {
      restrict: 'A',
      template: '<option value="{{ item.id }}" ng-repeat="item in items">{{ item.name }}</option>',
      replace: false,
      scope: {
        optional: '=?',
        optionalText: "@",
      },
      controller: ['$scope', function ($scope) {
        $scope.items = [
          { id: 'NOS', name: 'Nessuno' },
          { id: 'ITEM', name: 'Pezzo' },
          { id: 'HOUR', name: 'Ora' },
        ];
        if ($scope.optional) {
          $scope.items.push({ id: '', name: $scope.optionalText ?? 'Nessuno' });
        }
      }],
    };
  });
