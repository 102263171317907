import * as angular from 'angular';
import toastr from 'toastr';
import { html } from 'common-tags';

var sidebarObject = {
  templateUrl: 'templates/sidebar.html',
  controller: 'SidebarCtrl'
};
angular.module('screeningcanvas',
  ['ngRoute', 'ngMaterial', 'ngCookies', 'ui.router', 'ui.router.upgrade', 'ui.bootstrap',
    'nvd3ChartDirectives', 'ngMessages', 'googlechart', 'duScroll',
    'ngSanitize', 'ui.sortable', 'nvd3', 'angularFileUpload'])
  .value('max_per_page', 16)
  .value('sogliaFatturato', {
    label: '€ 35.000,00',
    value: 35000
  })
  .value('percentuale_iva', 22)
  .config(['$urlServiceProvider', '$locationProvider', '$stateProvider', '$urlRouterProvider', '$uibTooltipProvider', '$animateProvider', '$logProvider',
    function ($urlServiceProvider, $locationProvider, $stateProvider, $urlRouterProvider, $uibTooltipProvider, $animateProvider, $logProvider) {
      // @uirouter hybrid boot
      $urlServiceProvider.deferIntercept();

      $locationProvider.html5Mode(true);
      $logProvider.debugEnabled(true);
      $animateProvider.classNameFilter(/animate/);
      $uibTooltipProvider.options({
        appendToBody: true
      });

      $stateProvider
        .state('login', {
          url: '/login',
          data: {},
          template: html`<login-page></login-page>`
        })
        .state('dashboard', {
          url: '/dashboard',
          data: {},
          template: html`<dashboard-page></dashboard-page>`
        })
        .state('users', {
          url: '/users',
          data: { allowAccessToUserRoles: [1] },
          template: html`<users-page></users-page>`
        })
        .state('progettodettaglio', {
          url: '/progetto/:id/dettaglio/:tab?',
          params: {
            tab: { squash: true, value: null }
          },
          data: { pageTitle: 'Dettaglio progetto' },
          views: {
            '': {
              templateUrl: 'templates/progettodettaglio/progettodettaglio.html',
              controller: 'ProgettoDettaglioCtrl'
            },
            'estimate@progettodettaglio': {
              templateUrl: 'templates/progettodettaglio/tabs/estimate.html',
              controller: 'ProjectsTabsEstimateCtrl'
            },
            'contracts@progettodettaglio': {
              templateUrl: 'templates/progettodettaglio/tabs/contracts.html',
              controller: 'ProjectsTabsContractsCtrl'
            },
            'sidebar@progettodettaglio': sidebarObject
          }
        })
        .state('progettoedit', {
          url: '/progetto/:id/edit',
          data: { pageTitle: 'Modifica progetto' },
          views: {
            '': {
              templateUrl: 'templates/progettodettaglio/progettoedit.html',
              controller: 'ProgettoEditCtrl'
            },
            'sidebar@progettoedit': sidebarObject
          }
        })
        .state('progettonuovo', {
          url: '/progetto/new',
          data: { pageTitle: 'Nuovo progetto' },
          views: {
            '': {
              templateUrl: 'templates/progettodettaglio/progettoedit.html',
              controller: 'ProgettoNewCtrl'
            },
            'sidebar@progettonuovo': sidebarObject
          }
        })

        // Cllenti e referenti
        .state('companies', {
          url: '/companies',
          data: { pageTitle: 'Anagrafiche', allowAccessToUserRoles: [1, 2, 3] },
          views: {
            '': {
              templateUrl: 'templates/companies/list.html',
              controller: 'ClientiCtrl',
              controllerAs: 'vm'
            },
            'sidebar@companies': sidebarObject
          }
        })
        .state('tickets', {
          url: '/tickets',
          data: { hasQuickSidebar: false, pageTitle: 'Ticket' },
          views: {
            '': {
              templateUrl: 'templates/tickets/tickets.html',
              controller: 'TicketsCtrl'
            },
            'sidebar@tickets': sidebarObject
          }
        })
        .state('fatturazioneveloce', {
          url: '/fatturazioneveloce',
          data: { allowAccessToUserRoles: [1, 2], hasQuickSidebar: false, pageTitle: 'Chiusura veloce' },
          views: {
            '': {
              templateUrl: 'templates/fatturazioneveloce/fatturazioneveloce.html',
              controller: 'FatturazioneVeloceCtrl'
            },
            'sidebar@fatturazioneveloce': sidebarObject
          }
        })
        .state('extras', {
          url: '/extras',
          data: { allowAccessToUserRoles: [1, 2], hasQuickSidebar: false, pageTitle: 'Extra' },
          views: {
            '': {
              templateUrl: 'templates/extras/list.html',
              controller: 'ExtrasListCtrl'
            },
            'sidebar@extras': sidebarObject
          }
        })
        .state('editcompany', {
          url: '/companies/:id/edit',
          data: { pageTitle: 'Modifica anagrafica', allowAccessToUserRoles: [1, 2] },
          views: {
            '': {
              templateUrl: 'templates/companies/edit.html',
              controller: 'ClienteDettaglioCtrl',
              controllerAs: 'vm'
            },
            'sidebar@editcompany': sidebarObject
          }
        })
        .state('newcompany', {
          url: '/companies/new',
          data: { pageTitle: 'Nuova anagrafica' },
          views: {
            '': {
              templateUrl: 'templates/companies/new.html',
              controller: 'ClienteNewCtrl'
            },
            'sidebar@newcompany': sidebarObject
          }
        })

        .state('projecttypedetails', {
          url: '/project-types/:id',
          data: { pageTitle: 'Dettaglio tipologia progetto' },
          views: {
            '': {
              templateUrl: 'templates/projecttypes/details.html',
              controller: 'ProjectTypeDetailsCtrl'
            },
            'estimate@projecttypedetails': {
              templateUrl: 'templates/projecttypes/tabs/estimate.html',
              controller: 'ProjectTypesTabsEstimateCtrl'
            },
            'contracts@projecttypedetails': {
              templateUrl: 'templates/projecttypes/tabs/contracts.html',
              controller: 'ProjectTypesTabsContractsCtrl'
            },
            'sidebar@projecttypedetails': sidebarObject,
          }
        })
        .state('projecttypeslist', {
          url: '/project-types',
          data: { pageTitle: 'Gestione tipologie' },
          views: {
            '': {
              templateUrl: 'templates/projecttypes/list.html',
              controller: 'EstimateBlocksCtrl'
            },
            'sidebar@projecttypeslist': sidebarObject,
          }
        })
        .state('utenti_orelavorate', {
          url: '/utentiorelavorate/:year/:weekNumber',
          params: {
            year: { squash: true, value: null },
            weekNumber: { squash: true, value: null },
          },
          data: { allowAccessToUserRoles: [1, 2], pageTitle: 'Ore lavorate utenti' },
          views: {
            '': {
              templateUrl: 'templates/utentiorelavorate/utentiorelavorate.html',
              controller: 'UtentiOreLavorate'
            },
            'sidebar@utenti_orelavorate': sidebarObject
          }
        })
        .state('vatseparation', {
          url: '/vatseparation',
          data: { allowAccessToUserRoles: [1], pageTitle: 'Scorporamento IVA' },
          views: {
            '': {
              templateUrl: 'templates/vatseparation/vatseparation.html',
              controller: 'VatSeparationCtrl'
            },
            'sidebar@vatseparation': sidebarObject
          }
        })

        .state('gestionearee', {
          url: '/aree',
          data: { allowAccessToUserRoles: [1, 2], pageTitle: 'Gestione aree' },
          views: {
            '': {
              templateUrl: 'templates/gestionearee/aree.html',
              controller: 'GestioneAreeCtrl'
            },
            'sidebar@gestionearee': sidebarObject
          }
        })

        .state('previsioni', {
          url: '/previsioni',
          data: {
            allowAccessToUserRoles: [1, 2],
            pageTitle: 'Previsioni',
            fixedFilters: {
              fatturato: 'non-fatturato',
            }
          },
          views: {
            '': {
              templateUrl: 'templates/progetti/progetti.html',
              controller: 'ProgettiCtrl'
            },
            'sidebar@previsioni': sidebarObject
          }
        })
        .state('elencoddt', {
          url: '/elencoddt',
          data: { allowAccessToUserRoles: [1], pageTitle: 'Elenco DDT' },
          views: {
            '': {
              templateUrl: 'templates/waybills/list.html',
              controller: 'WaybillsListCtrl'
            },
            'sidebar@elencoddt': sidebarObject
          }
        })
        .state('createwaybill', {
          url: '/waybills/new',
          data: { allowAccessToUserRoles: [1], pageTitle: 'Nuovo DDT' },
          views: {
            '': {
              templateUrl: 'templates/waybills/create.html',
              controller: 'CreateWaybillCtrl'
            },
            'sidebar@createwaybill': sidebarObject
          }
        })
        .state('updatewaybill', {
          url: '/waybills/:waybillId/edit',
          data: { allowAccessToUserRoles: [1], pageTitle: 'Modifica DDT' },
          views: {
            '': {
              templateUrl: 'templates/waybills/create.html',
              controller: 'UpdateWaybillCtrl'
            },
            'sidebar@updatewaybill': sidebarObject
          }
        })
        .state('elencofatture', {
          url: '/elencofatture',
          data: { allowAccessToUserRoles: [1], pageTitle: 'Fatture' },
          views: {
            '': {
              templateUrl: 'templates/elencofatture/elencofatture.html',
              controller: 'ElencoFattureCtrl'
            },
            'sidebar@elencofatture': sidebarObject
          }
        })
        .state('digitalinvoicedownloader', {
          url: '/digitalinvoicedownloader',
          data: { allowAccessToUserRoles: [1], pageTitle: 'Scarica fatture' },
          views: {
            '': {
              templateUrl: 'templates/digitalinvoicedownloader/index.html',
              controller: 'DigitalInvoiceDownloaderCtrl'
            },
            'sidebar@digitalinvoicedownloader': sidebarObject
          }
        })
        .state('fatturatore', {
          url: '/fatturatore',
          data: { allowAccessToUserRoles: [1], pageTitle: 'Nuova fattura di vendita' },
          views: {
            '': {
              templateUrl: 'templates/fatturatore/fatturatore.html',
              controller: 'FatturatoreCtrl'
            },
            'sidebar@fatturatore': sidebarObject
          }
        })
        .state('progetti', {
          url: '/progetti',
          data: { hasQuickSidebar: false, pageTitle: 'Progetti' },
          views: {
            '': {
              templateUrl: 'templates/progetti/progetti.html',
              controller: 'ProgettiCtrl'
            },
            'sidebar@progetti': sidebarObject
          }
        })
        .state('projectsinprogress', {
          url: '/projects/inprogress',
          data: {
            hasQuickSidebar: false, pageTitle: 'Progetti in corso', fixedFilters: {
              fatturato: 'non-fatturato',
              consegnato: 'non-consegnato',
              statusType: 'OPEN',
            }
          },
          views: {
            '': {
              templateUrl: 'templates/progetti/progetti.html',
              controller: 'ProgettiCtrl'
            },
            'sidebar@projectsinprogress': sidebarObject
          }
        })
        .state('fatturatoreedit', {
          url: '/fatturatore/:invoiceId',
          data: { allowAccessToUserRoles: [1], pageTitle: 'Modifica fattura di vendita' },
          views: {
            '': {
              templateUrl: 'templates/fatturatore/fatturatore.html',
              controller: 'FatturatoreCtrl'
            },
            'sidebar@fatturatoreedit': sidebarObject
          }
        })
        .state('elenconotedicredito', {
          url: '/notedicredito',
          data: { allowAccessToUserRoles: [1], pageTitle: 'Note di credito' },
          views: {
            '': {
              templateUrl: 'templates/notedicredito/list.html',
              controller: 'ElencoNoteDiVariazioneCtrl'
            },
            'sidebar@elenconotedicredito': sidebarObject
          }
        })
        .state('notedicreditonew', {
          url: '/notedicredito/new',
          data: { allowAccessToUserRoles: [1], pageTitle: 'Nuova nota di credito' },
          views: {
            '': {
              templateUrl: 'templates/notedicredito/new.html',
              controller: 'NoteDiVariazioneNewCtrl'
            },
            'sidebar@notedicreditonew': sidebarObject
          }
        })
        .state('notedicreditoedit', {
          url: '/notedicredito/edit/:id',
          data: { allowAccessToUserRoles: [1], pageTitle: 'Modifica nota di credito' },
          views: {
            '': {
              templateUrl: 'templates/notedicredito/new.html',
              controller: 'NoteDiVariazioneNewCtrl'
            },
            'sidebar@notedicreditoedit': sidebarObject
          }
        })

        // ENTRATE E USCITE

        .state('scadenzario', {
          url: '/scadenzario',
          data: { allowAccessToUserRoles: [1], pageTitle: 'Scadenzario' },
          views: {
            '': {
              templateUrl: 'templates/scadenzario/scadenzario.html',
              controller: 'ScadenzarioCtrl'
            },
            'sidebar@scadenzario': sidebarObject
          }
        })
        .state('elencofatturato', {
          url: '/elencofatturato',
          data: { hasQuickSidebar: false, allowAccessToUserRoles: [1], pageTitle: 'Elenco Fatturato' },
          views: {
            '': {
              templateUrl: 'templates/elencofatturato/elencofatturato.html',
              controller: 'ElencoFatturatoCtrl'
            },
            'sidebar@elencofatturato': sidebarObject,
          }
        })
        .state('elencofatturatoperanno', {
          url: '/elencofatturatoperanno',
          data: { allowAccessToUserRoles: [1], pageTitle: 'Elenco fatturato per anno' },
          views: {
            '': {
              templateUrl: 'templates/elencofatturatoperanno/elencofatturatoperanno.html',
              controller: 'ElencoFatturatoPerAnnoCtrl'
            },
            'sidebar@elencofatturatoperanno': sidebarObject
          }
        })
        .state('costifornitori', {
          url: '/costifornitori',
          data: { hasQuickSidebar: true, allowAccessToUserRoles: [1], pageTitle: 'Costi fornitori' },
          views: {
            '': {
              templateUrl: 'templates/costifornitori/costifornitori.html',
              controller: 'CostiFornitoriCtrl'
            },
            'sidebar@costifornitori': sidebarObject
          }
        })
        .state('timesheet', {
          url: '/timesheet',
          data: { hasQuickSidebar: true, allowAccessToUserRoles: [1, 2], pageTitle: 'Report ore' },
          views: {
            '': {
              templateUrl: 'templates/timesheet/timesheet.html',
              controller: 'TimesheetCtrl'
            },
            'sidebar@timesheet': sidebarObject
          }
        })
        .state('elencofattureacquisto', {
          url: '/elencofattureacquisto',
          data: { hasQuickSidebar: false, allowAccessToUserRoles: [1], pageTitle: 'Elenco fatture di acquisto' },
          views: {
            '': {
              templateUrl: 'templates/purchaseInvoices/elencofattureacquisto.html',
              controller: 'ElencoFattureAcquistoCtrl'
            },
            'sidebar@elencofattureacquisto': sidebarObject
          }
        })
        .state('fatturaacquisto_new', {
          url: '/elencofattureacquisto/new',
          data: { allowAccessToUserRoles: [1], pageTitle: 'Nuova fattura di acquisto' },
          views: {
            '': {
              templateUrl: 'templates/purchaseInvoices/fatturaacquisto_edit.html',
              controller: 'FatturaAcquistoNewCtrl'
            },
            'sidebar@fatturaacquisto_new': sidebarObject
          }
        })
        .state('fatturaacquisto_edit', {
          url: '/elencofattureacquisto/:id/edit',
          data: { pageTitle: 'Modifica fattura di acquisto' },
          views: {
            '': {
              templateUrl: 'templates/purchaseInvoices/fatturaacquisto_edit.html',
              controller: 'FatturaAcquistoEditCtrl'
            },
            'sidebar@fatturaacquisto_edit': sidebarObject
          }
        })
        .state('analisicomposizionefatturato', {
          url: '/stats/analisi-composizione-fatturato',
          data: { hasQuickSidebar: false, allowAccessToUserRoles: [1], pageTitle: 'Analisi composizione fatturato' },
          views: {
            '': {
              templateUrl: 'templates/analisicomposizionefatturato/index.html',
              controller: 'AnalisiComposizioneFatturatoCtrl',
              controllerAs: 'vm'
            },
            'sidebar@analisicomposizionefatturato': sidebarObject
          }
        })
        .state('lavoriincorso', {
          url: '/lavoriincorso',
          data: { hasQuickSidebar: false, allowAccessToUserRoles: [1, 2, 3], pageTitle: 'Lavori in corso' },
          views: {
            '': {
              templateUrl: 'templates/lavoriincorso/lavoriincorso.html',
              controller: 'LavoriInCorsoCtrl',
              controllerAs: 'vm'
            },
            'sidebar@lavoriincorso': sidebarObject
          }
        })
        ;

      $urlRouterProvider.otherwise('/login');

    }])

  .run(['$rootScope', '$state', '$stateParams', 'User', '$transitions',
    function ($rootScope, $state, $stateParams, User, $transitions) {
      toastr.options.closeButton = true;
      toastr.options.newestOnTop = true;
      toastr.options.progressBar = true;
      toastr.options.positionClass = "toast-bottom-right";
      toastr.options.onclick = null;

      // Redirect to login if the user is not logged in.
      // Redirect to dashboard if the user is already logged in.
      $transitions.onStart(
        {
          to: (state) => !!state.data.allowAccessToUserRoles
        },
        function(trans) {
          const user = User.getUser();
          const allowedUserRoles = trans.to().data.allowAccessToUserRoles;
          if (user != null && !allowedUserRoles.includes(user.role.id)) {
            if (!!trans.from().name) {
              console.error('authorization error: aborted transaction');
              return trans.abort();
            } else {
              console.error('authorization error: redirected to dashboard');
              return trans.router.stateService.target('dashboard');
            }
          }
        }
      );

      $rootScope.$state = $state;
      $rootScope.$stateParams = $stateParams;
    }]);