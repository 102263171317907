import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .controller('ModalAddProjectToInvoiceCtrl', ModalAddProjectToInvoiceCtrl);

  ModalAddProjectToInvoiceCtrl.$inject = ['$scope', 'availableProjects', 'title', '$uibModalInstance'];

  function ModalAddProjectToInvoiceCtrl($scope, availableProjects, title, $uibModalInstance) {
    $scope.title = title;

    function groupProjects(projects) {
      function hashFn(project) {
        if (project.paymentInfo == null)
          return "-";
        return ""+project.paymentInfo.paymentMethod.id + "-" + project.paymentInfo.paymentTerm.id;
      }

      const index = [];
      projects.forEach(function(x) {
        const hash = hashFn(x);
        if (Object.keys(index).includes(hash)) {
          index[hash].projects.push(x);
        } else {
          index[hash] = {
            paymentInfo: x.paymentInfo,
            projects: [x],
          };
        }
      });

      return Object.values(index);
    }

    $scope.availableProjects = groupProjects(availableProjects);

    $scope.$on('modal.closing', function (event, reason, closed) {
      $scope.clear();
    });

    $scope.getSelected = function() {
      const selectedPerGroup = $scope.availableProjects.map(function(group) {
        return group.projects.filter(function(x) { return !!x.isSelected; });
      });
      return [].concat.apply([], selectedPerGroup);
    };

    $scope.clear = function() {
      $scope.availableProjects.forEach(function(group) {
        group.projects.forEach(function(proj) {
          proj.isSelected = false;
        });
      });
      $scope.availableProjects = [];
    };

    $scope.addAll = function () {
      const selectedProjects = $scope.getSelected();
      $scope.clear();
      $uibModalInstance.close(selectedProjects);
    };

    $scope.toggleGroup = function(group) {
      if (group.allSelected) {
        $scope.selectAll(group);
      } else {
        $scope.deselectAll(group);
      }
    }

    $scope.checkAllSelected = function(group) {
      if ($scope.areAllSelected(group)) {
        group.allSelected = true;
      } else {
        group.allSelected = false;
      }
    }

    $scope.selectAll = function(group) {
      group.projects.forEach(function(proj) {
        if (proj.billablePrice > 0 && proj.billableQuantity > 0) {
          proj.isSelected = true;
        }
      });
    };
    $scope.deselectAll = function(group) {
      group.projects.forEach(function(proj) {
        if (proj.billablePrice > 0 && proj.billableQuantity > 0) {
          proj.isSelected = false;
        }
      });
    };
    $scope.areAllSelected = function(group) {
      const aNonSelectedOne = group.projects.find(function(proj) {
        return (proj.billablePrice > 0
            && proj.billableQuantity > 0
            && !proj.isSelected);
      });
      return !aNonSelectedOne;
    };
    $scope.anyCanBeSelected = function(group) {
      const selectableOne = group.projects.find(function(proj) {
        return (proj.billablePrice > 0
            && proj.billableQuantity > 0);
      });
      return !!selectableOne;
    };
  }
