import * as angular from 'angular';
import * as moment from 'moment';
import toastr from 'toastr';
import { Convert } from '../../utils';

angular
  .module('screeningcanvas')
  .controller('ProgettoEditCtrl', ProgettoEditCtrl);

ProgettoEditCtrl.$inject = ['$scope', '$state', '$stateParams', 'newApiClient'];

function ProgettoEditCtrl($scope, $state, $stateParams, newApiClient) {
  $scope.title = 'Modifica progetto';
  $scope.hasReferentUser = () =>
    !!$scope.data &&
    !!$scope.data.memberships &&
    $scope.data.memberships.some(m => m.isReferent);

  const projectId = Convert.toInt($stateParams.id);

  $scope.getProgetto = function () {
    $scope.now = moment().format('YYYY-MM-DD');
    $scope.loading = true;
    newApiClient.call(`
        query($id: Int!) {
          projects {
            project(id: $id) {
              id
              type { id }
              title
              customer { id }
              pricing {
                fixedPrice
                unitPrice
              }
              quantity
              hasQuantityAsBillableHours
              unit
              deadline
              purchaseOrderCode
              purchaseOrderCIG
              contractCode
              customerProductCode
              paymentInfo {
                paymentMethod { id }
                paymentTerm { id }
              }
              memberships {
                id
                isReferent
                user { id }
              }
              tasks {
                assignedUser { id }
              }
              note
              isResale
              includeInTimesheet
            }
          }
        }
      `, { id: projectId })
      .then(function (data) {
        $scope.loading = false;

        const project = data.projects.project;

        $scope.frozenUsers = [];
        var frozenSet = new Set();
        project.tasks.forEach(t => {
          if (!!t.assignedUser) {
            frozenSet.add(t.assignedUser.id);
          }
        });
        $scope.frozenUsers = Array.from(frozenSet);

        $scope.data = {
          projectTypeId: project.type?.id?.toString(),
          title: project.title,
          customerId: project.customer?.id?.toString(),
          fixedPrice: project.pricing.fixedPrice,
          unitPrice: project.pricing.unitPrice,
          isUnitPrice: (project.pricing.unitPrice != null),
          quantity: project.quantity,
          hasQuantityAsBillableHours: project.hasQuantityAsBillableHours,
          unit: project.unit,
          deadline: project.deadline,
          purchaseOrderCode: project.purchaseOrderCode,
          purchaseOrderCIG: project.purchaseOrderCIG,
          contractCode: project.contractCode,
          customerProductCode: project.customerProductCode,
          paymentMethodId: project.paymentInfo?.paymentMethod?.id?.toString(),
          paymentTermId: project.paymentInfo?.paymentTerm?.id?.toString(),
          memberships: project.memberships.map(m => ({
            isReferent: m.isReferent,
            userId: m.user.id,
          })),
          note: project.note,
          isResale: project.isResale,
          includeInTimesheet: project.includeInTimesheet,
        };
      }, function (errors) {
        toastr.error("Impossibile caricare il progetto");
      });
  }
  $scope.getProgetto();

  $scope.save = function () {
    const input = {
      projectId: projectId,
      deadline: Convert.toNonEmptyString($scope.data.deadline),
      customerId: Convert.toInt($scope.data.customerId),
      projectTypeId: Convert.toInt($scope.data.projectTypeId),
      title: $scope.data.title,
      purchaseOrderCode: Convert.toNonEmptyString($scope.data.purchaseOrderCode),
      purchaseOrderCIG: Convert.toNonEmptyString($scope.data.purchaseOrderCIG),
      contractCode: Convert.toNonEmptyString($scope.data.contractCode),
      customerProductCode: Convert.toNonEmptyString($scope.data.customerProductCode),
      pricing: {
        fixedPrice: (!$scope.data.isUnitPrice) ? Convert.toFloat($scope.data.fixedPrice) : null,
        unitPrice: (!!$scope.data.isUnitPrice) ? Convert.toFloat($scope.data.unitPrice) : null,
      },
      unit: Convert.toNonEmptyString($scope.data.unit),
      quantity: (!$scope.data.hasQuantityAsBillableHours)
        ? Convert.toFloat($scope.data.quantity)
        : null,
      quantityAsBillableHours: $scope.data.hasQuantityAsBillableHours,
      isResale: $scope.data.isResale,
      includeInTimesheet: $scope.data.includeInTimesheet,
      paymentInfo: (!!$scope.data.paymentTermId && !!$scope.data.paymentMethodId)
        ? {
          paymentTermId: Convert.toInt($scope.data.paymentTermId),
          paymentMethodId: Convert.toInt($scope.data.paymentMethodId),
        }
        : null,
      note: Convert.toNonEmptyString($scope.data.note),
      memberships: $scope.data.memberships,
    };
    newApiClient.call(`
        mutation($input: UpdateProjectInput!) {
          projects {
            updateProject(input: $input) {
              id
            }
          }
        }
      `, { input: input })
      .then(function (data) {
        toastr.success("Progetto modificato");
        $state.go('progettodettaglio', { id: data.projects.updateProject.id });
      }, function (errors) {
        toastr.error(errors[0].extensions.userMessage, "Impossibile modificare il progetto", { timeOut: 0, extendedTimeOut: 0 });
      });
  };

}
