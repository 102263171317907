import {
  forwardRef,
  Component,
  OnInit,
  Inject,
  Input,
  OnChanges,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Apollo, gql } from 'apollo-angular';
import { Convert } from '../../../utils';
import { showApolloError } from '../../alert';

@Component({
  selector: 'project-selector',
  templateUrl: 'new/shared/components/project-selector.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR, 
      useExisting: forwardRef(() => ProjectSelector), 
      multi: true
    }
  ]
})
export class ProjectSelector implements OnInit, OnChanges {
  @Input() private optional: string;
  @Input() private companyId: number;
  @Input() private status: string;

  private disabled: boolean = false;
  private selected: number = null;
  private items = [];

  private _onTouched: any = () => {}
  private _onChange: any = () => {}

  constructor(@Inject(Apollo) private apollo: Apollo) {}

  private onChange() {
    this._onChange(this.selected);
    this._onTouched();
  }

  writeValue(value: number) {
    this.selected = value;
    this.onChange();
  }

  registerOnChange(fn: () => any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  async reload() {
    try {
      const { data } = await this.apollo.query<any>({
        query: gql`
          query($filter: ProjectFilter, $order: ProjectOrder!, $pagination: Pagination!) {
            projects {
              allProjects {
                list(filter: $filter, order: $order, pagination: $pagination) {
                  id
                  folderCode
                  title
                }
              }
            }
          }
        `,
        variables: {
          filter: {
            includeIds: Convert.toSingletonIntList(this.selected),
            statusTypes: Convert.toSingletonList(this.status),
            customerIds: Convert.toSingletonIntList(this.companyId),
          },
          order: 'CREATED_AT_DESC',
          pagination: { skip: 0, take: 1000 }
        }
      }).toPromise();
      this.items = data.projects
        .allProjects.list
        .map(x => ({
          label: `${x.title} (${x.folderCode})`,
          value: x.id,
        }));
    } catch (error) {
      showApolloError(error);
    }
  }

  ngOnInit() { this.reload(); }
  ngOnChanges() { this.reload(); }
}