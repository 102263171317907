import * as angular from 'angular';
import * as moment from 'moment';
import toastr from 'toastr';

angular
  .module('screeningcanvas')
  .controller('WaybillsListCtrl', WaybillsListController);

WaybillsListController.$inject = ['$scope', 'newApiClient'];

function WaybillsListController($scope, newApiClient) {
  $scope.loading = false;
  $scope.list = [];
  $scope.cerca = cerca;
  $scope.meseIsDisabled = false;

  var annoCorrente = new Date().getFullYear();
  $scope.anni = [];
  for (var i = 2015; i <= annoCorrente; i++) {
    $scope.anni.push(i);
  };
  $scope.filters = {
    anno: annoCorrente,
    mese: "-1",
    customer_id: "",
    type: "invoice",
  };

  $scope.paginator = {
    page: 0,
    perPage: 50,
    totalPages: 0,
  };

  function cerca(page = 0, loading = false) {
    $scope.loading = loading;
    page = (page == null) ? $scope.paginator.page : page;

    var fromDate = moment({
      year: (!!$scope.filters.anno) ? $scope.filters.anno : "2015",
      month: ($scope.filters.mese != "-1") ? (parseInt($scope.filters.mese) - 1) : 0,
      day: 1
    });

    var toDateYear = (!!$scope.filters.anno) ? $scope.filters.anno : moment().year();
    var toDateMonth = ($scope.filters.mese != "-1") ? (parseInt($scope.filters.mese) - 1) : 11;
    var toDateDay = moment().clone().year(toDateYear).month(toDateMonth).endOf('month').date();
    var toDate = moment({
      year: toDateYear,
      month: toDateMonth,
      day: toDateDay,
    });

    newApiClient.call(`
          query($filter: WaybillFilter!, $order: WaybillOrder!, $pagination: Pagination!) {
            waybills {
              allWaybills {
                list(filter: $filter, order: $order, pagination: $pagination) {
                  id
                  yearlyCode
                  status
                  date
                  customer {
                    id
                    name
                  }
                  pdfFile
                  items {
                    id
                    quantity
                    project {
                      id
                      folderCode
                      title
                      price
                      billedPrice
                      billablePrice
                    }
                  }
                }
                count(filter: $filter)
              }
            }
          }
        `, {
      filter: {
        fromDate: fromDate.format('YYYY-MM-DD'),
        toDate: toDate.format('YYYY-MM-DD'),
        customerIds: ($scope.filters.customer_id != "") ? [parseInt($scope.filters.customer_id)] : [],
      },
      pagination: {
        take: $scope.paginator.perPage,
        skip: $scope.paginator.perPage * page,
      },
      order: 'DATE_DESC',
    }).then(function (data) {
      $scope.list = data.waybills.allWaybills.list;
      $scope.paginator.totalPages = Math.ceil(data.waybills.allWaybills.count / $scope.paginator.perPage);
      $scope.paginator.page = page;
      $scope.loading = false;
    }, function () {
      $scope.loading = false;
      toastr.error("Impossibile caricare i DDT");
    });
  }

  $scope.displayStatus = (status) => {
    switch (status) {
      case 'DRAFT':
        return { color: 'orange', text: 'Bozza' };
      case 'SENT':
        return { color: 'green', text: 'Inviata' };
    }
  };

  $scope.cerca(0, true);

  $scope.updateWaybillStatus = (id, status) => {
    newApiClient.call(`
          mutation($id: Int!, $status: WaybillStatus!) {
            waybills {
              updateWaybillStatus(id: $id, status: $status) {
                id
              }
            }
          }
        `, {
      id: id,
      status: status
    }).then(function (data) {
      toastr.success("Stato del DDT aggiornato");
      $scope.cerca($scope.paginator.page, false);
    }, function () {
      toastr.error("Impossibile aggiornare lo stato del DDT");
    });
  };
}
