import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .directive('taxCode', function () {
    return {
      require: 'ngModel',
      link: function (scope, elm, attrs, ctrl) {
        ctrl.$validators.taxCode = function (modelValue, viewValue) {
          // consider empty models to be valid
          if (ctrl.$isEmpty(modelValue)) {
            return true;
          }

          const regexes = [
            "^IT[0-9]{11,11}$",
            "^IT[A-Z]{6}[0-9]{2}[ABCDEHLMPRST]{1}[0-9]{2}([A-Z]{1}[0-9]{3})[A-Z]{1}$",
          ];
          for (const regex of regexes) {
            if (modelValue.match(regex))
              return true;
          }

          return false;
        };
      }
    };
  });
