import * as angular from 'angular';
import { Convert } from '../../utils';

angular
  .module('screeningcanvas')
  .controller('ModalEditTransactionCtrl', ModalEditTransactionCtrl);

  ModalEditTransactionCtrl.$inject = ['$scope', 'data', '$uibModalInstance'];

  function ModalEditTransactionCtrl($scope, data, $uibModalInstance) {
    $scope.data = {
      paymentDate: data.paymentDate,
      tagId: Convert.toNonEmptyString(data.tagId),
      paymentMethodId: Convert.toNonEmptyString(data.paymentMethodId),
      companyId: Convert.toNonEmptyString(data.companyId),
      documentCode: data.documentCode,
      documentDate: data.documentDate,
      amount: data.amount,
      note: data.note,
      paidAt: data.paidAt,
      isDefaulted: data.isDefaulted,
    };

    $scope.$on('modal.closing', function (event, reason, closed) {
      $scope.clear();
    });

    $scope.clear = function() {
      $scope.data = null;
    };

    $scope.save = function () {
      const data = $scope.data;
      $scope.clear();
      $uibModalInstance.close({
        paymentDate: data.paymentDate,
        tagId: Convert.toInt(data.tagId),
        paymentMethodId: Convert.toInt(data.paymentMethodId),
        companyId: Convert.toInt(data.companyId),
        documentCode: Convert.toNonEmptyString(data.documentCode),
        documentDate: data.documentDate,
        amount: data.amount,
        note: Convert.toNonEmptyString(data.note),
        paidAt: data.paidAt,
        isDefaulted: data.isDefaulted,
      });
    };
  }