import * as angular from 'angular';
import * as moment from 'moment';
import { Convert } from '../../utils';

  angular
    .module('screeningcanvas')
    .controller('AnalisiComposizioneFatturatoCtrl', AnalisiComposizioneFatturatoCtrl);

    AnalisiComposizioneFatturatoCtrl.$inject = ['$scope', '$location', 'newApiClient'];

    function AnalisiComposizioneFatturatoCtrl($scope, $location, newApiClient) {

      const urlFilters = $location.search();
      $scope.ordinamento = '-netGain';
      $scope.filters = {
        fromDate: urlFilters.fromDate || moment().startOf('year').format('YYYY-MM-DD'),
        toDate: urlFilters.toDate || moment().endOf('year').format('YYYY-MM-DD'),
        isResale: urlFilters.isResale || null,
        customerId: Convert.toInt(urlFilters.customerId),
      };

      $scope.drawPieChart = (reports) => {
        var copy = angular.copy(reports);
        copy.sort((a, b) => b.netGainPercentageOverall - a.netGainPercentageOverall);
        const top = copy.slice(0, 10);
        const rest = copy.slice(10);
        $scope.pieChart = {
          chartType: 'PieChart',
          dataTable: google.visualization.arrayToDataTable([
            ['Tipologia', 'Percentage'],
            ...top.map(x => [
                x.projectType.name,
                x.netGainPercentageOverall,
              ]),
            rest.reduce((acc, x) => [ acc[0], acc[1] + x.netGainPercentageOverall ], [ 'Altri', 0 ]),
          ]),
          options: {
            height: 500,
          },
        };
      };

      $scope.fetchTableData = () => {
        $scope.loading = true;
        newApiClient.call(`
          query($filter: StatsByProjectTypeFilter!) {
            stats {
              statsByProjectType(filter: $filter) {
                projectType {
                  id
                  name
                }
                netTotal
                internalNetCost
                externalNetCost
                netCost
                netGain
                workedTime
              }
            }
          }
        `, {
          filter: {
            fromDate: $scope.filters.fromDate,
            toDate: $scope.filters.toDate,
            isResale: ($scope.filters.isResale === null || $scope.filters.isResale === "")
              ? null
              : ($scope.filters.isResale === "1"),
            customerIds: Convert.toSingletonIntList($scope.filters.customerId),
          },
        }).then(function(response) {
          $scope.loading = false;
          const reports = response.stats.statsByProjectType.map(r => ({
            ...r,
            workedHours: Convert.fromDurationToHours(r.workedTime),
            netTotalMinusExternalCosts: r.netTotal - r.externalNetCost,
          }));
          const netTotal = reports.reduce((acc, x) => acc + x.netTotal, 0);
          const internalNetCostTotal = reports.reduce((acc, x) => acc + x.internalNetCost, 0);
          const externalNetCostTotal = reports.reduce((acc, x) => acc + x.externalNetCost, 0);
          const netCostTotal = reports.reduce((acc, x) => acc + x.netCost, 0);
          const netGainTotal = reports.reduce((acc, x) => acc + x.netGain, 0);
          const netGainTotalOverall = reports.filter(x => x.netGain > 0).reduce((acc, x) => acc + x.netGain, 0);
          const netTotalMinusExternalCosts = netTotal - externalNetCostTotal;
          $scope.reports = reports.map(x => ({
            ...x,
            netGainPercentage: (x.netTotal != 0)
              ? (x.netGain * 100) / x.netTotal
              : 0,
            netGainPercentageOverall: (x.netGain > 0)
              ? (x.netGain * 100) / netGainTotalOverall
              : 0,
          }));
          $scope.somme = [
            {
              label: 'Guadagni',
              total: netGainTotal,
            },
            {
              label: 'F. Netto',
              total: netTotalMinusExternalCosts,
            },
            {
              label: 'Costi interni',
              total: internalNetCostTotal,
            },
            {
              label: 'Costi esterni',
              total: externalNetCostTotal,
            },
            {
              label: 'Costi',
              total: netCostTotal,
            },
            {
              label: 'Imponibile',
              total: netTotal,
            }
          ];

          $scope.drawPieChart($scope.reports);
        }, function(error) {
          $scope.loading = false;
          console.error(error);
        });
      };

      $scope.refresh = () => {
        if (!$scope.filters.fromDate || !$scope.filters.toDate)
          return;
        $scope.fetchTableData();
      };

      $scope.refresh();
      $scope.$watch('filters.fromDate', (newValue, oldValue) => {
        if (newValue !== oldValue)
          $scope.refresh();
      });
      $scope.$watch('filters.toDate', (newValue, oldValue) => {
        if (newValue !== oldValue)
          $scope.refresh();
      });
      $scope.$watch('filters.isResale', (newValue, oldValue) => {
        if (newValue !== oldValue)
          $scope.refresh();
      });
      $scope.$watch('filters.customerId', (newValue, oldValue) => {
        if (newValue !== oldValue)
          $scope.refresh();
      });
    }
