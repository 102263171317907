import * as angular from 'angular';
import toastr from 'toastr';

angular
  .module('screeningcanvas')
  .controller('ProjectTypeDetailsCtrl', ProjectTypeDetailsCtrl);

  ProjectTypeDetailsCtrl.$inject = ['$scope', '$stateParams', 'newApiClient'];

  function ProjectTypeDetailsCtrl($scope, $stateParams, newApiClient) {
    $scope.load = (initialLoading) => {
      $scope.loading = initialLoading;
      newApiClient.call(`
        query($id: Int!) {
          projectTypes {
            projectType(id: $id) {
              id
              name
            }
          }
        }`,
        { id: parseInt($stateParams.id) },
      ).then(function(data) {
        $scope.loading = false;
        $scope.projectType = data.projectTypes.projectType;
      }, function(errors) {
        $scope.loading = false;
        toastr.error(errors[0].message, "Impossibile caricare la tipologia di progetto");
      });
    };

    $scope.load(true);
  }
