import * as angular from 'angular';

angular.module('screeningcanvas')
  .service('clientStorage', function () {
    this.put = function (key, obj) {
      localStorage.setItem(key, JSON.stringify(obj));
    };

    this.get = function (key) {
      var jsonObj = localStorage.getItem(key);
      return JSON.parse(jsonObj);
    }
  });
