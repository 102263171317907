import * as angular from 'angular';
import * as moment from 'moment';
import toastr from 'toastr';
import { Convert } from '../../utils';

angular
  .module('screeningcanvas')
  .controller('UtentiOreLavorate', UtentiOreLavorate);

UtentiOreLavorate.$inject = ['$scope', '$state', '$stateParams', 'clientStorage', 'newApiClient'];

function UtentiOreLavorate($scope, $state, $stateParams, clientStorage, newApiClient) {
  $scope.examplesData = [];
  $scope.loading = false;
  $scope.year = $stateParams.year || moment().weekYear();
  $scope.weekNumber = $stateParams.weekNumber || moment().isoWeek();

  $scope.fromDate = () =>
    moment().weekYear($scope.year).isoWeek($scope.weekNumber).isoWeekday(1);
  $scope.toDate = () =>
    moment().weekYear($scope.year).isoWeek($scope.weekNumber).isoWeekday(5);

  $scope.onPreviousMonth = () => {
    const referenceDay = $scope.fromDate().clone().subtract(1, 'months');
    $state.go('utenti_orelavorate', { year: referenceDay.weekYear(), weekNumber: referenceDay.isoWeek() });
  };
  $scope.onNextMonth = () => {
    const referenceDay = $scope.fromDate().clone().add(1, 'months');
    $state.go('utenti_orelavorate', { year: referenceDay.weekYear(), weekNumber: referenceDay.isoWeek() });
  };

  $scope.onPreviousWeek = () => {
    const referenceDay = $scope.fromDate().clone().subtract(1, 'weeks');
    $state.go('utenti_orelavorate', { year: referenceDay.weekYear(), weekNumber: referenceDay.isoWeek() });
  };
  $scope.onNextWeek = () => {
    const referenceDay = $scope.fromDate().clone().add(7, 'days');
    $state.go('utenti_orelavorate', { year: referenceDay.weekYear(), weekNumber: referenceDay.isoWeek() });
  };

  $scope.formatYAxis = function (d) {
    return d;
  };

  $scope.formatXAxis = function (d) {
    return moment(d, 'YYYY-MM-DD').format('ddd D').toUpperCase();
  };

  $scope.$on('stateChange.legend.directive', function (angularEvent, event) {
    const disabled = event.disabled;
    const userIds = $scope.weekData.map(x => x.userId);
    const disabledState = userIds.reduce((acc, id, i) => {
      acc[id] = disabled[i];
      return acc;
    }, {});
    clientStorage.put('utentiorelavorate_disabled', disabledState);
  });

  $scope.chartOptions = {
    chart: {
      type: "multiBarChart",
      width: 1100,
      height: 400,
      showXAxis: true,
      showYAxis: true,
      yAxis: { axisLabel: 'Ore lavorate', tickFormat: $scope.formatYAxis, ticks: 10 },
      xAxis: { tickFormat: $scope.formatXAxis },
      forceY: [0, 10],
      showLegend: true,
      showControls: false,
      noData: "Non ci sono dati a disposizione",
      stacked: false,
      tooltip: {
        enabled: false,
      },
      dispatch: {
        stateChange: (event) => {
          const disabled = event.disabled;
          const userIds = $scope.chartData.map(x => x.userId);
          const disabledState = userIds.reduce((acc, id, i) => {
            acc[id] = disabled[i];
            return acc;
          }, {});
          clientStorage.put('utentiorelavorate_disabled', disabledState);
        },
      },
    },
  };

  function enumerateDaysBetweenDates(startDate, endDate) {
    var dates = [];

    const start = moment(startDate, 'YYYY-MM-DD').startOf('day');
    const end = moment(endDate, 'YYYY-MM-DD').startOf('day');

    for (var curr = start; curr <= end; curr = curr.clone().add(1, 'days')) {
      dates.push(curr.format('YYYY-MM-DD'));
    }

    return dates;
  };

  $scope.drawChart = function () {
    $scope.loading = true;
    const fromDate = $scope.fromDate().format('YYYY-MM-DD');
    const toDate = $scope.toDate().format('YYYY-MM-DD');
    newApiClient.call(`
        query($filter: StatsByUserFilter!) {
          users {
            allUsers {
              list(filter: { isActive: true }) {
                id
                fullName
              }
            }
          }
          stats {
            statsByUser(filter: $filter) {
              date
              user {
                id
                fullName
              }
              workedTime
            }
          }
        }
      `, {
      filter: {
        fromDate: fromDate,
        toDate: toDate,
      }
    }).then(function (data) {
      $scope.loading = false;
      const users = data.users.allUsers.list;
      const dailyReports = data.stats.statsByUser;
      const dates = enumerateDaysBetweenDates(fromDate, toDate);

      const disabledState = clientStorage.get('utentiorelavorate_disabled') ?? {};

      var weekData = users.map(user => {
        const values = dates.map(date => {
          const userReport = dailyReports.find(x => x.user.id == user.id && date == x.date);
          if (!userReport) {
            return { x: date, y: 0 };
          }
          return {
            x: userReport.date,
            y: Convert.fromDurationToHours(userReport.workedTime),
          };
        });
        return {
          "key": user.fullName,
          "values": values,
          "userId": user.id,
          "disabled": disabledState[user.id] ?? false,
        };
      });

      $scope.chartData = weekData;
    }, function () {
      $scope.loading = false;
      toastr.error("Impossibile caricare i dati");
    });
  };
  $scope.drawChart();
}