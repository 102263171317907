import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .filter('formatMaxSizeString', function () {
    return function (input, length) {
      const str = "" + input;
      return (str.length > length)
        ? str.substring(0, length - 1) + "…"
        : str;
    };
  }); 