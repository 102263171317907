import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .factory('Utility', UtilityService);

UtilityService.$inject = ['clientStorage'];

function UtilityService(clientStorage) {
  var getAndFixFilters = function (filterCookieName, defaultFilters) {
    var filtri = clientStorage.get(filterCookieName) || angular.copy(defaultFilters);

    // Scorro tutti i default
    // per ogni default controllo se la sua voce c'è tra i filtri presi
    // se non c'è la imposto con il valore di default
    angular.forEach(defaultFilters, function (v, k) {
      if (!filtri.hasOwnProperty(k)) {
        filtri[k] = v;
      };
    }); // non serve che li salvo tanto li salvo dopo quando prendo i records
    // Poi scorro tutti i filtri correnti
    // per ogni filtro controllo se la sua voce c'è tra i filtri di default
    // se non c'è, la elimino
    angular.forEach(filtri, function (v, k) {
      if (!defaultFilters.hasOwnProperty(k)) {
        delete (filtri[k]);
      };
    });

    return filtri;
  }

  return {
    getAndFixFilters: getAndFixFilters
  };
}
