import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .directive('projectStatusTypeSelector', function () {
    return {
      restrict: 'A',
      scope: {
        optionalText: '@',
        optional: '=?',
      },
      template: `
        <option ng-if="optional" value="">{{ optionalText }}</option>
        <option value="{{ item.id }}" ng-repeat="item in items">{{ item.name }}</option>
      `,
      replace: false,
      controller: ['$scope', function ($scope) {
        $scope.items = [
          { id: 'OPEN', name: 'Aperto' },
          { id: 'CLOSED', name: 'Chiuso' },
          { id: 'SUSPENDED', name: 'Sospeso' },
        ];
      }],
    };
  });
