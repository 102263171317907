import * as angular from 'angular';
import toastr from 'toastr';

angular
  .module('screeningcanvas')
  .directive('userSelector', ['newApiClient', function (newApiClient) {
    return {
      restrict: 'A',
      scope: {
        areaId: '=?',
        optional: '=?',
        optionalText: "@",
      },
      template: '<option ng-if="optional" value="">{{ (!!optionalText) ? optionalText : "Nessuno" }}</option><option value="{{ item.id }}" ng-repeat="item in items">{{ item.fullName }} ({{ item.username }})</option>',
      replace: false,
      require: 'ngModel',
      link: function (scope, element, attrs, ngModel) {
        ngModel.$parsers.push(function (val) {
          return val != null ? parseInt(val, 10) : null;
        });
        ngModel.$formatters.push(function (val) {
          return val != null ? '' + val : null;
        });
      },
      controller: ['$scope', function ($scope) {
        $scope.items = [];

        $scope.fetch = function (areaId) {
          newApiClient.call(`
            query($filter: UserFilter, $order: UserOrder!, $pagination: Pagination!) {
              users {
                allUsers {
                  list(filter: $filter, order: $order, pagination: $pagination) {
                    id
                    username
                    fullName
                  }
                }
              }
            }`,
            {
              filter: { areaIds: (!!areaId) ? [areaId] : null, isActive: true },
              order: 'EMAIL_ASC',
              pagination: { skip: 0, take: 1000 }
            },
          ).then(function (data) {
            $scope.items = data.users.allUsers.list;
          }, function () {
            toastr.error("Impossibile caricare gli utenti");
          });
        };

        $scope.fetch($scope.areaId);

        $scope.$watch('areaId', function (newVal) {
          $scope.fetch(newVal);
        });
      }],
    };
  }]);
