import * as angular from 'angular';
import { Random } from '../utils';

angular
  .module('screeningcanvas')
  .directive('googleChart', function () {
    return {
      restrict: 'E',
      scope: {
        options: '=',
      },
      template: '<div id="{{ generatedId }}"></div>',
      replace: true,
      link: function (scope, element, attrs) {
        scope.generatedId = 'chart-' + Random.randomString(6);

        scope.refresh = () => {
          scope.chart = new google.visualization.ChartWrapper({
            ...scope.options,
            containerId: scope.generatedId,
          });

          scope.columns = [];
          scope.series = scope.chart.getOption('series');
          for (var i = 0; i < scope.chart.getDataTable().getNumberOfColumns(); i++) {
            scope.columns.push(i);
          }

          scope.chart.draw();
        };

        // Reference: http://jsfiddle.net/asgallant/6gz2Q/
        // Reference: https://stackoverflow.com/a/43998947/10042350
        // Reference: http://jsfiddle.net/xDUPF/53/
        // Reference: https://codepen.io/gapple/pen/nluHK
        scope.handleLegendHideShow = () => {
          var sel = scope.chart.getChart().getSelection();
          // if selection length is 0, we deselected an element
          if (sel.length > 0) {
            // if row is undefined, we clicked on the legend
            if (sel[0].row == null) {
              var col = sel[0].column;
              if (scope.columns[col] == col) {
                // hide the data series
                scope.columns[col] = {
                  label: scope.chart.getDataTable().getColumnLabel(col),
                  type: scope.chart.getDataTable().getColumnType(col),
                  calc: function () {
                    return null;
                  }
                };

                // grey out the legend entry
                scope.series[col - 1].originalColor = scope.series[col - 1].color;
                scope.series[col - 1].color = '#CCCCCC';
              }
              else {
                // show the data series
                scope.columns[col] = col;
                scope.series[col - 1].color = scope.series[col - 1].originalColor;
              }
              var view = scope.chart.getView() || {};
              view.columns = scope.columns;
              //scope.chart.setOption('series', scope.series);
              scope.chart.setView(view);
              scope.chart.draw();
            }
          }
        };

        google.charts.setOnLoadCallback(() => {
          scope.$watch('options', scope.refresh);
          scope.refresh();
          scope.selectListener = google.visualization.events.addListener(scope.chart, 'select', scope.handleLegendHideShow);
        });

        scope.$on('$destroy', function () {
          if (!!scope.selectListener) {
            google.visualization.events.removeListener(scope.selectListener);
          }
        });
      },
    };
  });
