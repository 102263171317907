import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .controller('ModalRemoveTransactionCtrl', ModalRemoveTransactionCtrl);

  ModalRemoveTransactionCtrl.$inject = ['$scope', '$uibModalInstance'];

  function ModalRemoveTransactionCtrl($scope, $uibModalInstance) {
    $scope.save = function () {
      $uibModalInstance.close({});
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }
  