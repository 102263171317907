import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .filter('formatEstimatePriceUnit', function () {
    return function (input) {
      switch (input) {
        case 'ONCE': return '';
        case 'ITEM': return '(al pz.)';
        case 'YEAR': return '(annuale)';
        case 'MONTH': return '(mensile)';
        case 'WEEK': return '(settimanale)';
        case 'HOUR': return '(all\'ora)';
        default: return '';
      }
    };
  });
