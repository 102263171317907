import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .controller('ModalProjectCreateAttachmentCtrl', ModalProjectCreateAttachmentCtrl);

  ModalProjectCreateAttachmentCtrl.$inject = ['$scope', '$uibModalInstance'];

  function ModalProjectCreateAttachmentCtrl($scope, $uibModalInstance) {
    $scope.data = { 
      description: null,
      fileId: null,
    };
    $scope.save = function () {
      $uibModalInstance.close($scope.data);
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }
  