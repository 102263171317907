import * as angular from 'angular';
import * as moment from 'moment';

angular
  .module('screeningcanvas')
  .controller('ProjectsModalRefuseCtrl', ProjectsModalRefuseCtrl);

  ProjectsModalRefuseCtrl.$inject = ['$scope', '$uibModalInstance'];

  function ProjectsModalRefuseCtrl($scope, $uibModalInstance) {
    $scope.data = { date: moment().format("YYYY-MM-DD") };
    $scope.save = function () {
      $uibModalInstance.close($scope.data);
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }
