import * as angular from 'angular';
import toastr from 'toastr';

angular
  .module('screeningcanvas')
  .directive('fileSelector', ['FileUploader', 'newApiClient', function (FileUploader, newApiClient) {
    return {
      restrict: 'E',
      require: '?ngModel',
      template: `
        <div class="file-selector" ng-if="!!uploader">
          <div class="drop-zone-title">Nuovo file</div>
          <div class="files-list-title">File recenti</div>
          <div nv-file-drop uploader="uploader" ng-click="clickOnDropZone()" class="drop-zone no-outline">
            Trascina qui i file o clicca per caricare
          </div>
          <input id="sectl" style="display: none;" type="file" nv-file-select uploader="uploader"/>
          <ul class="files-list">
            <li ng-repeat="item in uploader.queue" progress progress-percentage="item.progress">
              <div class="file-checkbox">
                <i style="font-size: 20px; user-select: none;" class="fa fa-circle font-grey"></i>
              </div>
              <div class="file">
                <div class="file-image">
                  <img src="img/files/file.svg" />
                </div>
                <div class="file-info">
                  {{ item.file.name }}<br />
                  <small>{{ item.file.size | formatSize }}</small>
                </div>
              </div>
            </li>
            <li ng-repeat="f in recentFiles" ng-class="{ 'file-selected': fileId === f.id }">
              <div class="file-checkbox">
                <span class="no-outline pointer" ng-click="select(null)" ng-if="fileId === f.id"><i style="font-size: 20px;" class="fa fa-check-circle font-green-jungle"></i></span>
                <span class="no-outline pointer" ng-click="select(f.id)" ng-if="fileId != f.id"><i style="font-size: 20px;" class="fa fa-circle font-grey"></i></span>
              </div>
              <div class="file">
                <div class="file-image">
                  <img src="img/files/{{ f.mimeType | mimeTypeIcon }}" />
                </div>
                <div class="file-info">
                  <a href="{{ f.url }}" target="_blank">{{ f.originalFileName }}</a><br />
                  <small>{{ f.sizeInBytes | formatSize }} • Caricato il {{ f.createdAt | formatDateTime }}</small>
                </div>
              </div>
            </li>
          </ul>
        </div>
      `,
      scope: {
        ngModel: '='
      },
      link: function ($scope, element, attrs, ngModel) {
        $scope.fileId = null;
        $scope.select = function (id) { $scope.fileId = id; }

        $scope.uploader = new FileUploader({
          url: "/api/files",
          autoUpload: true,
        });

        $scope.uploader.onCompleteItem = function (item, response, status, headers) {
          if (status != 200) {
            var message = "Non è stato possibile caricare il file";
            if (!!response.error) {
              message = response.error;
            }
            toastr.error(message, "Errore!", { timeOut: 0, extendedTimeOut: 0 });
          } else {
            $scope.fileId = response.fileId;
          }
          $scope.loadFiles(function () {
            item.remove();
          });
        };

        $scope.clickOnDropZone = function () {
          element.find('input[type=file]').click();
        };

        $scope.$watch('fileId', function (newVal) {
          ngModel.$setViewValue(newVal);
        });

        $scope.recentFiles = [];
        $scope.loadFiles = function (callbackFn) {
          newApiClient.call(`
            query($pagination: Pagination!) {
              storedFiles {
                allStoredFiles {
                  list(pagination: $pagination) {
                    id
                    originalFileName
                    mimeType
                    sizeInBytes
                    createdAt
                    url
                  }
                }
              }
            }`,
            {
              pagination: { take: 25, skip: 0 },
            },
          ).then(function (data) {
            if (!!callbackFn) callbackFn();
            $scope.recentFiles = data.storedFiles.allStoredFiles.list.map(x => ({
              ...x,
              originalFileName: x.originalFileName ?? "Senza nome",
            }));
          }, function () {
            toastr.error("Impossibile caricare i file");
          });
        };
        $scope.loadFiles();
      }
    };
  }]);
