import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .controller('ModalProjectUpdateAttachmentCtrl', ModalProjectUpdateAttachmentCtrl);

  ModalProjectUpdateAttachmentCtrl.$inject = ['$scope', 'projectAttachment', '$uibModalInstance'];

  function ModalProjectUpdateAttachmentCtrl($scope, projectAttachment, $uibModalInstance) {
    $scope.data = { 
      description: projectAttachment.description,
    };
    $scope.save = function () {
      $uibModalInstance.close($scope.data);
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }
  