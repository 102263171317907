import * as angular from 'angular';
import toastr from 'toastr';

angular
  .module('screeningcanvas')
  .directive('taskSelector', ['newApiClient', function (newApiClient) {
    return {
      restrict: 'A',
      scope: {
        projectId: '=?',
        optional: '=?',
        optionalText: "@",
      },
      template: '<option ng-if="optional" value="">{{ (!!optionalText) ? optionalText : "Nessuno" }}</option><option value="{{ item.id }}" ng-repeat="item in items">{{ item.name }}</option>',
      replace: false,
      controller: ['$scope', function ($scope) {
        $scope.items = [];

        $scope.fetch = function (projectId) {
          newApiClient.call(`
            query($filter: TaskFilter!, $order: TaskOrder!, $pagination: Pagination!) {
              tasks {
                allTasks {
                  list(filter: $filter, order: $order, pagination: $pagination) {
                    id
                    name
                  }
                }
              }
            }`,
            {
              filter: {
                projectIds: (!!projectId) ? [parseInt(projectId)] : null,
              },
              order: 'DEADLINE_DESC',
              pagination: { skip: 0, take: 1000 }
            },
          ).then(function (data) {
            $scope.items = data.tasks.allTasks.list;
          }, function () {
            toastr.error("Impossibile caricare i task");
          });
        };

        $scope.fetch($scope.projectId);

        $scope.$watch('projectId', function (newVal) {
          $scope.fetch(newVal);
        });
      }],
    };
  }]);
