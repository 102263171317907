import * as angular from 'angular';
import toastr from 'toastr';

angular
  .module('screeningcanvas')
  .controller('DigitalInvoiceDownloaderCtrl', DigitalInvoiceDownloaderCtrl);

  DigitalInvoiceDownloaderCtrl.$inject = ['$scope', 'newApiClient', '$http'];

  function DigitalInvoiceDownloaderCtrl($scope, newApiClient, $http) {
    var annoCorrente = new Date().getFullYear();
    var meseCorrente = new Date().getMonth() + 1;

    $scope.anni = [];
    for (var i = 2019; i <= annoCorrente; i++) {
      $scope.anni.push(i);
    };
    $scope.months = [
      { id: 1, label: "Gennaio" },
      { id: 2, label: "Febbraio" },
      { id: 3, label: "Marzo" },
      { id: 4, label: "Aprile" },
      { id: 5, label: "Maggio" },
      { id: 6, label: "Giugno" },
      { id: 7, label: "Luglio" },
      { id: 8, label: "Agosto" },
      { id: 9, label: "Settembre" },
      { id: 10, label: "Ottobre" },
      { id: 11, label: "Novembre" },
      { id: 12, label: "Dicembre" },
    ];

    $scope.filters = {
      anno: annoCorrente,
      mese: meseCorrente,
    };
    $scope.loadingLink = false;
    $scope.downloadLink = null;

    $scope.createDownloadLink = () =>
    {
      if (!$scope.filters.anno || !$scope.filters.mese)
        return;

      $scope.loadingLink = true;

      newApiClient.call(`
        mutation($input: CreateDigitalInvoiceArchiveInput!) {
          digitalInvoices {
            createDigitalInvoiceArchive(input: $input)
          }
        }
      `, {
        input: {
          year: $scope.filters.anno,
          month: $scope.filters.mese,
        }
      }).then(function(data) {
        $scope.downloadLink = data.digitalInvoices.createDigitalInvoiceArchive;
        $scope.loadingLink = false;
      }, function() {
        $scope.loadingLink = false;
        toastr.error("Impossibile caricare le fatture");
      });
    };

    $scope.$watch('filters.anno', function(oldVal, newVal) {
      if (oldVal !== newVal)
        $scope.createDownloadLink();
    });
    $scope.$watch('filters.mese', function(oldVal, newVal) {
      if (oldVal !== newVal)
        $scope.createDownloadLink();
    });
    $scope.createDownloadLink();
  }
