import * as angular from 'angular';

angular.module('screeningcanvas')
  .service('fileClient', ['$http', '$q', function ($http, $q) {
    this.fetchFile = function (fileURL) {
      return $http({
        method: 'GET',
        responseType: 'arraybuffer',
        url: fileURL,
      });
    }

    this.displayStyledXML = function (fileURL, stylesheet) {
      const mimeType = 'application/xml';
      this.fetchFile(fileURL).then(function (response) {
        var blob = new Blob([response.data], { type: mimeType });
        const reader = new FileReader();

        reader.addEventListener('loadend', (e) => {
          const text = e.target.result as string;
          $.get(stylesheet, function (xsl) {
            const cleanedText = text.replace(/^.*<\?xml/, '<?xml');

            var parser = new DOMParser();
            const xml = parser.parseFromString(cleanedText, mimeType);

            var xsltProcessor = new XSLTProcessor();
            xsltProcessor.importStylesheet(xsl);
            const doc = xsltProcessor.transformToDocument(xml);
            const docString = new XMLSerializer().serializeToString(doc);

            var w = window.open();
            w.document.write(docString);
          });
        });

        reader.readAsText(blob);
      });
    };
  }]);
