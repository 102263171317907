import * as angular from 'angular';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Convert } from '../../../utils';
import * as moment from 'moment';
import { FormBuilder, Validators } from '@angular/forms';
import { pairwise } from 'rxjs/operators';

export interface DashboardModalResult {
  parent: { companyId: number } | { taskId: number },
  typeId: number,
  description: string,
  period: {
    startsAt: string,
    endsAt: string,
  },
  isBillable: boolean,
}

@Component({
  selector: 'dashboard-modal',
  templateUrl: 'new/pages/dashboard/dashboard-modal.component.html',
})
export class DashboardModal implements OnInit {
  @Input() title: string;
  @Input() activity: any;
  @Input() showExtraButtons: boolean;
  @Output() onClose = new EventEmitter<DashboardModalResult>();
  @Output() onRemove = new EventEmitter();
  @Output() onDuplicate = new EventEmitter();

  private form = this.formBuilder.group({
    kind: ['standard', Validators.required],
    startsAt: [null, Validators.required],
    endsAt: [{ value: null, disabled: true }, Validators.required],
    companyId: [null, Validators.required],
    typeId: [null],
    projectId: [null, Validators.required],
    taskId: [null, Validators.required],
    description: ['', Validators.required],
    isBillable: [true, Validators.required],
  });

  constructor(
    @Inject(BsModalRef) private modalRef: BsModalRef,
    @Inject(FormBuilder) private formBuilder: FormBuilder,
  ) {}
  
  ngOnInit() {
    this.form.get('startsAt')
      .valueChanges
      .pipe(pairwise())
      .subscribe(([oldVal, newVal]) => {
        if (!!oldVal && !!newVal) {
          let endsAt = this.form.get('endsAt');
          const difference = endsAt.value.diff(oldVal);
          endsAt.setValue(newVal.clone().add(difference));
        }
      });

    this.form.get('kind')
      .valueChanges
      .subscribe((newVal) => {
        if (newVal === 'standard') {
          this.form.get('typeId').setValidators([]);
          this.form.get('projectId').setValidators([Validators.required]);
          this.form.get('taskId').setValidators([Validators.required]);
        } else {
          this.form.get('typeId').setValidators([Validators.required]);
          this.form.get('projectId').setValidators([]);
          this.form.get('taskId').setValidators([]);
        }
        this.form.controls['typeId'].updateValueAndValidity();
        this.form.controls['projectId'].updateValueAndValidity();
        this.form.controls['taskId'].updateValueAndValidity();
      });

    this.form.get('kind').setValue((!this.activity.taskId && !!this.activity.companyId) ? 'extra' : 'standard');
    this.form.get('startsAt').setValue(moment(this.activity.startsAt));
    this.form.get('endsAt').setValue(moment(this.activity.endsAt));
    this.form.get('companyId').setValue(this.activity.companyId);
    this.form.get('projectId').setValue(this.activity.projectId);
    this.form.get('taskId').setValue(this.activity.taskId);
    this.form.get('typeId').setValue(this.activity.typeId);
    this.form.get('description').setValue(this.activity.description);
    this.form.get('isBillable').setValue(this.activity.isBillable);

    this.form.get('companyId').valueChanges.subscribe((_) => {
      this.form.get('projectId').setValue(null);
      this.form.get('taskId').setValue(null);
    });

    this.form.get('projectId').valueChanges.subscribe((_) => {
      this.form.get('taskId').setValue(null);
    });
  }

  onSubmit() {
    const data = this.form.getRawValue();
    const isExtra = data.kind == 'extra';
    this.onClose.emit({
      parent: (isExtra) 
        ? {
          companyId: Convert.toInt(data.companyId),
        }
        : {
          taskId: Convert.toInt(data.taskId),
        },
      typeId: Convert.toInt(data.typeId),
      description: data.description,
      period: {
        startsAt: data.startsAt.toISOString(),
        endsAt: data.endsAt.toISOString(),
      },
      isBillable: data.isBillable,
    });
    this.modalRef.hide();
  }

  onRemoveClick() {
    this.onRemove.emit();
    this.modalRef.hide();
  }

  onDuplicateClick() {
    this.onDuplicate.emit();
    this.modalRef.hide();
  }

  onCancel() {
    this.modalRef.hide();
  }
}

angular.module('screeningcanvas').directive('dashboardModal', downgradeComponent({ component: DashboardModal }));