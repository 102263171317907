import * as angular from 'angular';

angular.module('screeningcanvas')
  .service('newApiClient', ['$http', '$q', '$state', function ($http, $q, $state) {
    this.call = function (query, variables) {
      var deferred = $q.defer();
      $http.post("/graphql", {
        query: query,
        variables: variables,
      }).then(function (response) {
        if (response.data.errors) {
          const anyAuthorizationErrors = response.data.errors
            .some(x => x.extensions.code == 'AUTHORIZATION_ERROR');
          if (anyAuthorizationErrors) {
            $state.go('login');
          } else {
            deferred.reject(response.data.errors);
          }
          return;
        }

        if (!response.data) {
          deferred.reject(response.data);
          return;
        }

        deferred.resolve(response.data.data);
      }, function (err) {
        deferred.reject(err);
        console.error(err);
      });
      return deferred.promise;
    };
  }]);
