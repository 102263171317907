import * as angular from 'angular';
import toastr from 'toastr';
import { parseDuration, Convert } from '../../../utils';

angular
  .module('screeningcanvas')
  .controller('ProjectTypesTabsEstimateCtrl', ProjectTypesTabsEstimateCtrl);

  ProjectTypesTabsEstimateCtrl.$inject = ['$scope', 'newApiClient', '$stateParams'];

  function ProjectTypesTabsEstimateCtrl($scope, newApiClient, $stateParams) {
    $scope.loading = false;
    $scope.softLoading = false;
    $scope.isEditing = false;
    $scope.projectType = null;

    $scope.load = (initialLoading, editingPartId) => {
      $scope.loading = initialLoading;
      newApiClient.call(`
        query($id: Int!) {
          projectTypes {
            projectType(id: $id) {
              id
              estimateParts {
                id
                title
                description
                orderKey

                prices {
                  id
                  amount
                  unit
                  isDeposit
                }

                tasks {
                  id
                  label
                  estimatedTime
                  unit
                }
              }
            }
          }
        }`,
        {
          id: parseInt($stateParams.id),
        },
      ).then(function(data) {
        $scope.loading = false;
        $scope.projectType = data.projectTypes.projectType;
        $scope.projectType._isEditing = false;
        $scope.isEditing = false;
        if (!!editingPartId) {
          $scope.projectType.estimateParts.forEach(x => { if (x.id == editingPartId) x._isEditing = true; });
          $scope.isEditing = true;
        }
      }, function(errors) {
        $scope.loading = false;
        $scope.isEditing = false;
        toastr.error(errors[0].message, "Impossibile caricare il progetto");
      });
    };

    $scope.load(true);

    $scope.initEdit = (estimatePart) =>
    {
      if ($scope.isEditing)
        return;
      $scope.isEditing = true;
      estimatePart._isEditing = true;
    };

    $scope.initProjectInfoEdit = () => {
      if ($scope.isEditing)
        return;
      $scope.isEditing = true;
      $scope.projectType._isEditing = true;
    };

    $scope.createEstimatePart = (atOrderKey) =>
    {
      $scope.softLoading = true;
      newApiClient.call(`
        mutation($input: CreateEstimatePartInput!) {
          estimateParts {
            createEstimatePart(input: $input) {
              id
            }
          }
        }`,
        {
          input: {
            title: "",
            description: "",
            parent: {
              projectTypeId: parseInt($stateParams.id),
            },
            orderKey: atOrderKey,
          }
        },
      ).then(function(data) {
        $scope.softLoading = false;
        $scope.load(false, data.estimateParts.createEstimatePart.id);
        $scope.isEditing = false;
      }, function(errors) {
        $scope.softLoading = false;
        toastr.error(errors[0].message, "Impossibile creare una sezione preventivo");
      });
    };

    $scope.saveEstimatePart = (estimatePart) =>
    {
      $scope.softLoading = true;
      newApiClient.call(`
        mutation($input: UpdateEstimatePartInput!) {
          estimateParts {
            updateEstimatePart(input: $input) {
              id
            }
          }
        }`,
        {
          input: {
            estimatePartId: estimatePart.id,
            title: estimatePart.title,
            description: estimatePart.description,
          }
        },
      ).then(function(data) {
        $scope.softLoading = false;
        estimatePart._isEditing = false;
        $scope.load(false);
        $scope.isEditing = false;
      }, function(errors) {
        $scope.softLoading = false;
        estimatePart._isEditing = false;
        toastr.error(errors[0].message, "Impossibile modificare la sezione preventivo");
      });
    };

    $scope.removeEstimatePart = (estimatePart) =>
    {
      $scope.softLoading = true;
      newApiClient.call(`
        mutation($id: Int!) {
          estimateParts {
            removeEstimatePart(estimatePartId: $id)
          }
        }`,
        { id: estimatePart.id },
      ).then(function(data) {
        $scope.softLoading = false;
        $scope.isEditing = false;
        $scope.load(false);
      }, function(errors) {
        $scope.softLoading = false;
        $scope.isEditing = false;
        toastr.error(errors[0].message, "Impossibile rimuovere la sezione preventivo");
      });
    };

    $scope.isInEditMode = () => !!$scope.projectType.estimateParts.find(x => x._isEditing || x._isCreatingPrice);

    $scope.onDropPart = (orderKey, part) =>
    {
      var newOrderKey = (orderKey > part.orderKey)
        ? orderKey - 1 // dobbiamo contare che 1 è occupato dall'elemento che si sta muovendo
        : orderKey;
      if (newOrderKey != part.orderKey)
      {
        $scope.softLoading = true;
        newApiClient.call(`
          mutation($input: UpdateEstimatePartInput!) {
            estimateParts {
              updateEstimatePart(input: $input) {
                id
              }
            }
          }`,
          {
            input: {
              estimatePartId: part.id,
              orderKey: newOrderKey,
            }
          },
        ).then(function(data) {
          $scope.softLoading = false;
          $scope.load(false);
        }, function(errors) {
          $scope.softLoading = false;
          toastr.error(errors[0].message, "Impossibile spostare la sezione preventivo");
        });
      }
    };

    $scope.createNewPrice = (estimatePart) => {
      estimatePart._isCreatingPrice = true;
      estimatePart.priceDraft = {
        estimatePartId: estimatePart.id,
        amount: 0.0,
        unit: 'ONCE',
        isDeposit: false,
      };
      $scope.isEditing = true;
    };

    $scope.createEstimatePrice = (estimatePart, data) => {
      $scope.softLoading = true;
      newApiClient.call(`
        mutation($input: CreateEstimatePriceInput!) {
          estimatePrices {
            createEstimatePrice(input: $input) {
              id
            }
          }
        }`,
        {
          input: data,
        }
      ).then(function(data) {
        $scope.softLoading = false;
        $scope.isEditing = false;
        $scope.load(false);
      }, function(errors) {
        $scope.softLoading = false;
        $scope.isEditing = false;
        toastr.error(errors[0].message, "Impossibile creare prezzo");
      });
    };

    $scope.initPriceEditing = (price) => {
      if ($scope.isEditing)
        return;
      $scope.isEditing = true;
      price._isEditing = true;
    };

    $scope.updateEstimatePrice = (price) => {
      $scope.softLoading = true;
      newApiClient.call(`
        mutation($input: UpdateEstimatePriceInput!) {
          estimatePrices {
            updateEstimatePrice(input: $input) {
              id
            }
          }
        }`,
        {
          input: {
            estimatePriceId: price.id,
            amount: price.amount,
            unit: price.unit,
            isDeposit: price.isDeposit,
          }
        },
      ).then(function(data) {
        $scope.softLoading = false;
        $scope.isEditing = false;
        $scope.load(false);
      }, function(errors) {
        $scope.softLoading = false;
        $scope.isEditing = false;
        toastr.error(errors[0].message, "Impossibile creare prezzo");
      });
    };

    $scope.removeEstimatePrice = (price) => {
      $scope.softLoading = true;
      newApiClient.call(`
        mutation($id: Int!) {
          estimatePrices {
            removeEstimatePrice(estimatePriceId: $id)
          }
        }`,
        { id: price.id, },
      ).then(function(data) {
        $scope.softLoading = false;
        $scope.isEditing = false;
        $scope.load(false);
      }, function(errors) {
        $scope.softLoading = false;
        $scope.isEditing = false;
        toastr.error(errors[0].message, "Impossibile rimuovere prezzo");
      });
    };

    /* Task management */
    $scope.createNewTask = (estimatePart) => {
      estimatePart._isCreatingTask = true;
      estimatePart.taskDraft = {
        estimatePartId: estimatePart.id,
        label: "Realizzazione",
        hoursAmount: 1,
        unit: 'ONCE',
      };
      $scope.isEditing = true;
    };

    $scope.createEstimateTask = (estimatePart, data) => {
      $scope.softLoading = true;
      newApiClient.call(`
        mutation($input: CreateEstimateTaskInput!) {
          estimateTasks {
            createEstimateTask(input: $input) {
              id
            }
          }
        }`,
        {
          input: {
            estimatePartId: estimatePart.id,
            label: data.label,
            estimatedTime: Convert.fromHoursToDuration(data.hoursAmount),
            unit: data.unit,
          }
        },
      ).then(function(data) {
        $scope.softLoading = false;
        $scope.isEditing = false;
        $scope.load(false);
      }, function(errors) {
        $scope.softLoading = false;
        $scope.isEditing = false;
        toastr.error(errors[0].message, "Impossibile creare task");
      });
    };

    $scope.initTaskEditing = (task) => {
      if ($scope.isEditing)
        return;
      $scope.isEditing = true;
      task._isEditing = true;
      task.hoursAmount = parseDuration(task.estimatedTime).asHours();
    };

    $scope.updateEstimateTask = (task) => {
      $scope.softLoading = true;
      newApiClient.call(`
        mutation($input: UpdateEstimateTaskInput!) {
          estimateTasks {
            updateEstimateTask(input: $input) {
              id
            }
          }
        }`,
        {
          input: {
            estimateTaskId: task.id,
            label: task.label,
            estimatedTime: Convert.fromHoursToDuration(task.hoursAmount),
            unit: task.unit,
          }
        },
      ).then(function(data) {
        $scope.softLoading = false;
        $scope.isEditing = false;
        $scope.load(false);
      }, function(errors) {
        $scope.softLoading = false;
        $scope.isEditing = false;
        toastr.error(errors[0].message, "Impossibile creare task");
      });
    };

    $scope.removeEstimateTask = (task) => {
      $scope.softLoading = true;
      newApiClient.call(`
        mutation($id: Int!) {
          estimateTasks {
            removeEstimateTask(estimateTaskId: $id)
          }
        }`,
        { id: task.id, },
      ).then(function(data) {
        $scope.softLoading = false;
        $scope.isEditing = false;
        $scope.load(false);
      }, function(errors) {
        $scope.softLoading = false;
        $scope.isEditing = false;
        toastr.error(errors[0].message, "Impossibile rimuovere task");
      });
    };
  }