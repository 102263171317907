import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .directive('invoiceTypeSelector', [function () {
    return {
      restrict: 'A',
      template: '<option value="{{ item.id }}" ng-repeat="item in items">{{ item.value }}</option>',
      replace: false,
      scope: {
        kind: '@',
        optional: '=?',
        optionalText: "@",
      },
      controller: ['$scope', function ($scope) {
        const debit = [
          { id: 'INVOICE', value: 'Fattura' },
          { id: 'INVOICE_DEPOSIT', value: 'Acconto' },
          { id: 'PARCEL', value: 'Parcella' },
          { id: 'PARCEL_DEPOSIT', value: 'Acconto su parcella' },
          { id: 'DEBIT_NOTE', value: 'Nota di debito' },
        ];
        const credit = [
          { id: 'CREDIT_NOTE', value: 'Nota di credito' },
        ];
        switch ($scope.kind) {
          case 'credit':
            $scope.items = credit;
            break;
          case 'debit':
            $scope.items = debit;
            break;
          default:
            $scope.items = [...debit, ...credit];
        }
        if ($scope.optional) {
          $scope.items = [{ id: '', value: $scope.optionalText ?? 'Nessuna' }, ...$scope.items];
        }
      }],
    };
  }]);
