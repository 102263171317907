import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .controller('ModalExtraSetBillable', ModalExtraSetBillable);

  ModalExtraSetBillable.$inject = ['$scope', 'isBillable', '$uibModalInstance'];

  function ModalExtraSetBillable($scope, isBillable, $uibModalInstance) {
    $scope.isBillable = isBillable;
    $scope.save = function () {
      $uibModalInstance.close({});
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }