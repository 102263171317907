import './polyfills';

// Imports for AngularJS
import './shared/components';
import './pages';

import { NgModule, NgZone } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { HttpClientModule } from '@angular/common/http';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { UIRouter, UrlService } from '@uirouter/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FullCalendarModule } from '@fullcalendar/angular';
import { setTheme } from 'ngx-bootstrap/utils';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

// Pages
import { DashboardPage } from './pages/dashboard/dashboard-page.component';
import { DashboardModal } from './pages/dashboard/dashboard-modal.component';
import { LoginPage } from './pages/login/login-page.component';
import { UsersPage } from './pages/users/users-page.component';
import { UserEditModal } from './pages/users/user-edit-modal.component';

// providers
import { uiRouterLocationProvider, uiRouterStateProvider, userProvider } from './ajs-upgraded-providers';
import { apolloProvider } from './providers';

// Pipes
import { FormatDecimalPipe } from './shared/pipes/formatDecimal.pipe';

// Components
import { Layout } from './shared/components/layout.component';
import { SummaryBar } from './shared/components/summary-bar.component';
import { DateTimePicker } from './shared/components/date-time-picker.component';
import { CompanySelector } from './shared/components/company-selector.component';
import { ActivityTypeSelector } from './shared/components/activity-type-selector.component';
import { UserRoleSelector } from './shared/components/user-role-selector.component';
import { UserAreaSelector } from './shared/components/user-area-selector.component';
import { ProjectSelector } from './shared/components/project-selector.component';
import { TaskSelector } from './shared/components/task-selector.component';
import { BooleanIcon } from './shared/components/boolean-icon.component';
import { AlertModal } from './shared/components/alert-modal.component';

FullCalendarModule.registerPlugins([
  timeGridPlugin,
  interactionPlugin
]);

@NgModule({
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    UpgradeModule,
    // @uirouter hybrid boot
    UIRouterUpgradeModule.forRoot(),
    FullCalendarModule,
    
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
  ],
  providers: [
    userProvider,
    uiRouterStateProvider,
    uiRouterLocationProvider,
    apolloProvider,
  ],
  declarations: [
    DateTimePicker,
    CompanySelector,
    ActivityTypeSelector,
    UserRoleSelector,
    UserAreaSelector,
    ProjectSelector,
    TaskSelector,

    FormatDecimalPipe,

    Layout,
    SummaryBar,
    BooleanIcon,
    AlertModal,
    DashboardModal,
    UserEditModal,

    DashboardPage,
    LoginPage,
    UsersPage,
  ],
  entryComponents: [
    Layout,
    SummaryBar,
    BooleanIcon,
    AlertModal,
    DashboardModal,
    UserEditModal,

    DashboardPage,
    LoginPage,
    UsersPage,
  ],
})
export class AppModule {
  constructor(private upgrade: UpgradeModule) {
    setTheme('bs3');
  }

  ngDoBootstrap() {
    this.upgrade.bootstrap(document.body, ["screeningcanvas"], { strictDi: false })
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // @uirouter hybrid boot
  .then(platformRef => {
    const urlService: UrlService = platformRef.injector.get(UIRouter).urlService;
    function startUIRouter() {
      urlService.listen();
      urlService.sync();
    }
    platformRef.injector.get<NgZone>(NgZone).run(startUIRouter);
  });