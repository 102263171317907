import { ApolloError } from '@apollo/client';
import toastr from 'toastr';

export let showError = (message: string) => {
  const toastrConfig = { timeOut: 0, extendedTimeOut: 0 };
  toastr.error(message, "Errore", toastrConfig);
};

export let showGraphQLErrors = (errors: Array<any>) => {
  const userErrors = errors.filter(x => !!x.extensions && !!x.extensions.userMessage);
  if (userErrors.length == 0) {
    showError("Errore sconosciuto");
  } else {
    const message = userErrors[0].extensions.userMessage;
    showError(message);
  }
};

export let showApolloError = (error: ApolloError) => {
  if (error.networkError != null) {
    showError("Errore di rete");
    return;
  }

  if (!error.graphQLErrors) {
    showError("Errore applicazione");
    console.error(error);
  }

  const userErrors = error.graphQLErrors.filter(x => !!x.extensions && !!x.extensions.userMessage);
  if (userErrors.length == 0) {
    showError("Errore applicazione");
    console.error(error);
  } else {
    const message = userErrors[0].extensions.userMessage;
    showError(message);
  }
};