import * as angular from 'angular';
import { showApolloError } from '../../alert';
import { Component, Inject, OnInit } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { downgradeComponent } from '@angular/upgrade/static';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UserEditModal } from './user-edit-modal.component';
import { AlertModal } from '../../shared/components/alert-modal.component';

@Component({
  selector: 'users-page',
  templateUrl: 'new/pages/users/users-page.component.html',
})
export class UsersPage implements OnInit {
  private loading: boolean = false;
  private activeUsers: any[] = null;
  private inactiveUsers: any[] = null;

  constructor(
    @Inject(Apollo) private apollo: Apollo,
    @Inject(BsModalService) private modalService: BsModalService,
  ) {}

  async ngOnInit() {
    await this.loadUsers();
  }

  openEditModal(user) {
    const modalRef = this.modalService.show(UserEditModal, {
      initialState: {
        title: 'Modifica utente',
        initial: {
          name: user.name,
          surname: user.surname,
          email: user.email,
          roleId: user.role.id,
          areaIds: user.areas.map(a => a.id),
        },
      }
    });
    modalRef.content.onClose.subscribe((input) => {
      this.updateUser(user.id, input);
    });
  }

  openCreateModal() {
    const defaultUserRoleId = 3;
    const modalRef = this.modalService.show(UserEditModal, {
      initialState: {
        title: 'Nuovo utente',
        initial: {
          name: null,
          surname: null,
          email: null,
          roleId: defaultUserRoleId,
          areaIds: [],
        },
      }
    });
    modalRef.content.onClose.subscribe((input) => {
      this.createUser(input);
    });
  }

  openToggleActiveModal(user) {
    const initialState = (user.isActive)
      ? { title: 'Disattivazione utente', body: 'Sei sicuro/a di voler disattivare questo utente? Verrà anche disconnesso dal sistema.' }
      : { title: 'Attivazione utente', body: 'Sei sicuro/a di voler attivare questo utente? Potrà accedere al sistema.' };
    const modalRef = this.modalService.show(AlertModal, { initialState: initialState });
    modalRef.content.onConfirm.subscribe(() => {
      this.toggleUserIsActive(user.id);
    });
  }

  openRemoveModal(user) {
    const modalRef = this.modalService.show(AlertModal, { 
      initialState: {
        title: 'Rimozione utente',
        body: 'Sei sicuro di voler rimuovere questo utente?',
      } 
    });
    modalRef.content.onConfirm.subscribe(() => {
      this.removeUser(user.id);
    });
  }

  async updateUser(userId, input) {
    try {
      await this.apollo.mutate({
        mutation: gql`
          mutation($input: UpdateUserInput!) {
            users {
              updateUser(input: $input) {
                id
              }
            }
          }
        `,
        variables: {
          input: {
            userId: userId,
            ...input,
          },
        }
      }).toPromise();
      await this.loadUsers(false);
    } catch (error) {
      showApolloError(error);
    }
  }

  async createUser(input) {
    try {
      await this.apollo.mutate({
        mutation: gql`
          mutation($input: CreateUserInput!) {
            users {
              createUser(input: $input) {
                id
              }
            }
          }
        `,
        variables: { input: input }
      }).toPromise();
      await this.loadUsers(false);
    } catch (error) {
      showApolloError(error);
    }
  }

  async toggleUserIsActive(userId) {
    try {
      await this.apollo.mutate({
        mutation: gql`
          mutation($id: Int!) {
            users {
              toggleUserIsActive(userId: $id) {
                id
              }
            }
          }
        `,
        variables: { id: userId }
      }).toPromise();
      await this.loadUsers(false);
    } catch (error) {
      showApolloError(error);
    }
  }

  async removeUser(userId) {
    try {
      await this.apollo.mutate({
        mutation: gql`
          mutation($id: Int!) {
            users {
              removeUser(userId: $id)
            }
          }
        `,
        variables: { id: userId }
      }).toPromise();
      await this.loadUsers(false);
    } catch (error) {
      showApolloError(error);
    }
  }

  async loadUsers(loading: boolean = true) {
    this.loading = loading;
    try {
      const { data } = await this.apollo.query<any>({
        query: gql`
          query {
            users {
              allUsers {
                list(pagination: { take: 1000 }) {
                  id
                  fullName
                  name
                  surname
                  avatar
                  email
                  role {
                    id
                    label
                  }
                  areas {
                    id
                    name
                  }
                  isActive
                }
              }
            }
          }
        `
      }).toPromise();
      const users = data.users.allUsers.list.map(u => ({ 
        ...u, 
        areasDesc: u.areas.map(a => a.name).join(', '),
      }));
      this.activeUsers = users.filter(u => u.isActive);
      this.inactiveUsers = users.filter(u => !u.isActive);
    } catch (error) {
      showApolloError(error);
    } finally {
      this.loading = false;
    }
  }
}

angular.module('screeningcanvas').directive('usersPage', downgradeComponent({ component: UsersPage }));