import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .directive('noteinfo', function () {
    return {
      restrict: 'E',
      scope: {
        title: '@title',
        description: '@description',
        type: '@type'
      },
      templateUrl: 'templates/directives/noteinfo.html',
      transclude: true,
      controller: function ($scope) {
        $scope.title = (typeof $scope.title !== 'undefined' && $scope.title != '') ? $scope.title : '';
        $scope.type = (typeof $scope.type !== 'undefined') ? $scope.type : 'info';
      }
    };
  });
