import 'jquery';
import 'angular';
import 'angular-route';
import 'angular-material';
import 'angular-animate';
import 'angular-aria';
import 'angular-cookies';
import 'angular-ui-bootstrap';
import 'angular-ui-sortable';
import 'angular-nvd3';
import 'angularjs-nvd3-directives';
import 'angular-messages';
import 'angular-google-chart';
import 'angular-scroll';
import 'angular-sanitize';
import 'bootstrap';
import 'eonasdan-bootstrap-datetimepicker';
import 'angular-file-upload';

import './app';

import './directives/activityTypeSelector_directive';
import './directives/areaSelector_directive';
import './directives/autoHeight_directive';
import './directives/companySelector_directive';
import './directives/countrySelector_directive';
import './directives/datePicker_directive';
import './directives/estimatePriceUnitSelector_directive';
import './directives/estimateTaskUnitSelector_directive';
import './directives/filtersCardButtonsVm_directive';
import './directives/filtersCardButtons_directive';
import './directives/formatPrice_directive';
import './directives/googleChart_directive';
import './directives/invoiceTypeSelector_directive';
import './directives/itemUnitSelector_directive';
import './directives/mainloading_directive';
import './directives/markdownEditor_directive';
import './directives/markdownViewer_directive';
import './directives/noteinfo_directive';
import './directives/paginator_directive';
import './directives/paymentDateOffsetSelector_directive';
import './directives/paymentMethodSelector_directive';
import './directives/paymentTermSelector_directive';
import './directives/percentageMask_directive';
import './directives/priceMask_directive';
import './directives/projectMembershipSelector_directive';
import './directives/projectSelector_directive';
import './directives/projectStatusTypeSelector_directive';
import './directives/projectStatus_directive';
import './directives/projectTypeSelector_directive';
import './directives/purchaseInvoiceCategorySelector_directive';
import './directives/purchaseInvoiceSelector_directive';
import './directives/quantityMask_directive';
import './directives/regionSelector_directive';
import './directives/taskSelector_directive';
import './directives/taxCode_directive';
import './directives/thSortable_directive';
import './directives/toolbarForFilters_directive';
import './directives/transactionTagSelector_directive';
import './directives/userSelector_directive';
import './directives/vatCollectabilitySelector_directive';
import './directives/vatMask_directive';
import './directives/vatNumber_directive';
import './directives/vatOperationTypeSelector';
import './directives/visualizzasomme_directive';
import './directives/waybillMeanSelector_directive';
import './directives/waybillPayerSelector_directive';
import './directives/fileSelector_directive';
import './directives/progress_directive';

import './services/clientStorage';
import './services/fileClient';
import './services/newApiClient';
import './services/user_factory';
import './services/utility';

import './app/elencofatturato/elencofatturato_ctrl';
import './app/elencofatturatoperanno/elencofatturatoperanno_ctrl';
import './app/utentiorelavorate/utentiorelavorate_ctrl';
import './app/vatseparation/vatseparation_ctrl';
import './app/analisicomposizionefatturato/analisi_composizione_fatturato_ctrl';
import './app/companies/edit_ctrl';
import './app/companies/list_ctrl';
import './app/companies/modal_merge_ctrl';
import './app/companies/new_ctrl';
import './app/costifornitori/CostiFornitori_ctrl';
import './app/digitalinvoicedownloader/index';
import './app/elencofatture/elencofatture_ctrl';
import './app/elencofatture/modal_send_ctrl';
import './app/elencofatture/modal_digital_invoices_ctrl';
import './app/extras/list';
import './app/extras/modal_edit';
import './app/extras/modal_move';
import './app/extras/modal_set_billable';
import './app/fatturatore/fatturatore_ctrl';
import './app/fatturatore/modal_add_ctrl';
import './app/fatturatore/modal_add_variation_ctrl';
import './app/fatturazioneveloce/fatturazioneveloce_ctrl';
import './app/fatturazioneveloce/modal_fattura_ctrl';
import './app/gestionearee/gestione_aree_ctrl';
import './app/lavoriincorso/lavoriincorso_ctrl';
import './app/lavoriincorso/modal_complete_ctrl';
import './app/lavoriincorso/modal_edit_ctrl';
import './app/notedicredito/modal_add';
import './app/notedicredito/new';
import './app/progetti/progetti_ctrl';
import './app/progettodettaglio/modals/accept';
import './app/progettodettaglio/modals/create_report';
import './app/progettodettaglio/modals/create_attachment';
import './app/progettodettaglio/modals/update_attachment';
import './app/progettodettaglio/modals/edit_activity';
import './app/progettodettaglio/modals/edit_expense';
import './app/progettodettaglio/modals/edit_task';
import './app/progettodettaglio/modals/pricing';
import './app/progettodettaglio/modals/question';
import './app/progettodettaglio/modals/refuse';
import './app/progettodettaglio/modals/schedule';
import './app/progettodettaglio/progetto_dettaglio_ctrl';
import './app/progettodettaglio/progettoedit_ctrl';
import './app/progettodettaglio/progettonew_ctrl';
import './app/progettodettaglio/tabs/contracts';
import './app/progettodettaglio/tabs/estimate';
import './app/projecttypes/details';
import './app/projecttypes/list';
import './app/projecttypes/modal_edit_type';
import './app/projecttypes/tabs/contracts';
import './app/projecttypes/tabs/estimate';
import './app/purchaseInvoices/elencofattureacquisto_ctrl';
import './app/purchaseInvoices/fatturaacquisto_edit_ctrl';
import './app/purchaseInvoices/fatturaacquisto_new_ctrl';
import './app/purchaseInvoices/modalSpeseFatturaAcquisto_ctrl';
import './app/purchaseInvoices/modalRemove';
import './app/scadenzario/modal_edit_ctrl';
import './app/scadenzario/modal_edit_payed_ctrl';
import './app/scadenzario/modal_remove_ctrl';
import './app/scadenzario/scadenzario_ctrl';
import './app/tickets/modal_chiudi_ctrl';
import './app/tickets/modal_edit_ctrl';
import './app/tickets/tickets_ctrl';
import './app/timesheet/timesheet_ctrl';
import './app/waybills/create_ctrl';
import './app/waybills/list_ctrl';
import './app/waybills/modal_add_ctrl';
import './app/waybills/update_ctrl';

import './filters';
import './utils';

import 'tui-editor/dist/tui-editor.min.css';
import 'tui-editor/dist/tui-editor-contents.min.css';
import 'codemirror/lib/codemirror.css';
import 'highlight.js/styles/github.css';

import 'ngx-bootstrap/datepicker/bs-datepicker.css';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'toastr/build/toastr.min.css';
import 'nvd3/build/nv.d3.min.css';
import './mytheme/assets/global/plugins/bootstrap-switch/css/bootstrap-switch.min.css';
import './mytheme/assets/global/plugins/bootstrap-daterangepicker/daterangepicker.min.css';
import './mytheme/assets/global/css/components.min.css';
import './mytheme/assets/global/css/plugins.min.css';
import './mytheme/assets/layouts/layout/css/layout.min.css';
import './mytheme/assets/layouts/layout/css/themes/darkblue.min.css';
import './mytheme/assets/layouts/layout/css/custom.min.css';
import './mytheme/assets/pages/css/login-5.min.css';
import './css/extra.css';
import './css/extra_scss.css';
import './css/main.css';
import './css/fatturatore.css';
import './css/products.css';
import './css/bootstrap-datetimepicker.min.css';

import './new/app.module';