import * as angular from 'angular';
import * as moment from 'moment';
import toastr from 'toastr';

angular
  .module('screeningcanvas')
  .controller('FatturatoreCtrl', FatturatoreCtrl);

  FatturatoreCtrl.$inject = ['$scope', '$state', '$stateParams', '$uibModal', 'percentuale_iva', 'newApiClient'];

  function FatturatoreCtrl($scope, $state, $stateParams, $uibModal, percentuale_iva, newApiClient) {
    $scope.loading = false;

    $scope.invoiceId = $stateParams.invoiceId;
    $scope.isEdit = !!$stateParams.invoiceId;

    $scope.default_vat = percentuale_iva;
    $scope.customers = [];
    $scope.data = {
      type: 'INVOICE',
      customerId: null,
      date: moment().format('YYYY-MM-DD'),
      paymentMethodId: null,
      paymentTermId: null,
      note: null,
      rows: [],
      vatCollectability: null,
    };

    $scope.formatCustomerName = function(customer) {
      const invoiceType = $scope.data.type;
      return customer.name + ' (' + customer.billableProjects.list.filter(x => isAvailableFor(invoiceType, x)).length + ' progetti fatturabili)';
    };

    $scope.visibleCustomers = function() {
      const invoiceType = $scope.data.type;
      return $scope.customers.filter(x => x.id == $scope.data.customerId || x.billableProjects.list.filter(x => isAvailableFor(invoiceType, x)).length > 0);
    }

    var orderRows = function(rows) {
      var orderedRows = rows.sort(function(a, b) {
        var a1 = a.yearlyCode;
        var b1 = b.yearlyCode;
        if (a1 > b1) return -1;
        if (a1 < b1) return 1;
        return 0;
      });
      return orderedRows;
    };

    const projectFragment = `
      id
      yearlyCode
      folderCode
      note
      title
      status {
        type
        updatedAt
      }
      paymentInfo {
        paymentMethod {
          id
          label
        }
        paymentTerm {
          id
          label
        }
      }
      price
      billablePrice
      quantity
      billableQuantity
      administrativeNote
      waybillReferences {
        id
        yearlyCode
        date
        pdfFile
      }
    `;

    // @todo: remove `fromDate: "2019-01-01"` in filter when Ines is ready to tackle old projects
    const customerFragment = `
      id
      name
      preferredPaymentMethod { id }
      preferredPaymentTerm { id }
      billableProjects: projects {
        list(filter: { fromDate: "2019-01-01", isBillable: true, statusTypes: [OPEN, CLOSED] }, pagination: { take: 1000 }) {
          ${projectFragment}
        }
      }
    `;

    $scope.loadingInvoice = false;

    if ($scope.isEdit) {
      $scope.loadingInvoice = true;
      // Carico i dati dei clienti perché devo aggiungere
      // il cliente della fattura corrente anche se non ha progetti
      // fatturabili
      newApiClient.call(`
        query {
          companies {
            allCompanies {
              list(pagination: { take: 1000 }) {
                ${customerFragment}
              }
            }
          }
        }
      `).then(function(customersData) {
        $scope.customers = customersData.companies.allCompanies.list;

        // Carico i dati della fattura...
        newApiClient.call(`
          query($id: Int!) {
            invoices {
              invoice(id: $id) {
                id
                type
                yearlyCode
                date
                recipient {
                  id
                }
                paymentInfo {
                  paymentMethod {
                    id
                  }
                  paymentTerm {
                    id
                  }
                }
                rows {
                  project {
                    ${projectFragment}
                  }
                  description
                  quantity
                  price
                  vat
                  vatOperationType {
                    id
                  }
                  priceVariations {
                    label
                    amount
                  }
                }
                note
                vatCollectability
              }
            }
          }
        `, {
          id: parseInt($scope.invoiceId),
        }).then(function(invoiceData) {
          $scope.invoice = invoiceData.invoices.invoice;

          $scope.data.customerId = $scope.invoice.recipient.id;

          const customerProjects = $scope.customers
            .find(x => x.id == $scope.invoice.recipient.id)
            .billableProjects.list;

          // Visto che sto modificando una fattura devo simulare come se i progetti
          // della fattura fossero stati tutti rimossi e metterli in available project.
          // Poi se sono già selezionati, la modal provvederà a filtrarli prima di darli come
          // selezionabili.
          $scope.invoice.rows.forEach(function(row) {
            const rowProject = row.project;
            const existingProject = customerProjects.find(function(p) { return p.id == rowProject.id; });
            if (!existingProject) {
              // Se il progetto non esiste in quelli già caricati, significa che
              // era un saldo, quindi lo aggiungo come tale:
              customerProjects.push({
                ...rowProject,
                billablePrice: row.price + row.project.billablePrice,
                billableQuantity: row.quantity + row.project.billableQuantity,
              });
            } else {
              // Se il progetto esiste, significa che era un acconto, quindi
              // lo aggiorno e ne sommo quantità e prezzo a quello di questa fattura.
              customerProjects.forEach(function(proj) {
                if (proj.id == rowProject.id) {
                  proj.billablePrice += row.price;
                  proj.billableQuantity += row.quantity;
                }
              });
            }
          });

          // Se sto modificando una fattura, imposto i dati dalla
          // fattura caricata stando attento alla nuova realtà.
          $scope.data = {
            type: $scope.invoice.type,
            customerId: $scope.invoice.recipient.id,
            date: $scope.invoice.date,
            paymentMethodId: (!!$scope.invoice.paymentInfo) ? ""+$scope.invoice.paymentInfo.paymentMethod.id : null,
            paymentTermId: (!!$scope.invoice.paymentInfo) ? ""+$scope.invoice.paymentInfo.paymentTerm.id : null,
            note: $scope.invoice.note,
            rows: orderRows($scope.invoice.rows.map(function(r) {
              const correspondingProject = customerProjects.find(function(p) { return p.id == r.project.id; });
              return {
                ...correspondingProject,
                description: r.description,
                amount: r.price,
                quantity: r.quantity,
                vat: r.vat,
                vatOperationTypeId: (!!r.vatOperationType)
                  ? r.vatOperationType.id+""
                  : null,
                priceVariations: r.priceVariations,
              };
            })),
            vatCollectability: $scope.invoice.vatCollectability,
          };

          $scope.loadingInvoice = false;
        }, function(error) {
          toastr.error("Impossibile caricare la fattura");
          $scope.loadingInvoice = false;
        });
      }, function(error) {
        toastr.error("Impossibile caricare i clienti");
      });
    } else {
      // Carico i dati dei clienti semplicemente se non sto modificando
      // una fattura.
      newApiClient.call(`
        query {
          companies {
            allCompanies {
              list(pagination: { take: 1000 }) {
                ${customerFragment}
              }
            }
          }
        }
      `).then(function(customersData) {
        $scope.customers = customersData.companies.allCompanies.list;
      }, function(error) {
        toastr.error("Impossibile caricare i clienti");
      });
    }

    // Alla modifica del cliente...
    $scope.$watch('data.customerId', function(newCustomerId, oldCustomerId) {
      if (newCustomerId !== '' && newCustomerId !== null && typeof newCustomerId !== 'undefined') {
        $scope.loading = true;

        // Se il cliente è diverso mi conviene tenere buona solo la data
        if (oldCustomerId != newCustomerId && oldCustomerId !== null) {
          $scope.data = {
            type: $scope.data.type,
            customerId: newCustomerId,
            date: $scope.data.date,
            paymentMethodId: null,
            paymentTermId: null,
            note: null,
            rows: [],
            vatCollectability: null,
          };
        }

        $scope.loading = false;
      }
    });

    $scope.currentCustomer = function() {
      return $scope.customers.find(x => x.id == $scope.data.customerId);
    };

    function isAvailableFor(invoiceType, proj) {
      var projectStatusTypes = [];
      switch (invoiceType) {
        case 'INVOICE':
        case 'PARCEL':
        case 'DEPOSIT_NOTE':
          projectStatusTypes = ['CLOSED'];
          break;
        case 'INVOICE_DEPOSIT':
        case 'PARCEL_DEPOSIT':
          projectStatusTypes = ['OPEN'];
          break;
      }
      return projectStatusTypes.indexOf(proj.status.type) != -1;
    }

    $scope.availableProjects = function() {
      const hiddenIds = $scope.data.rows.map(function(x) { return x.id; });
      const customer = $scope.currentCustomer();
      const invoiceType = $scope.data.type;
      if (customer == null)
        return [];
      return customer.billableProjects.list
        .filter(function(proj) {
          return hiddenIds.indexOf(proj.id) == -1 && isAvailableFor(invoiceType, proj);
        });
    };

    $scope.openAddModal = function() {
      $uibModal.open({
        templateUrl: 'templates/fatturatore/modal_add.html',
        size: 'lg',
        controller: 'ModalAddProjectToInvoiceCtrl',
        resolve: {
          title: function() { return 'Aggiunta progetti'; },
          availableProjects: $scope.availableProjects,
        },
      }).result.then(function (selectedProjects) {
        const toAdd = selectedProjects.map(function(proj) {
          return {
            ...proj,
            description: `${proj.folderCode} ${proj.title}`,
            amount: proj.billablePrice,
            quantity: proj.billableQuantity,
            vat: percentuale_iva,
            vatOperationTypeId: null,
            priceVariations: [],
          };
        });
        $scope.data.rows = orderRows([
          ...$scope.data.rows,
          ...toAdd,
        ]);

        // Se la lista dei progetti selezionati cambia, imposto
        // il metodo di pagamento e scadenza di pagamento in modo
        // che siano concordi con tutti i progetti, altrimenti li metto
        // in rosso per evidenziare che alcuni progetti non sono concordi.
        const customer = $scope.currentCustomer();

        const availablePaymentMethods = Array.from(new Set($scope.data.rows.filter(x => x.paymentInfo != null).map(function(x) {
          return x.paymentInfo.paymentMethod.id;
        })));
        $scope.data.paymentMethodId = (availablePaymentMethods.length === 1)
          ? availablePaymentMethods[0].toString()
          : customer.preferredPaymentMethod?.id?.toString() ?? null;

        const availablePaymentTerms = Array.from(new Set($scope.data.rows.filter(x => x.paymentInfo != null).map(function(x) {
          return x.paymentInfo.paymentTerm.id;
        })));
        $scope.data.paymentTermId = (availablePaymentTerms.length === 1)
          ? availablePaymentTerms[0].toString()
          : customer.preferredPaymentTerm?.id?.toString() ?? null;
      });
    };

    $scope.deselect = function(proj) {
      $scope.data.rows = $scope.data.rows.filter(function(x) { return x.id != proj.id; });
    };

    $scope.canSave = function() {
      return !$scope.data.rows.some(function(x) {
          return !$scope.hasValidPrice(x)
            || !$scope.hasValidVat(x)
            || !$scope.hasValidQuantity(x)
            || !$scope.hasValidVatOperationTypeId(x);
        }) &&
        !!$scope.data.type &&
        (
          (!!$scope.data.paymentMethodId && !!$scope.data.paymentTermId) ||
          (!$scope.data.paymentMethodId && !$scope.data.paymentTermId)
        ) &&
        !!$scope.data.date &&
        !!$scope.data.paymentMethodId &&
        !!$scope.data.paymentTermId &&
        $scope.data.rows.length > 0;
    };

    $scope.hasValidPrice = function(project) {
      return project.amount >= 0 && project.amount <= project.billablePrice;
    };
    $scope.hasValidVatOperationTypeId = function(project) {
      return project.vat != 0 || project.vatOperationTypeId != null;
    };
    $scope.hasValidVat = function(project) {
      return project.vat != null && project.vat >= 0;
    };
    $scope.hasValidQuantity = function(project) {
      return project.quantity != null && project.quantity >= 0 && project.quantity <= project.billableQuantity;
    };

    $scope.getAmount = function() {
      return $scope.data.rows.reduce(function(a, b) {
        return a + $scope.calculateActualRowPrice(b);
      }, 0);
    };

    $scope.getTax = function() {
      return $scope.data.rows.reduce(function(a, b) {
        return a + (Math.round($scope.calculateActualRowPrice(b) * b.vat) / 100);
      }, 0);
    };

    $scope.getTotal = function() {
      return $scope.getAmount() + $scope.getTax();
    };

    $scope.calculateActualRowPrice = function(progetto) {
      return progetto.priceVariations.reduce(function(state, variation) {
        return state + variation.amount;
      }, progetto.amount);
    };

    $scope.openAddPriceVariationModal = function(progetto) {
      $uibModal.open({
        templateUrl: 'templates/fatturatore/modal_add_variation.html',
        size: 'md',
        controller: 'ModalAddPriceVariationCtrl',
        resolve: {
          currentPrice: $scope.calculateActualRowPrice(progetto),
        },
      }).result.then(function (priceVariations) {
        progetto.priceVariations = progetto.priceVariations.concat(priceVariations);
      });
    };

    $scope.removeRowPriceVariation = function(progetto, index) {
      progetto.priceVariations.splice(index, 1);
    };

    $scope.save = function () {
      const input = {
        date: $scope.data.date,
        note: ($scope.data.note != '') ? $scope.data.note : null,
        paymentInfo: ($scope.data.paymentMethodId != null && $scope.data.paymentTermId != null)
          ? {
            paymentMethodId: parseInt($scope.data.paymentMethodId),
            paymentTermId: parseInt($scope.data.paymentTermId),
          }
          : null,
        recipientId: parseInt($scope.data.customerId),
        type: $scope.data.type,
        rows: $scope.data.rows.map(function(x) {
          return {
            projectId: x.id,
            description: x.description,
            price: x.amount,
            quantity: x.quantity,
            vat: x.vat,
            vatOperationTypeId: (x.vat > 0)
              ? null
              : parseInt(x.vatOperationTypeId),
            priceVariations: x.priceVariations,
          };
        }),
        vatCollectability: (!!$scope.data.vatCollectability)
          ? $scope.data.vatCollectability
          : null,
      };

      $scope.submitting = true;
      if (!$scope.isEdit) {
        newApiClient.call(`
          mutation($input: InvoiceInput!) {
            invoices {
              createInvoice(input: $input) {
                id
              }
            }
          }
        `, {
          input: input,
        }).then(function(data) {
          $scope.submitting = false;
          toastr.success("Fattura generata con successo");
          $state.go('elencofatture');
        }, function(errors) {
          $scope.submitting = false;
          toastr.error(errors[0].extensions.userMessage, "Impossibile creare la fattura");
        });
      } else {
        newApiClient.call(`
          mutation($invoiceId: Int!, $input: InvoiceInput!) {
            invoices {
              updateInvoice(invoiceId: $invoiceId, input: $input) {
                id
              }
            }
          }
        `, {
          invoiceId: parseInt($scope.invoiceId),
          input: input,
        }).then(function(data) {
          $scope.submitting = false;
          toastr.success("Fattura modificata con successo");
          $state.go('elencofatture');
        }, function(errors) {
          $scope.submitting = false;
          toastr.error(errors[0].extensions.userMessage, "Impossibile modificare la fattura");
        });
      }
    };
  }
