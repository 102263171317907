import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .controller('ModalExtraMoveCtrl', ModalExtraMoveCtrl);

  ModalExtraMoveCtrl.$inject = ['$scope', '$uibModalInstance'];

  function ModalExtraMoveCtrl($scope, $uibModalInstance) {
    $scope.data = {
      companyId: null,
      projectId: null,
      taskId: null,
    };
    $scope.save = function () {
      $uibModalInstance.close($scope.data);
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.$watch('data.companyId', function(newValue) {
      $scope.data.projectId = null;
      $scope.data.taskId = null;
    });

    $scope.$watch('data.projectId', function(newValue) {
      $scope.data.taskId = null;
    });
  }