import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .directive('vatNumber', function () {
    return {
      require: 'ngModel',
      link: function (scope, elm, attrs, ctrl) {
        ctrl.$validators.vatNumber = function (modelValue, viewValue) {
          // consider empty models to be valid
          if (ctrl.$isEmpty(modelValue)) {
            return true;
          }

          const regexes = [
            "^ATU[A-Z0-9]{9,9}$",
            "^BE[0-9]{10,10}$",
            "^BG[0-9]{9,10}$",
            "^CY[A-Z0-9]{9,9}$",
            "^CZ[0-9]{8,10}$",
            "^DE[0-9]{9,9}$",
            "^DK[0-9]{8,8}$",
            "^EE[0-9]{9,9}$",
            "^EL[0-9]{9,9}$",
            "^ES[A-Z0-9]{9,9}$",
            "^FI[0-9]{8,8}$",
            "^FR[A-Z0-9]{2,2}[0-9]{9,9}$",
            "^GB[0-9]{9,9}$|^GB[0-9]{12,12}$|^GBGD[0-9]{3,3}$|^GBHA[0-9]{3,3}$",
            "^HU[0-9]{8,8}$",
            "^IE[A-Z0-9]{8,8}$",
            "^IT[0-9]{11,11}$",
            "^LT[0-9]{9,9}$|^LT[0-9]{12,12}$",
            "^LU[0-9]{8,8}$",
            "^LV[0-9]{11,11}$",
            "^MT[0-9]{8,8}$",
            "^NL[A-Z0-9]{12,12}$",
            "^PL[0-9]{10,10}$",
            "^PT[0-9]{9,9}$",
            "^SE[0-9]{12,12}$",
            "^SI[0-9]{8,8}$",
            "^SK[0-9]{10,10}$",
          ];
          for (const regex of regexes) {
            if (modelValue.match(regex))
              return true;
          }

          return false;
        };
      }
    };
  });
