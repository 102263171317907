import * as angular from 'angular';
import * as moment from 'moment';
import { Convert } from '../../utils';

angular
  .module('screeningcanvas')
  .controller('ElencoFatturatoCtrl', ElencoFatturatoCtrl);

  ElencoFatturatoCtrl.$inject = ['$scope', 'newApiClient'];

  function ElencoFatturatoCtrl($scope, newApiClient) {

    $scope.ordinamento = '-netGain';
    $scope.filters = {
      fromDate: moment().startOf('year').format('YYYY-MM-DD'),
      toDate: moment().endOf('year').format('YYYY-MM-DD'),
      isResale: null,
    };

    $scope.drawPieChart = (customerReports) => {
      var copy = angular.copy(customerReports);
      copy.sort((a, b) => b.netGainPercentageOverall - a.netGainPercentageOverall);
      const top = copy.slice(0, 11);
      const rest = copy.slice(11);
      $scope.pieChart = {
        chartType: 'PieChart',
        dataTable: google.visualization.arrayToDataTable([
          ['Cliente', 'Percentage'],
          ...top.map(x => [
              x.customer.name,
              x.netGainPercentageOverall,
            ]),
          rest.reduce((acc, x) => [ acc[0], acc[1] + x.netGainPercentageOverall ], [ 'Altri', 0 ]),
        ]),
        options: {
          height: 500,
        },
      };
    };

    $scope.fetchTableData = () => {
      $scope.loading = true;
      newApiClient.call(`
        query($filter: StatsByCustomerFilter!) {
          stats {
            statsByCustomer(filter: $filter) {
              customer {
                id
                name
              }
              netTotal
              internalNetCost
              externalNetCost
              netCost
              netGain
              workedTime
            }
          }
        }
      `, {
        filter: {
          fromDate: $scope.filters.fromDate,
          toDate: $scope.filters.toDate,
          isResale: ($scope.filters.isResale === null || $scope.filters.isResale === "")
            ? null
            : ($scope.filters.isResale === "1"),
        },
      }).then(function(response) {
        $scope.loading = false;
        const customerReports = response.stats.statsByCustomer.map(r => ({
          ...r,
          workedHours: Convert.fromDurationToHours(r.workedTime),
          netTotalMinusExternalCosts: r.netTotal - r.externalNetCost,
        }));
        const netTotal = customerReports.reduce((acc, x) => acc + x.netTotal, 0);
        const internalNetCostTotal = customerReports.reduce((acc, x) => acc + x.internalNetCost, 0);
        const externalNetCostTotal = customerReports.reduce((acc, x) => acc + x.externalNetCost, 0);
        const netCostTotal = customerReports.reduce((acc, x) => acc + x.netCost, 0);
        const netGainTotal = customerReports.reduce((acc, x) => acc + x.netGain, 0);
        const netGainTotalOverall = customerReports.filter(x => x.netGain > 0).reduce((acc, x) => acc + x.netGain, 0);
        const netTotalMinusExternalCosts = netTotal - externalNetCostTotal;
        $scope.customerReports = customerReports.map(x => ({
          ...x,
          netGainPercentage: (x.netTotal != 0)
            ? (x.netGain * 100) / x.netTotal
            : 0,
          netGainPercentageOverall: (x.netGain > 0)
            ? (x.netGain * 100) / netGainTotalOverall
            : 0,
        }));

        $scope.somme = [
          {
            label: 'Guadagni',
            total: netGainTotal,
          },
          {
            label: 'F. Netto',
            total: netTotalMinusExternalCosts,
          },
          {
            label: 'Costi interni',
            total: internalNetCostTotal,
          },
          {
            label: 'Costi esterni',
            total: externalNetCostTotal,
          },
          {
            label: 'Costi',
            total: netCostTotal,
          },
          {
            label: 'Imponibile',
            total: netTotal,
          }
        ];

        $scope.drawPieChart($scope.customerReports);
      }, function(error) {
        $scope.loading = false;
        console.error(error);
      });
    };

    $scope.refresh = () => {
      $scope.fetchTableData();
    };

    $scope.refresh();
    $scope.$watch('filters.fromDate', (newValue, oldValue) => {
      if (newValue !== oldValue)
        $scope.refresh();
    });
    $scope.$watch('filters.toDate', (newValue, oldValue) => {
      if (newValue !== oldValue)
        $scope.refresh();
    });
    $scope.$watch('filters.isResale', (newValue, oldValue) => {
      if (newValue !== oldValue)
        $scope.refresh();
    });

  }
