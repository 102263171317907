import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .controller('ModalAddProjectToWaybillCtrl', ModalAddProjectToWaybillCtrl);

ModalAddProjectToWaybillCtrl.$inject = ['$scope', 'availableProjects', '$uibModalInstance'];

function ModalAddProjectToWaybillCtrl($scope, availableProjects, $uibModalInstance) {
  $scope.availableProjects = availableProjects;

  $scope.$on('modal.closing', function (event, reason, closed) {
    $scope.clear();
  });

  $scope.getSelected = function () {
    return $scope.availableProjects.filter(x => !!x.isSelected);
  };

  $scope.clear = function () {
    $scope.availableProjects.forEach(function (proj) {
      proj.isSelected = false;
    });
    $scope.availableProjects = [];
  };

  $scope.addAll = function () {
    const selectedProjects = $scope.getSelected();
    $scope.clear();
    $uibModalInstance.close(selectedProjects);
  };
}
