import * as angular from 'angular';

  angular
    .module('screeningcanvas')
    .controller('ModalEditTaskCtrl', ModalEditTaskCtrl);

    ModalEditTaskCtrl.$inject = ['$scope', 'data', '$uibModalInstance'];

    function ModalEditTaskCtrl($scope, data, $uibModalInstance) {
      $scope.data = data;
      $scope.save = function () {
        $uibModalInstance.close($scope.data);
      };
    }
