import {
  forwardRef,
  Component,
  OnInit,
  Inject,
  Input,
  OnChanges,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Apollo, gql } from 'apollo-angular';
import { Convert } from '../../../utils';
import { showApolloError } from '../../alert';

@Component({
  selector: 'task-selector',
  templateUrl: 'new/shared/components/task-selector.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR, 
      useExisting: forwardRef(() => TaskSelector), 
      multi: true
    }
  ]
})
export class TaskSelector implements OnInit, OnChanges {
  @Input() private optional: string;
  @Input() private projectId: number;

  private disabled: boolean = false;
  private selected: number = null;
  private items = [];

  private _onTouched: any = () => {}
  private _onChange: any = () => {}

  constructor(@Inject(Apollo) private apollo: Apollo) {}

  private onChange() {
    this._onChange(this.selected);
    this._onTouched();
  }

  writeValue(value: number) {
    this.selected = value;
    this.onChange();
  }

  registerOnChange(fn: () => any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  async reload() {
    try {
      const { data } = await this.apollo.query<any>({
        query: gql`
          query($filter: TaskFilter!, $order: TaskOrder!, $pagination: Pagination!) {
            tasks {
              allTasks {
                list(filter: $filter, order: $order, pagination: $pagination) {
                  value: id
                  label: name
                }
              }
            }
          }
        `,
        variables: {
          filter: {
            projectIds: Convert.toSingletonIntList(this.projectId),
          },
          order: 'DEADLINE_DESC',
          pagination: { skip: 0, take: 1000 },
        }
      }).toPromise();
      this.items = data.tasks.allTasks.list;
    } catch (error) {
      showApolloError(error);
    }
  }

  ngOnInit() { this.reload(); }
  ngOnChanges() { this.reload(); }
}