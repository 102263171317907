import * as moment from 'moment';

export const Random = {
    randomString: (length) => {
        var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }
};

export const Money = {
    allocate: (amount, weights) => {
        const totalWeights = weights.reduce((a, b) => a + b, 0);
        const perWeight = (amount * 100) / totalWeights;
        const allocated = weights.map(weight => Math.floor(weight * perWeight) / 100);
        const totalAllocated = allocated.reduce((a, b) => a + b, 0);
        const nonAllocated = amount - totalAllocated;
        allocated[allocated.length - 1] += nonAllocated;
        return allocated;
    }
};

export const Transaction = {
    generateFrom: (paymentTerm, date, amount) => {
        if (paymentTerm == null)
            return [];
        const paymentWeights = paymentTerm.payments.map(x => x.weight);
        const pricePerPayment = Money.allocate(amount, paymentWeights);
        return paymentTerm.payments.map((payment, i) => {
            const offsetMonths = Math.floor(payment.offset / 30);
            const offsetDays = payment.offset % 30;
            var baseDate = moment(date, 'YYYY-MM-DD').clone();
            if (payment.offsetOrigin == 'END_OF_MONTH') {
                baseDate
                    .endOf('month')
                    .add(offsetMonths, 'month')
                    .endOf('month')
                    .add(offsetDays, 'day');
            } else {
                baseDate
                    .add(offsetMonths, 'month')
                    .add(offsetDays, 'day');
            }
            const price = pricePerPayment[i];
            return { date: baseDate.format('YYYY-MM-DD'), amount: price };
        })
    },
};

export const Js = {

    isNull: input => input == null || typeof (input) == "undefined",

};

export const Convert = {

    toInt: input => (input != null && typeof (input) != "undefined" && !Number.isNaN(input) && input != "")
        ? parseInt(input)
        : null,

    toFloat: input => {
        if (typeof (input) == "number" && !Number.isNaN(input))
            return input;
        if (typeof (input) == "string" && input != "")
            return parseFloat(input);
        return null;
    },

    toNonEmptyString: input => (input != null && typeof (input) != "undefined" && !Number.isNaN(input) && input != "")
        ? "" + input
        : null,

    toSingletonIntList: input => (input != null && typeof (input) != "undefined" && !Number.isNaN(input) && input != "")
        ? [parseInt(input)]
        : null,

    toSingletonList: input => (input != null && typeof (input) != "undefined" && !Number.isNaN(input) && input != "")
        ? [input]
        : null,

    fromHoursToDuration: input => {
        if (input === null || typeof (input) == "undefined" || Number.isNaN(input) || input === "")
            return null;
        if (typeof (input) == 'string')
            input = parseFloat(input);
        const totalMinutes = Math.abs(input * 60);
        const minutes = totalMinutes % 60;
        const hours = Math.floor(input) % 24;
        const days = Math.floor(input / 24);
        return `${days.toString().padStart(1, '0')}:${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`;
    },
    fromDurationToHours: input => {
        if (input == null || typeof (input) == "undefined" || Number.isNaN(input) || input == "")
            return null;
        var re = new RegExp("([0-9]+):([0-9]+):([0-9]+):([0-9]+)");
        const match = re.exec(input);
        const days = parseInt(match[1]);
        const hours = parseInt(match[2]);
        const minutes = parseInt(match[3]);
        const seconds = parseInt(match[4]);
        return (days * 24) + hours + (((minutes * 60) + seconds) / (60 * 60));
    },
};

export function parseDuration(input) {
    const durationRegex = /^[\+\-]?([0-9]+):([0-9]+):([0-9]+):([0-9]+)/;
    const matches = input.match(durationRegex);
    const duration = moment.duration({
        days: parseInt(matches[1]),
        hours: parseInt(matches[2]),
        minutes: parseInt(matches[3]),
        seconds: parseInt(matches[4]),
    });
    return duration;
}