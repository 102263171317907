import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .filter('formatSignedFloat', function () {
    return function (input) {
      if (input != null && typeof input !== 'undefined') {
        return ((input >= 0) ? "+" : "") + (input.toFixed(2).replace('.', ','));
      } else {
        return null;
      }
    };
  });
