import * as angular from 'angular';
import * as moment from 'moment';
import toastr from 'toastr';

angular
  .module('screeningcanvas')
  .controller('VatSeparationCtrl', VatSeparationCtrl);

VatSeparationCtrl.$inject = ['$scope', 'newApiClient'];

function VatSeparationCtrl($scope, newApiClient) {
  $scope.loading = false;

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  $scope.years = [];
  for (var i = 2015; i <= currentYear; i++) {
    $scope.years.push(i);
  };
  $scope.months = [
    { id: 1, label: "Gennaio" },
    { id: 2, label: "Febbraio" },
    { id: 3, label: "Marzo" },
    { id: 4, label: "Aprile" },
    { id: 5, label: "Maggio" },
    { id: 6, label: "Giugno" },
    { id: 7, label: "Luglio" },
    { id: 8, label: "Agosto" },
    { id: 9, label: "Settembre" },
    { id: 10, label: "Ottobre" },
    { id: 11, label: "Novembre" },
    { id: 12, label: "Dicembre" },
  ];

  $scope.filters = {
    year: currentYear,
    month: currentMonth,
  };

  $scope.search = () => {
    newApiClient.call(`
        query($invoiceFilter: InvoiceFilter!, $purchaseInvoiceFilter: PurchaseInvoiceFilter!, $pagination: Pagination!) {
          invoices {
            allInvoices {
              list(filter: $invoiceFilter, pagination: $pagination) {
                id
                date
                yearlyCode
                recipient {
                  id
                  name
                }
                total {
                  grossTotal
                  netTotal
                  taxes
                }
              }
              total(filter: $invoiceFilter) {
                grossTotal
                netTotal
                taxes
              }
            }
          }
          purchaseInvoices {
            allPurchaseInvoices {
              list(filter: $purchaseInvoiceFilter, pagination: $pagination) {
                id
                date
                code
                supplier {
                  id
                  name
                }
                total {
                  grossTotal
                  netTotal
                  taxes
                }
              }
              total(filter: $purchaseInvoiceFilter) {
                grossTotal
                netTotal
                taxes
              }
            }
          }
        }
      `, {
      invoiceFilter: {
        fromDate: moment().clone()
          .year($scope.filters.year).month($scope.filters.month - 1)
          .startOf('month')
          .format('YYYY-MM-DD'),
        toDate: moment().clone()
          .year($scope.filters.year).month($scope.filters.month - 1)
          .endOf('month')
          .format('YYYY-MM-DD'),
      },
      purchaseInvoiceFilter: {
        fromDate: moment().clone()
          .year($scope.filters.year).month($scope.filters.month - 1)
          .startOf('month')
          .format('YYYY-MM-DD'),
        toDate: moment().clone()
          .year($scope.filters.year).month($scope.filters.month - 1)
          .endOf('month')
          .format('YYYY-MM-DD'),
      },
      pagination: {
        take: 10000,
        skip: 0,
      },
      order: 'YEARLY_CODE_DESC',
    }).then(function (data) {
      const salesInvoices = data.invoices.allInvoices.list.map(x => ({
        code: x.yearlyCode,
        date: x.date,
        company: x.recipient.name,
        grossTotal: x.total.grossTotal,
        netTotal: x.total.netTotal,
        taxes: x.total.taxes,
        isPurchaseInvoice: false,
      }));
      const purchaseInvoices = data.purchaseInvoices.allPurchaseInvoices.list.map(x => ({
        code: x.code,
        date: x.date,
        company: x.supplier?.name,
        grossTotal: -x.total.grossTotal,
        netTotal: -x.total.netTotal,
        taxes: -x.total.taxes,
        isPurchaseInvoice: true,
      }));
      $scope.invoices = [...salesInvoices, ...purchaseInvoices].sort(function (a, b) {
        return ((a.date).localeCompare(b.date)) * -1;
      });

      const salesTaxes = data.invoices.allInvoices.total.taxes;
      const purchaseTaxes = data.purchaseInvoices.allPurchaseInvoices.total.taxes;
      $scope.sommeDaVisualizzare = [];
      $scope.sommeDaVisualizzare.push({ label: 'IVA dovuta', total: salesTaxes - purchaseTaxes });
      $scope.sommeDaVisualizzare.push({ label: 'IVA acquisti', total: purchaseTaxes });
      $scope.sommeDaVisualizzare.push({ label: 'IVA vendita', total: salesTaxes });
      $scope.loading = false;
    }, function () {
      $scope.loading = false;
      toastr.error("Impossibile caricare le fatture");
    });
  };

  $scope.search();
}