import * as angular from 'angular';

// For some reason this is not working: import Editor from 'tui-editor';
import Editor from '../tui-editor-Editor-full';

angular
  .module('screeningcanvas')
  .directive('markdownViewer', [function ($parse) {
    return {
      restrict: 'E',
      require: '?ngModel',
      template: '<div class="markdown"></div>',
      replace: true,
      scope: {
        ngModel: '='
      },
      link: function (scope, element, attrs, ngModelCtrl) {
        var editor = Editor.factory({
          el: element[0],
          height: 'auto',
          viewer: true,
        });

        if (ngModelCtrl)
          ngModelCtrl.$formatters.push(function (value) {
            return editor.setValue(value);
          });
      }
    };
  }]);
