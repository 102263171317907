import * as angular from 'angular';
import * as moment from 'moment';
import toastr from 'toastr';

angular
  .module('screeningcanvas')
  .controller('NoteDiVariazioneNewCtrl', NoteDiVariazioneNewCtrl);

  NoteDiVariazioneNewCtrl.$inject = ['$scope', '$state', '$stateParams', '$uibModal', 'percentuale_iva', 'newApiClient'];

  function NoteDiVariazioneNewCtrl($scope, $state, $stateParams, $uibModal, percentuale_iva, newApiClient) {
    $scope.loading = false;

    $scope.creditNoteId = $stateParams.id;
    $scope.isEdit = !!$stateParams.id;

    $scope.availableInvoices = [];
    $scope.data = {
      invoiceTypeId: 4,
      customerId: null,
      date: moment().format('YYYY-MM-DD'),
      note: '',
      rows: [],
      paymentMethodId: null,
      paymentTermId: null,
    };

    var orderRows = function(rows) {
      var orderedRows = rows.sort(function(a, b) {
        var a0 = moment(a.row.invoice.date, 'YYYY-MM-DD').format('YYYY');
        var b0 = moment(b.row.invoice.date, 'YYYY-MM-DD').format('YYYY');
        if (a0 > b0) return -1;
        if (a0 < b0) return 1;

        var a1 = a.row.invoice.yearlyCode;
        var b1 = b.row.invoice.yearlyCode;
        if (a1 > b1) return -1;
        if (a1 < b1) return 1;

        var a2 = a.row.project.yearlyCode;
        var b2 = b.row.project.yearlyCode;
        if (a2 > b2) return -1;
        if (a2 < b2) return 1;
        return 0;
      });
      return orderedRows;
    };

    $scope.loading = true;
    newApiClient.call(`
      query($companyFilter: CompanyFilter!, $companyPagination: Pagination!, $invoiceFilter: InvoiceFilter!) {
        companies {
          allCompanies {
            list(filter: $companyFilter, pagination: $companyPagination) {
              id
              name
              invoices {
                count(filter: $invoiceFilter)
              }
            }
          }
        }
      }
    `, {
      companyFilter: {},
      companyPagination: { take: 1000 },
      invoiceFilter: {
        types: ["INVOICE", "INVOICE_DEPOSIT", "PARCEL", "PARCEL_DEPOSIT"],
        fromDate: moment().subtract(1, 'years').format('YYYY-MM-DD'),
      },
    }).then(function(data) {
      $scope.companies = data.companies.allCompanies.list.filter(function(customer) {
        return customer.invoices.count > 0;
      });

      if ($scope.isEdit) {
        // Se stiamo modificando una fattura, prima carico i
        // dati della fattura...
        $scope.loading = true;
        newApiClient.call(`
          query($id: Int!) {
            invoices {
              invoice(id: $id) {
                id
                yearlyCode
                date
                recipient {
                  id
                }
                paymentInfo {
                  paymentMethod { id }
                  paymentTerm { id }
                }
                rows {
                  invoice: referencedInvoice {
                    id
                    yearlyCode
                    date
                  }
                  project {
                    id
                    yearlyCode
                    title
                    administrativeNote
                  }
                  description
                  quantity
                  price
                  vat
                  vatOperationType {
                    id
                  }
                }
                note
              }
            }
          }
        `, {
          id: parseInt($scope.creditNoteId),
        }).then(function(data) {
          $scope.creditNote = data.invoices.invoice;

          // Passa la responsabilità di cambiare "loadingInvoice"
          // all'altro passaggio (dopo aver cambiato il ata.customerId).
          $scope.data.customerId = $scope.creditNote.recipient.id;
        }, function() {
          $scope.loading = false;
          toastr.error("Impossibile caricare i dati");
        });
      } else {
        $scope.loading = false;
        // A questo punto sarà l'utente a selezionare il cliente
        // e quello prenderà i progetti fatturabili.
      }
    }, function() {
      toastr.error("Impossibile caricare i dati");
    })

    // Alla modifica del cliente...
    $scope.$watch('data.customerId', function(newVal) {
      if (newVal !== '' && newVal !== null && typeof newVal !== 'undefined') {
        $scope.loading = true;
        newApiClient.call(`
          query ($invoiceFilter: InvoiceFilter!) {
            invoices {
              allInvoices {
                list(filter: $invoiceFilter) {
                  id
                  yearlyCode
                  date
                  rows {
                    project {
                      id
                      yearlyCode
                      folderCode
                      title
                    }
                    description
                    quantity
                    adjustedQuantity
                    price
                    adjustedPrice
                    vat
                    vatOperationType {
                      id
                    }
                  }
                }
              }
            }
          }
        `, {
          invoiceFilter: {
            types: ["INVOICE", "INVOICE_DEPOSIT", "PARCEL", "PARCEL_DEPOSIT"],
            fromDate: moment().subtract(1, 'years').format('YYYY-MM-DD'),
            recipientIds: [newVal],
          },
        }).then(function(data) {
          $scope.availableInvoices = data.invoices.allInvoices.list;

          const existingCreditNote = $scope.creditNote;
          if (!!existingCreditNote) {
            // Se la nota è per lo stesso cliente allora
            // posso importare i dati, altrimenti posso importarne solo
            // alcuni.
            if (existingCreditNote.recipient.id === newVal) {
              // Visto che sto modificando una nota di credito devo simulare come se le fatture
              // della nota non fossero state "modificate".
              // Poi se sono già selezionate, la modal provvederà a filtrarle prima di darle come
              // selezionabili.
              existingCreditNote.rows.forEach(function(row) {
                // Aggiungo alle fatture ciò che era stato tolto da questa
                // nota di credito (come se la nota di credito non fosse stata
                // fatta).
                $scope.availableInvoices = $scope.availableInvoices.map(function(invoice) {
                  if (invoice.id == row.invoice.id) {
                    return {
                      ...invoice,
                      rows: invoice.rows.map(function(innerRow) {
                        if (innerRow.project.id == row.project.id) {
                          return {
                            ...innerRow,
                            adjustedQuantity: innerRow.adjustedQuantity - row.quantity,
                            adjustedPrice: innerRow.adjustedPrice - row.price,
                          };
                        } else {
                          return innerRow;
                        }
                      }),
                    };
                  } else {
                    return invoice;
                  }
                });
              });

              // Se sto modificando una fattura, imposto i dati dalla
              // fattura caricata stando attento alla nuova realtà.
              $scope.data = {
                ...$scope.data,
                invoiceId: existingCreditNote.id,
                date: existingCreditNote.date,
                note: existingCreditNote.note,
                rows: orderRows(existingCreditNote.rows.map(function(row) {
                  const correspondingInvoice = $scope.availableInvoices.find(function(invoice) { return invoice.id == row.invoice.id; });
                  const correspondingRow = correspondingInvoice.rows.find(function(invoiceRow) { return invoiceRow.project.id == row.project.id });
                  return {
                    row: { ...correspondingRow, invoiceId: row.invoice.id, invoice: row.invoice },
                    projectId: row.project.id,
                    invoiceId: row.invoice.id,
                    description: row.description,
                    price: -row.price,
                    quantity: -row.quantity,
                    vat: row.vat,
                    vatOperationTypeId: (row.vat > 0)
                      ? null
                      : (!!row.vatOperationType) ? row.vatOperationType.id : null,
                  };
                })),
                paymentMethodId: (!!existingCreditNote.paymentInfo) ? ""+existingCreditNote.paymentInfo.paymentMethod.id : null,
                paymentTermId: (!!existingCreditNote.paymentInfo) ? ""+existingCreditNote.paymentInfo.paymentTerm.id : null,
              };
            } else {
              // Se il cliente è diverso mi conviene importare solo la
              // data.
              $scope.data = {
                ...$scope.data,
                note: '',
                rows: [],
                paymentMethodId: null,
                paymentTermId: null,
                date: existingCreditNote.date,
              };
            }

            // Dobbiamo ricordarci di fermare la rotellina di caricamento.
            $scope.loading = false;
          } else {
            // Se non sto modificando alcuna fattura lascio i dati di default.
          }

          $scope.loading = false;
        }, function() {
          toastr.error("Impossibile caricare i dati");
          $scope.loading = false;
        });
      }
    });

    $scope.getNonSelectedInvoices = function() {
      const invoices = $scope.availableInvoices.map(function(invoice) {
        return {
          ...invoice,
          rows: invoice.rows.filter(function(row) {
            const isSelected = !!$scope.data.rows.find(function(proj) {
              return proj.projectId == row.project.id && proj.invoiceId == invoice.id;
            });
            return !isSelected;
          }),
        };
      }).filter(function(invoice) { return invoice.rows.length > 0; });
      return invoices;
    };

    $scope.openAddModal = function() {
      $uibModal.open({
        templateUrl: 'templates/notedicredito/modal_add.html',
        size: 'lg',
        controller: 'ModalAddProjectToCreditNoteCtrl',
        resolve: {
          availableInvoices: function () { return $scope.getNonSelectedInvoices(); },
        },
      }).result.then(function (selectedRows) {
        const toAdd = selectedRows.map(function(row) {
          return {
            row: row,
            projectId: row.project.id,
            invoiceId: row.invoice.id,
            description: `${row.project.folderCode} ${row.project.title}`,
            price: null,
            quantity: null,
            vat: percentuale_iva,
            vatOperationTypeId: null,
          };
        });
        $scope.data.rows = orderRows([
          ...$scope.data.rows,
          ...toAdd,
        ]);
      });
    };

    $scope.deselect = function(proj) {
      $scope.data.rows = $scope.data.rows.filter(function(x) {
        return !(x.projectId == proj.projectId && x.invoiceId == proj.invoiceId);
      });
    };

    $scope.canSave = function() {
      return !$scope.data.rows.some(function(x) {
        return !$scope.hasValidPrice(x)
          || !$scope.hasValidVat(x)
          || !$scope.hasValidQuantity(x)
          || !$scope.hasValidVatOperationTypeId(x);
      })
        && !!$scope.data.date
        && $scope.data.paymentMethodId
        && $scope.data.paymentTermId
        && $scope.data.rows.length > 0;
    };

    $scope.hasValidPrice = function(row) {
      return row.price != null && (row.price >= 0) && ((row.row.adjustedPrice - row.price) >= 0);
    };
    $scope.hasValidVatOperationTypeId = function(project) {
      return project.vat != 0 || project.vatOperationTypeId != null;
    };
    $scope.hasValidVat = function(row) {
      return row.vat != null && row.vat >= 0;
    };
    $scope.hasValidQuantity = function(row) {
      return row.quantity != null && (row.quantity >= 0) && ((row.row.adjustedQuantity - row.quantity) >= 0);
    };

    $scope.getAmount = function() {
      return $scope.data.rows.reduce(function(a, b) {
        return a + b.price;
      }, 0);
    };

    $scope.getTax = function() {
      return $scope.data.rows.reduce(function(a, b) {
        return a + (Math.round(b.price * b.vat) / 100);
      }, 0);
    };

    $scope.getTotal = function() {
      return $scope.getAmount() + $scope.getTax();
    };

    $scope.asInput = function() {
      return {
        date: $scope.data.date,
        note: (!!$scope.data.note && $scope.data.note.length > 0)
          ? $scope.data.note
          : null,
        recipientId: parseInt($scope.data.customerId),
        type: 'CREDIT_NOTE',
        paymentInfo: {
          paymentMethodId: parseInt($scope.data.paymentMethodId),
          paymentTermId: parseInt($scope.data.paymentTermId),
        },
        rows: $scope.data.rows.map(row => ({
          description: row.description,
          price: -row.price,
          quantity: -row.quantity,
          priceVariations: [],
          referencedInvoiceId: parseInt(row.invoiceId),
          projectId: parseInt(row.projectId),
          vat: row.vat,
          vatOperationTypeId: (row.vat > 0)
            ? null
            : parseInt(row.vatOperationTypeId),
        })),
      };
    };

    $scope.save = function () {
      const input = $scope.asInput();
      $scope.submitting = true;
      if (!$scope.isEdit) {
        newApiClient.call(`
          mutation($input: InvoiceInput!) {
            invoices {
              createInvoice(input: $input) {
                id
              }
            }
          }
        `, {
          input: input,
        }).then(function(data) {
          $scope.submitting = false;
          toastr.success("Nota di credito generata con successo");
          $state.go('elencofatture');
        }, function(errors) {
          $scope.submitting = false;
          toastr.error(errors[0].extensions.userMessage, "Impossibile creare la nota di credito");
        });
      } else {
        newApiClient.call(`
          mutation($id: Int!, $input: InvoiceInput!) {
            invoices {
              updateInvoice(invoiceId: $id, input: $input) {
                id
              }
            }
          }
        `, {
          id: parseInt($stateParams.id),
          input: input,
        }).then(function(data) {
          $scope.submitting = false;
          toastr.success("Nota di credito modificata con successo");
          $state.go('elencofatture');
        }, function(errors) {
          $scope.submitting = false;
          toastr.error(errors[0].extensions.userMessage, "Impossibile modificare la nota di credito");
        });
      }
    };
  }
