import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .filter('formatEstimateTaskUnitSuffix', function () {
    return function (input) {
      switch (input) {
        case 'YEAR': return '/anno';
        case 'MONTH': return '/mese';
        case 'WEEK': return '/settimana';
        default: return '';
      }
    };
  });
