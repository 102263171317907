import * as angular from 'angular';
import * as moment from 'moment';

angular
  .module('screeningcanvas')
  .directive('datePicker', function () {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attrs, ngModelCtrl) {
        $(element[0])
          .datetimepicker({
            locale: 'it',
            showTodayButton: true,
            format: "DD/MM/YYYY",
          })
          .on("dp.change", function (e) {
            var date = element.val();
            scope.$apply(function () {
              ngModelCtrl.$setViewValue(date);
            });
          });

        ngModelCtrl.$formatters.push(function (value) {
          if (value === null) return null;
          const mnt = moment(value, 'YYYY-MM-DD');
          if (!mnt.isValid()) return null;
          return mnt.format('DD/MM/YYYY');
        });

        ngModelCtrl.$parsers.push(function (value) {
          if (value === null) return null;
          const mnt = moment(value, 'DD/MM/YYYY');
          if (!mnt.isValid()) return null;
          return mnt.format('YYYY-MM-DD');
        });
      }
    };
  })
  .directive('dateTimePicker', function () {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attrs, ngModelCtrl) {
        $(element[0])
          .datetimepicker({
            locale: 'it',
            showTodayButton: true,
            format: "DD/MM/YYYY HH:mm:ss",
          })
          .on("dp.change", function (e) {
            var date = element.val();
            scope.$apply(function () {
              ngModelCtrl.$setViewValue(date);
            });
          });

        ngModelCtrl.$formatters.push(function (value) {
          if (value === null) return null;
          return moment(value).format('DD/MM/YYYY HH:mm:ss');
        });

        ngModelCtrl.$parsers.push(function (value) {
          if (value === null) return null;
          return moment(value, 'DD/MM/YYYY HH:mm:ss').toISOString();
        });
      }
    };
  });
