import * as angular from 'angular';
import * as moment from 'moment';
import toastr from 'toastr';
import { Convert } from '../../utils';

angular
  .module('screeningcanvas')
  .controller('TimesheetCtrl', TimesheetCtrl);

TimesheetCtrl.$inject = ['$scope', 'newApiClient', 'clientStorage'];

function TimesheetCtrl($scope, newApiClient, clientStorage) {
  $scope.loading = false;
  const filtersCookieName = 'timesheet_filters';
  const filtriBase = {
    companyId: null,
    fromDate: moment().startOf('month').format('YYYY-MM-DD'),
    toDate: moment().endOf('month').format('YYYY-MM-DD'),
  };
  $scope.filtri = clientStorage.get(filtersCookieName) || angular.copy(filtriBase);
  $scope.projects = [];
  $scope.pdfFile = null;

  $scope.canSearch = () => ($scope.filtri.fromDate != null && $scope.filtri.toDate != null && $scope.filtri.companyId != null);

  $scope.search = function () {
    if (!$scope.canSearch())
      return null;

    $scope.loading = true;
    newApiClient.call(`
      mutation($input: CreateTimesheetInput!, $billableTimeFilter: ActivityFilter!) {
        projects {
          createTimesheet(input: $input) {
            pdfFile
            projects {
              id
              date
              folderCode
              title
              customer {
                id
                name
              }
              activities {
                billableTime: totalTime(filter: $billableTimeFilter)
              }
            }
          }
        }
      }
    `, {
      input: {
        fromDate: $scope.filtri.fromDate,
        toDate: $scope.filtri.toDate,
        customerId: Convert.toInt($scope.filtri.companyId),
      },
      billableTimeFilter: {
        isBillable: true,
        from: moment($scope.filtri.fromDate, 'YYYY-MM-DD').startOf('day').toISOString(),
        to: moment($scope.filtri.toDate, 'YYYY-MM-DD').add(1, 'day').startOf('day').toISOString(),
      },
    }).then(function (data) {
      $scope.loading = false;
      clientStorage.put(filtersCookieName, $scope.filtri);
      $scope.projects = data.projects.createTimesheet.projects.map(p => ({
        ...p,
        billableHours: Convert.fromDurationToHours(p.activities.billableTime),
      }));
      $scope.projects.sort((p1, p2) => p2.billableHours - p1.billableHours);
      $scope.pdfFile = data.projects.createTimesheet.pdfFile;
      $scope.totalBillableHours = $scope.projects.reduce((acc, p) => acc + p.billableHours, 0);
    }, function (errors) {
      toastr.error(errors[0].message, "Errore!");
    });
  };

  $scope.$watch('filtri.companyId', function () { $scope.search(); });
  $scope.$watch('filtri.fromDate', function () { $scope.search(); });
  $scope.$watch('filtri.toDate', function () { $scope.search(); });

}