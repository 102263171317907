import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .filter('formatDecimal', function () {
    return function (input) {
      if (input != null && typeof input !== 'undefined') {
        return input.toFixed(2).replace('.', ',');
      } else {
        return null;
      }
    };
  });