import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .directive('estimatePriceUnitSelector', function () {
    return {
      restrict: 'A',
      template: '<option value="{{ item.id }}" ng-repeat="item in items">{{ item.name }}</option>',
      replace: false,
      controller: ['$scope', function ($scope) {
        $scope.items = [
          { id: 'ONCE', name: '/una tantum' },
          { id: 'ITEM', name: '/unità' },
          { id: 'YEAR', name: '/anno' },
          { id: 'MONTH', name: '/mese' },
          { id: 'WEEK', name: '/settimana' },
          { id: 'HOUR', name: '/ora' },
        ];
      }],
    };
  });
