import * as angular from 'angular';
import * as moment from 'moment';
import toastr from 'toastr';

angular
  .module('screeningcanvas')
  .directive('filtersCardButtonsVm', ['Utility', '$cookieStore', function (Utility, $cookieStore) {
    return {
      restrict: 'E',
      template: `
          <div class="btn-group">
            <button type="submit" class="btn btn-primary" ng-click="vm.cerca('buttonClick')" ng-if="!vm.loading"><i class="fa fa-fw fa-search"></i> Cerca</button>
            <button type="button" class="btn btn-default" ng-click="" ng-if="vm.loading" disabled><i class="fa fa-fw fa-gear fa-spin"></i> Cerco...</button>
            <button type="button" class="btn btn-default" ng-click="vm.resetFilters()"><i class="fa fa-eraser"></i> Pulisci</button>
          </div>
        `,
      link: function (scope, elem, attrs) {
      }
    };
  }]);
