import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .directive('progress', function () {
    return {
      restrict: 'A',
      scope: {
        progressPercentage: '=progressPercentage',
      },
      link: function (scope, element, attrs) {
        element.css("transition", "background 0.5s");
        // We use an image because background-position doesn't work with
        // fill colors.
        // The alternative would be to use a gradient, but gradients don't yet
        // support transitions/animations.
        // So the SVG used here is a simple square with a fill color.
        element.css("background-image", "url(img/progress/color.svg)");
        element.css("background-repeat-x", "no-repeat");
        element.css("background-repeat-y", "repeat");
        element.css("background-position", "left center");
        scope.$watch('progressPercentage', function (newVal) {
          element.css("background-size", `${newVal}%`);
        }, true);
      }
    };
  });
