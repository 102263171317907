import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .controller('ModalChiusuraCtrl', ModalChiusuraCtrl);

ModalChiusuraCtrl.$inject = ['$scope', '$uibModalInstance', 'statusType'];

function ModalChiusuraCtrl($scope, $uibModalInstance, statusType) {
  $scope.statusType = statusType;
  $scope.save = function () {
    $uibModalInstance.close({});
  };
  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };
}
