import * as angular from 'angular';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'alert-modal',
  templateUrl: 'new/shared/components/alert-modal.component.html',
})
export class AlertModal {
  @Input() title: string;
  @Input() body: string;
  @Input() confirmText: string = "Conferma";
  @Input() cancelText: string = "Annulla";

  @Output() onConfirm = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  constructor(@Inject(BsModalRef) private modalRef: BsModalRef) {}

  onConfirmClick() {
    this.onConfirm.emit();
    this.modalRef.hide();
  }

  onCancelClick() {
    this.onCancel.emit();
    this.modalRef.hide();
  }
}

angular.module('screeningcanvas').directive('alertModal', downgradeComponent({ component: AlertModal }));