import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .filter('formatBoolean', function ($sce) {
    return function (input) {
      if (input) {
        return '<i class="font-green-jungle font-lg fa fa-check-circle"></i>';
      } else {
        return '<i class="font-red font-lg fa fa-times-circle"></i>';
      }
    };
  });
