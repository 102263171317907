import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .controller('ModalAddPriceVariationCtrl', ModalAddPriceVariationCtrl);

  ModalAddPriceVariationCtrl.$inject = ['$scope', 'currentPrice', '$uibModalInstance'];

  function ModalAddPriceVariationCtrl($scope, currentPrice, $uibModalInstance) {
    $scope.clear = function() {
      $scope.currentPrice = null;
      $scope.multiplier = "-1";
      $scope.label = "";
      $scope.amount = null;
    };

    $scope.clear();
    $scope.currentPrice = currentPrice;

    $scope.$on('modal.closing', function (event, reason, closed) {
      $scope.clear();
    });

    $scope.calculateNewPrice = function() {
      if (!!$scope.amount) {
        return $scope.currentPrice + ($scope.amount * parseInt($scope.multiplier));
      } else {
        return $scope.currentPrice;
      }
    };

    $scope.hasNonMatchingPriceSign = () => (
      Math.sign($scope.currentPrice) !== Math.sign($scope.calculateNewPrice())
        && Math.sign($scope.calculateNewPrice()) !== 0
    );

    $scope.canSave = function() {
      return (!!$scope.label && !!$scope.amount && !$scope.hasNonMatchingPriceSign());
    }

    $scope.save = function() {
      $uibModalInstance.close({
        label: $scope.label,
        amount: $scope.amount * parseInt($scope.multiplier),
      });
    };
  }
