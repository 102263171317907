import * as angular from 'angular';
import toastr from 'toastr';
import { Convert } from '../../utils';

angular.module('screeningcanvas')
.controller('ClienteNewCtrl', ['$scope', 'newApiClient', '$state', function ($scope, newApiClient, $state) {
  $scope.nuovocliente = {
    name: null,
    billingAddress: {
      countryId: null,
      regionId: null,
      zip: null,
      city: null,
      streetAddress: null,
    },
    taxCode: null,
    vatNumber: null,
    bank: null,
    iban: null,
    phone: null,
    fax: null,
    email: null,
    pec: null,
    digitalInvoiceChannel: null,
    website: null,
    note: null,
    preferredPaymentMethodId: null,
    preferredPaymentTermId: null,
    paymentDateOffsetId: null,
    purchaseInvoiceCategoryId: null,
  };
  $scope.loading = false;
  
  $scope.salvaCliente = function() {
    $scope.loading = true;
    newApiClient.call(`
      mutation($input: CreateCompanyInput!) {
        companies {
          createCompany(input: $input) {
            id
          }
        }
      }`,
      { 
        input: {
          name: Convert.toNonEmptyString($scope.nuovocliente.name),
          billingAddress: (!!$scope.nuovocliente.billingAddress.streetAddress) 
            ? {
              countryId: Convert.toInt($scope.nuovocliente.billingAddress.countryId),
              regionId: Convert.toInt($scope.nuovocliente.billingAddress.regionId),
              zip: Convert.toNonEmptyString($scope.nuovocliente.billingAddress.zip),
              city: Convert.toNonEmptyString($scope.nuovocliente.billingAddress.city),
              streetAddress: Convert.toNonEmptyString($scope.nuovocliente.billingAddress.streetAddress),
            }
            : null,
          taxCode: Convert.toNonEmptyString($scope.nuovocliente.taxCode),
          vatNumber: Convert.toNonEmptyString($scope.nuovocliente.vatNumber),
          bank: Convert.toNonEmptyString($scope.nuovocliente.bank),
          iban: Convert.toNonEmptyString($scope.nuovocliente.iban),
          phone: Convert.toNonEmptyString($scope.nuovocliente.phone),
          fax: Convert.toNonEmptyString($scope.nuovocliente.fax),
          email: Convert.toNonEmptyString($scope.nuovocliente.email),
          pec: Convert.toNonEmptyString($scope.nuovocliente.pec),
          digitalInvoiceChannel: Convert.toNonEmptyString($scope.nuovocliente.digitalInvoiceChannel),
          website: Convert.toNonEmptyString($scope.nuovocliente.website),
          note: Convert.toNonEmptyString($scope.nuovocliente.note),
          preferredPaymentMethodId: Convert.toInt($scope.nuovocliente.preferredPaymentMethodId),
          preferredPaymentTermId: Convert.toInt($scope.nuovocliente.preferredPaymentTermId),
          paymentDateOffsetId: Convert.toInt($scope.nuovocliente.paymentDateOffsetId),
          purchaseInvoiceCategoryId: Convert.toInt($scope.nuovocliente.purchaseInvoiceCategoryId),
        } 
      },
    ).then(function(data) {
      $scope.nuovocliente = {};
      $scope.loading = false;
      toastr.success("Anagrafica salvata con successo!");
      $state.go('companies');
    }, function(errors) {
      toastr.error(errors[0].extensions.userMessage, "Impossibile creare l'anagrafica");
    });
  };

  $scope.isAddressEmpty = function(address) {
    if (!address)
      return true;
    return !address.city
      && !address.zip
      && !address.countryId
      && !address.regionId
      && !address.streetAddress
  };
}]);
