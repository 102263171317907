import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .filter('projectUnit', function () {
    return function (input) {
      switch (input) {
        case 'HOUR': return 'ora';
        case 'ITEM': return 'pz.';
      }
    };
  });
