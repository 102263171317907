import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .controller('ModalProjectQuestionCtrl', ModalProjectQuestionCtrl);

  ModalProjectQuestionCtrl.$inject = ['$scope', '$uibModalInstance', 'title', 'question'];

  function ModalProjectQuestionCtrl($scope, $uibModalInstance, title, question) {
    $scope.title = title;
    $scope.question = question;
    $scope.save = function () {
      $uibModalInstance.close({});
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }
  