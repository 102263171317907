import * as angular from 'angular';
import toastr from 'toastr';

angular
  .module('screeningcanvas')
  .controller('EstimateBlocksCtrl', EstimateBlocksCtrl);

  EstimateBlocksCtrl.$inject = ['$scope', '$uibModal', 'newApiClient'];

  function EstimateBlocksCtrl($scope, $uibModal, newApiClient) {
    $scope.projectTypes = [];

    $scope.search = async () => {
      $scope.loading = true;
      const data = await newApiClient.call(`
        query {
          projectTypes {
            allProjectTypes {
              id
              name
            }
          }
        }`,
      );
      $scope.$apply(() => {
        $scope.loading = false;
        $scope.projectTypes = data.projectTypes.allProjectTypes;
      });
    };

    $scope.openNewProjectTypeModal = async () => {
      try {
        const data = await $uibModal.open({
          templateUrl: 'templates/projecttypes/modal_edit_type.html',
          controller: 'ModalEditProjectType',
          resolve: {
            title: function() { return 'Creazione tipologia'; },
            defaults: function() { return {}; },
          },
        }).result;

        await newApiClient.call(`
          mutation($input: CreateProjectTypeInput!) {
            projectTypes {
              createProjectType(input: $input) {
                id
              }
            }
          }`,
          {
            input: {
              name: data.name,
            }
          }
        );

        toastr.success('Tipologia progetto creata!');
        await $scope.search();
      } catch (error) {
        console.error(error);
      }
    };

    $scope.loading = true;
    $scope.search();

    $scope.initEdit = projectType => {
      projectType._isEditing = true;
    };

    $scope.saveEdit = async projectType => {
      try {
        const response = await newApiClient.call(`
          mutation($input: UpdateProjectTypeInput!) {
            projectTypes {
              updateProjectType(input: $input) {
                id
                name
              }
            }
          }`,
          {
            input: {
              projectTypeId: projectType.id,
              name: projectType.name,
            }
          }
        );
        projectType.name = response.projectTypes.updateProjectType.name;
        toastr.success('Modifiche salvate!');
      } catch (errors) {
        console.error(errors);
        toastr.error('Qualcosa è andato storto!');
      } finally {
        $scope.$apply(() => projectType._isEditing = false);
      }
    };
  }