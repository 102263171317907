import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .directive('autoHeight', function () {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        const el = element.get(0);
        el.style.resize = 'none';

        function updateHeight() {
          const minHeight = 32;
          const el = element.get(0);
          el.style.height = '1px';
          el.style.height = Math.max(el.scrollHeight, minHeight) + "px";
        }

        attrs.$set('ngTrim', 'false');
        scope.$watch(attrs['ngModel'], updateHeight);
      }
    };
  });
