import {
  forwardRef,
  Component,
  OnInit,
  Inject,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Apollo, gql } from 'apollo-angular';
import { showApolloError } from '../../alert';

@Component({
  selector: 'user-area-selector',
  templateUrl: 'new/shared/components/user-area-selector.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR, 
      useExisting: forwardRef(() => UserAreaSelector), 
      multi: true
    }
  ]
})
export class UserAreaSelector implements OnInit {
  private disabled: boolean = false;
  private areas: any[] = [];
  private selected: number[] = [];

  private _onTouched: any = () => {}
  private _onChange: any = () => {}

  get value(): number[] {
    return this.selected;
  }

  set value(val: number[]) {
    this.selected = val;
    this._onChange(val);
    if (val) {
      this._onTouched();
    }
  }

  writeValue(value: number[]) {
    this.value = value;
    this.areas = this.areas.map(a => ({
      ...a,
      isChecked: value.some(x => x == a.id),
    }));
  }

  registerOnChange(fn: () => any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onCheckboxChange(e) {
    const value = parseInt(e.target.value);
    const isChecked = e.target.checked;
    if (isChecked && !this.value.some(id => id == value)) {
      this.value = [...this.value, value];
    } else {
      this.value = this.value.filter(id => id != value);
    }
  }

  constructor(@Inject(Apollo) private apollo: Apollo) {}

  async ngOnInit() {
    try {
      const { data } = await this.apollo.query<any>({
        query: gql`
          query {
            areas {
              allAreas {
                id
                name
              }
            }
          }
        `
      }).toPromise();
      this.areas = data.areas.allAreas.map(a => ({
        ...a,
        isChecked: this.selected.some(x => x == a.id),
      }));
    } catch (error) {
      showApolloError(error);
    }
  }
}