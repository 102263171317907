import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { DefaultOptions, InMemoryCache } from '@apollo/client/core';

export function apolloServiceFactory(httpLink: HttpLink) {
  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
  };
  return {
    cache: new InMemoryCache({
      // Type policies for singleton objects in the GraphQL schema.
      // Reference: https://www.apollographql.com/docs/react/caching/cache-configuration/#generating-unique-identifiers
      typePolicies: {
        ActivityQueries: { keyFields: [] },
        AreaQueries: { keyFields: [] },
        AuthQueries: { keyFields: [] },
        CompanyQueries: { keyFields: [] },
        CountryQueries: { keyFields: [] },
        DigitalInvoiceQueries: { keyFields: [] },
        ActivityTypeQueries: { keyFields: [] },
        InvoiceQueries: { keyFields: [] },
        PaymentDateOffsetQueries: { keyFields: [] },
        PaymentMethodQueries: { keyFields: [] },
        PaymentTermQueries: { keyFields: [] },
        PriceVariationQueries: { keyFields: [] },
        ProjectQueries: { keyFields: [] },
        ProjectTypeQueries: { keyFields: [] },
        RegionQueries: { keyFields: [] },
        PurchaseInvoiceQueries: { keyFields: [] },
        PurchaseInvoiceCategoryQueries: { keyFields: [] },
        StoredFileQueries: { keyFields: [] },
        TaskQueries: { keyFields: [] },
        UserQueries: { keyFields: [] },
        UserRoleQueries: { keyFields: [] },
        VatOperationTypeQueries: { keyFields: [] },
        SalesQuotationQueries: { keyFields: [] },
        TransactionQueries: { keyFields: [] },
        TransactionTagQueries: { keyFields: [] },
        WaybillQueries: { keyFields: [] },
        StatsQueries: { keyFields: [] },

        ActivityMutations: { keyFields: [] },
        AreaMutations: { keyFields: [] },
        AuthMutations: { keyFields: [] },
        CompanyMutations: { keyFields: [] },
        DigitalInvoiceMutations: { keyFields: [] },
        EstimatePartMutations: { keyFields: [] },
        EstimatePriceMutations: { keyFields: [] },
        EstimateTaskMutations: { keyFields: [] },
        InvoiceMutations: { keyFields: [] },
        ProjectMutations: { keyFields: [] },
        ProjectContractMutations: { keyFields: [] },
        ProjectAttachmentMutations: { keyFields: [] },
        ProjectTypeMutations: { keyFields: [] },
        ProjectExpenseMutations: { keyFields: [] },
        PurchaseInvoiceMutations: { keyFields: [] },
        TaskMutations: { keyFields: [] },
        TransactionMutations: { keyFields: [] },
        UserMutations: { keyFields: [] },
        SalesQuotationMutations: { keyFields: [] },
        WaybillMutations: { keyFields: [] },
      }
    }),
    link: httpLink.create({ uri: '/graphql' }),
    defaultOptions: defaultOptions,
  };
}

export const apolloProvider = {
  provide: APOLLO_OPTIONS,
  useFactory: apolloServiceFactory,
  deps: [HttpLink],
}