import * as angular from 'angular';
import * as moment from 'moment';
import toastr from 'toastr';
import { parseDuration, Convert } from '../../utils';

angular
  .module('screeningcanvas')
  .controller('LavoriInCorsoCtrl', LavoriInCorsoCtrl);

LavoriInCorsoCtrl.$inject = ['$scope', 'User', 'newApiClient', '$uibModal'];

function groupBy(list, keyFn) {
  return list.reduce(function(map, x) {
    (map[keyFn(x)] = map[keyFn(x)] || []).push(x);
    return map;
  }, {});
}

function serializeWeek(deadline) {
  if (!deadline)
    return '';
  const m = moment(deadline);
  return `${m.isoWeekYear()}-${m.isoWeek()}`;
}

function serializedWeekInfo(serializedWeek) {
  const split = serializedWeek.split('-');
  if (split.length == 2) {
    const isoWeekYear = parseInt(split[0]);
    const isoWeek = parseInt(split[1]);
    const baseMoment = moment().isoWeekYear(isoWeekYear).isoWeek(isoWeek);
    return {
      isoWeekYear: isoWeekYear,
      isoWeek: isoWeek,
      weekStart: baseMoment.clone().isoWeekday(1),
      weekEnd: baseMoment.clone().isoWeekday(6),
    };
  } else {
    return null;
  }
}

function LavoriInCorsoCtrl($scope, User, newApiClient, $uibModal)
{
  $scope.user = User.getUser();
  $scope.filters = { assigneeId: $scope.user.id.toString() };

  $scope.refresh = (filters, loading) =>
  {
    $scope.loading = loading;
    newApiClient.call(`
      query($userId: Int!, $filter: TaskFilter!, $pagination: Pagination!) {
        users {
          user(id: $userId) {
            assignedTasks {
              list(filter: $filter, order: DEADLINE_ASC, pagination: $pagination) {
                id
                name
                estimatedTime
                activities {
                  totalTime
                }
                deadline
                project {
                  id
                  yearlyCode
                  folderCode
                  createdAt
                  title
                  customer {
                    id
                    name
                  }
                }
                assignedArea { id }
                assignedUser { id }
              }
            }
          }
        }
      }
    `, {
      userId: (filters.assigneeId != null) ? parseInt(filters.assigneeId) : null,
      filter: { isCompleted: false, shouldBeCompleted: true },
      pagination: { skip: 0, take: 1000 },
    }).then(function(data) {
      const grouped = groupBy(data.users.user.assignedTasks.list, t => serializeWeek(t.deadline));
      $scope.taskGroups = Object.keys(grouped).sort().map(k => ({
        weekInfo: serializedWeekInfo(k),
        tasks: grouped[k],
      }));
      $scope.loading = false;
    }, function() {
      $scope.loading = false;
      toastr.error("Errore di caricamento");
    });
  };

  $scope.$watch('filters', function(newVal) {
    $scope.refresh(newVal, true);
  }, true);


  $scope.remainingTimePercentage = (workedTime, estimatedTime) =>
  {
    return (parseDuration(workedTime).asMinutes() * 100) / parseDuration(estimatedTime).asMinutes();
  };

  $scope.daysToDeadline = (deadline) =>
  {
    const line = moment(deadline);
    const now = moment();
    const diff = moment.duration(line.diff(now));
    return Math.ceil(diff.asDays());
  };

  $scope.remainingTime = (workedTime, estimatedTime) =>
  {
    const worked = parseDuration(workedTime);
    const total = parseDuration(estimatedTime);
    const diff = total.clone().subtract(worked);
    return Math.ceil(diff.asHours());
  };

  $scope.textColorForDeadline = (deadline) =>
  {
    const red = '#c0392b';
    const yellow = '#f39c12';
    const base = '#27ae60';
    if (!deadline || $scope.daysToDeadline(deadline) < 0)
      return red;
    if (!!deadline && $scope.daysToDeadline(deadline) < 5)
      return yellow;
    return base;
  };

  $scope.textColorForRemainingTime = (workedTime, estimatedTime) =>
  {
    const red = '#c0392b';
    const yellow = '#f39c12';
    const base = '#27ae60';
    if ($scope.remainingTime(workedTime, estimatedTime) <= 0)
      return red;
    if ($scope.remainingTime(workedTime, estimatedTime) < 8)
      return yellow;
    return base;
  };

  $scope.formatWeekPeriod = (weekInfo) =>
  {
    if (!weekInfo)
      return 'Deadline mancanti';

    function formatDate(mmnt) {
      return (mmnt.year() == moment().year())
        ? mmnt.format('DD MMM')
        : mmnt.format('DD MMM \'YY')
    }

    const start = formatDate(weekInfo.weekStart);
    const end = formatDate(weekInfo.weekEnd);
    return `Settimana ${start} - ${end}`;
  };

  $scope.openEditModal = (task) =>
  {
    $uibModal.open({
      templateUrl: 'templates/lavoriincorso/modal_edit.html',
      controller: 'ModalEditTaskCtrl',
      resolve: {
        data: function () {
          return {
            name: task.name,
            estimatedHours: Math.ceil(parseDuration(task.estimatedTime).asHours()),
            deadline: task.deadline,
            assignedAreaId: task.assignedArea.id,
            assignedUserId: task.assignedUser.id,
          };
        }
      },
    }).result.then(function (data) {
      newApiClient.call(`
        mutation($input: UpdateTaskInput!) {
          tasks {
            updateTask(input: $input) {
              id
            }
          }
        }
      `, {
        input: {
          taskId: task.id,
          name: data.name,
          estimatedTime: Convert.fromHoursToDuration(data.estimatedHours),
          deadline: data.deadline,
          assignedAreaId: data.assignedAreaId,
          assignedUserId: data.assignedUserId,
        }
        }).then(function(data) {
        $scope.refresh($scope.filters, false);
      }, function() {
        toastr.error("Impossibile modificare task");
      });
    });
  };

  $scope.openCompleteModal = (task) =>
  {
    $uibModal.open({
      templateUrl: 'templates/lavoriincorso/modal_complete.html',
      controller: 'ModalCompleteTaskCtrl',
      resolve: {},
    }).result.then(function (data) {
      newApiClient.call(`
        mutation($id: Int!) {
          tasks {
            toggleCompleteTask(taskId: $id) {
              id
            }
          }
        }
      `, { id: task.id }).then(function(data) {
        $scope.refresh($scope.filters, false);
      }, function() {
        toastr.error("Impossibile segnare task come completato");
      });
    });
  };
}