import * as angular from 'angular';
import toastr from 'toastr';

angular
  .module('screeningcanvas')
  .directive('countrySelector', ['newApiClient', function (newApiClient) {
    return {
      restrict: 'A',
      template: '<option value="">Seleziona...</option><option value="{{ item.id }}" ng-repeat="item in items">{{ item.englishName }}</option>',
      replace: false,
      controller: ['$scope', function ($scope) {
        $scope.items = [];
        newApiClient.call(`
          query {
            countries {
              allCountries {
                id
                englishName
              }
            }
          }`,
          {},
        ).then(function (data) {
          $scope.items = data.countries.allCountries;
        }, function () {
          toastr.error("Impossibile caricare le nazioni");
        });
      }],
    };
  }]);
