import * as angular from 'angular';
import { Convert } from '../../utils';

angular
  .module('screeningcanvas')
  .controller('ModalEditTransactionPayedCtrl', ModalEditTransactionPayedCtrl);

  ModalEditTransactionPayedCtrl.$inject = ['$scope', 'data', '$uibModalInstance'];

  function ModalEditTransactionPayedCtrl($scope, data, $uibModalInstance) {
    $scope.data = {
      paymentDate: data.paymentDate,
      tagId: Convert.toNonEmptyString(data.tagId),
      note: data.note,
      paidAt: data.paidAt,
      isDefaulted: data.isDefaulted,
    };

    $scope.$on('modal.closing', function (event, reason, closed) {
      $scope.clear();
    });

    $scope.clear = function() {
      $scope.data = null;
    };

    $scope.save = function () {
      const data = $scope.data;
      $scope.clear();
      $uibModalInstance.close({
        paidAt: data.paidAt,
        tagId: Convert.toInt(data.tagId),
        note: Convert.toNonEmptyString(data.note),
        isDefaulted: data.isDefaulted,
      });
    };
  }
