import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .directive('projectStatus', function () {
    return {
      restrict: 'E',
      scope: {
        finito: '=',
        incorso: '=',
        dafare: '=',
        total: '='
      },
      template: `
          <div class="project-status">
            <div class="finito" style="width: {{finito.perc}}%" uib-tooltip="Completato {{finito.num}}/{{total}} - {{finito.perc}}%"></div>
            <div class="in-corso" style="width: {{incorso.perc}}%" uib-tooltip="In corso {{incorso.num}}/{{total}} - {{incorso.perc}}%"></div>
            <div class="da-fare" style="width: {{dafare.perc}}%" uib-tooltip="Da fare {{dafare.num}}/{{total}} - {{dafare.perc}}%"></div>
          </div>
        `
    };
  });
