import * as angular from 'angular';
import toastr from 'toastr';
import { Convert } from '../utils';

angular
  .module('screeningcanvas')
  .directive('regionSelector', ['newApiClient', function (newApiClient) {
    return {
      restrict: 'A',
      scope: {
        countryId: '=',
      },
      template: '<option value="">{{ optionalText }}</option><option value="{{ item.id }}" ng-repeat="item in items">{{ item.name }}</option>',
      replace: false,
      controller: ['$scope', '$element', function ($scope, $element) {
        $scope.items = [];
        $scope.optionalText = "Nessuna provincia";

        $scope.fetch = function (countryId) {
          if (!countryId)
            return;
          newApiClient.call(`
            query($filter: RegionFilter!) {
              regions {
                allRegions(filter: $filter) {
                  id
                  name
                }
              }
            }`,
            { 'filter': { 'countryIds': [Convert.toInt(countryId)] } },
          ).then(function (data) {
            $scope.items = data.regions.allRegions;
          }, function () {
            toastr.error("Impossibile caricare le provincie");
          });
        };
        $scope.fetch($scope.countryId);

        $scope.$watch('countryId', function (newVal) {
          $scope.fetch(newVal);
        });

        $scope.$watch('items', function (newVal) {
          if (newVal.length > 0) {
            $scope.optionalText = "Seleziona...";
            $element.prop("disabled", false);
          } else {
            $scope.optionalText = "Nessuna provincia";
            $element.prop("disabled", true);
          }
        });
      }],
    };
  }]);
