import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .controller('ModalEditTicketCtrl', ModalEditTicketCtrl);

ModalEditTicketCtrl.$inject = ['$scope', 'data', '$uibModalInstance', 'title'];

function ModalEditTicketCtrl($scope, data, $uibModalInstance, title) {
  $scope.title = title;

  $scope.data = {
    ...data,
    customerId: (!!data.customerId)
      ? data.customerId + ""
      : null,
  };
  $scope.save = function () {
    $uibModalInstance.close({
      ...$scope.data,
      customerId: parseInt($scope.data.customerId),
    });
  };
}
