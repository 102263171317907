import {
  ElementRef,
  forwardRef,
  OnDestroy,
  Component,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as datetimepicker from 'eonasdan-bootstrap-datetimepicker';
import * as moment from 'moment';

@Component({
  selector: 'date-time-picker',
  templateUrl: 'new/shared/components/date-time-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR, 
      useExisting: forwardRef(() => DateTimePicker), 
      multi: true
    }
  ]
})
export class DateTimePicker implements AfterViewInit, OnDestroy {
  // We need to maintain this value as a separate entity
  // as opposed to `.date` on the picker because the value of this
  // component is set before the datetimepicker is initialised.
  private cachedValue: moment.Moment;
  
  @ViewChild("datePicker")
  private elementRef: ElementRef;
  private datepickerElement: JQuery = null;
  private datepicker: datetimepicker.Datetimepicker = null;
  
  private disabled: boolean = false;
  private isInitialized: boolean = false;

  private _onTouched: any = () => {}
  private _onChange: any = () => {}

  get value(): moment.Moment {
    return this.cachedValue;
  }

  set value(val: moment.Moment) {
    this.cachedValue = val;
    this._onChange(val);
    if (val) {
      this._onTouched();
    }
  }

  writeValue(value: moment.Moment) {
    this.value = value;
    if (this.isInitialized) {
      this.datepicker.date(value);
    }
  }

  registerOnChange(fn: () => any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngAfterViewInit() {
    this.isInitialized = true;
    this.datepickerElement = $(this.elementRef.nativeElement);
    this.datepickerElement.datetimepicker({
      locale: 'it',
      showTodayButton: true,
      format: "DD/MM/YYYY HH:mm:ss",
    });
    this.datepicker = this.datepickerElement.data('DateTimePicker');
    this.datepicker.date(this.value);
    this.datepickerElement.on('dp.change', (e) => {
      if (e.date !== this.value) {
        this.value = e.date;
      }
    });
  }

  ngOnDestroy(): void {
    this.datepicker.destroy();
  }
}