import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .filter('projectStatusType', function () {
    return function (input) {
      switch (input) {
        case 'OPEN': return 'Aperto';
        case 'CLOSED': return 'Chiuso';
        case 'SUSPENDED': return 'Sospeso';
      }
    };
  });
