import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .controller('ProjectsModalScheduleCtrl', ProjectsModalScheduleCtrl);

  ProjectsModalScheduleCtrl.$inject = ['$scope', '$uibModalInstance', 'project'];

  function ProjectsModalScheduleCtrl($scope, $uibModalInstance, project) {
    $scope.project = angular.copy(project);
    $scope.project.estimateParts.forEach(part => part.tasks.forEach(x => x._isSelected = true));

    $scope.getSelected = () => {
      return $scope.project.estimateParts.reduce((acc, part) =>
        acc.concat(part.tasks
          .filter(x => x._isSelected)), []);
    };

    $scope.save = function () {
      $uibModalInstance.close({ estimateTasks: $scope.getSelected() });
    };
    $scope.skip = function () {
      $uibModalInstance.close({ taskIds: []});
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }