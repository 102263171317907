import { Component, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import * as angular from 'angular';

@Component({
  selector: 'summary-bar',
  templateUrl: 'new/shared/components/summary-bar.component.html',
})
export class SummaryBar {
  @Input() items: Array<SummaryBarItem> = [];
}

interface SummaryBarItem {
  label: string;
  amount: number;
  prefix?: string;
}

angular.module('screeningcanvas').directive('summaryBar', downgradeComponent({ 
  component: SummaryBar,
  inputs: ['items'], 
}));