import * as angular from 'angular';
import toastr from 'toastr';

angular
  .module('screeningcanvas')
  .controller('ExtrasListCtrl', ExtrasListCtrl);

  ExtrasListCtrl.$inject = ['$scope', '$uibModal', 'newApiClient'];

  function ExtrasListCtrl($scope, $uibModal, newApiClient) {
    $scope.list = null;
    $scope.info = {
      loading: false,
    };
    $scope.filters = {
      isBillable: true,
      customerId: null,
      userId: null,
      query: null,
    };
    $scope.paginator = {
      page: 0,
      perPage: 50,
    };

    $scope.search = function(page = 0, loading = false) {
      page = (page == null) ? $scope.paginator.page : page;
      const baseFilters = {
        isExtra: true,
        isBillable: $scope.filters.isBillable,
        query: $scope.filters.query,
        customerIds: (!!$scope.filters.customerId)
          ? [parseInt($scope.filters.customerId)]
          : null,
        userIds: (!!$scope.filters.userId)
          ? [parseInt($scope.filters.userId)]
          : null,
      };

      $scope.info.loading = loading;
      newApiClient.call(`
        query(
          $filter: ActivityFilter!,
          $billableFilters: ActivityFilter!,
          $nonBillableFilters: ActivityFilter!,
          $order: ActivityOrder!,
          $pagination: Pagination!
        ) {
          activities {
            allActivities {
              list(filter: $filter, order: $order, pagination: $pagination) {
                id
                description
                startsAt
                endsAt
                duration
                isBillable
                company: parent {
                  ... on Company {
                    id
                    name
                  }
                }
                type {
                  id
                  label
                }
                user {
                  id
                  fullName
                }
              }
              billableCount: count(filter: $billableFilters)
              nonBillableCount: count(filter: $nonBillableFilters)
            }
          }
        }
      `, {
        filter: baseFilters,
        billableFilters: {
          ...baseFilters,
          isBillable: true,
        },
        nonBillableFilters: {
          ...baseFilters,
          isBillable: false,
        },
        order: 'STARTS_AT_DESC',
        pagination: {
          take: $scope.paginator.perPage,
          skip: $scope.paginator.perPage * page,
        },
      }).then(function(data) {
        $scope.billableCount = data.activities.allActivities.billableCount;
        $scope.nonBillableCount = data.activities.allActivities.nonBillableCount;

        $scope.list = data.activities.allActivities.list.map(x => ({
          ...x,
          isSelected: false,
        }));
        $scope.info.loading = false;

        $scope.paginator.page = page;
      }, function() {
        $scope.info.loading = false;
        toastr.error("Impossibile caricare le attività extra");
      });
    };

    $scope.selectedCount = () => (!!$scope.list)
      ? $scope.list.filter((p) => p.isSelected).length
      : 0;

    $scope.selectedTotalCount = () => ($scope.filters.isBillable)
      ? $scope.billableCount
      : $scope.nonBillableCount;

    $scope.pagesCount = () => Math.ceil($scope.selectedTotalCount() / $scope.paginator.perPage);

    $scope.openSetBillableModal = function(isBillable) {
      var selectedIds = $scope.list.filter((p) => p.isSelected).map((p) => p.id);
      $uibModal.open({
        templateUrl: 'templates/extras/modal_set_billable.html',
        controller: 'ModalExtraSetBillable',
        resolve: {
          isBillable: () => isBillable,
        },
      }).result.then(function (data) {
        newApiClient.call(`
          mutation($input: UpdateActivityBatchInput!) {
            activities {
              updateActivityBatch(input: $input) {
                id
              }
            }
          }
        `, {
          input: {
            activityIds: selectedIds,
            isBillable: isBillable,
          },
        }).then(function(data) {
          toastr.success('Attività modificate correttamente!');
          $scope.search(null, true);
        }, function() {
          toastr.error("Non è stato possibile modificare le attività");
        });
      });
    };

    $scope.openMoveModal = function() {
      var selectedIds = $scope.list.filter((p) => p.isSelected).map((p) => p.id);
      $uibModal.open({
        templateUrl: 'templates/extras/modal_move.html',
        controller: 'ModalExtraMoveCtrl',
        resolve: {},
      }).result.then(function (data) {
        newApiClient.call(`
          mutation($input: UpdateActivityBatchInput!) {
            activities {
              updateActivityBatch(input: $input) {
                id
              }
            }
          }
        `, {
          input: {
            activityIds: selectedIds,
            parent: {
              taskId: parseInt(data.taskId),
            }
          },
        }).then(function(data) {
          toastr.success('Attività spostate correttamente!');
          $scope.search(null, true);
        }, function() {
          toastr.error("Non è stato possibile spostare le attività");
        });
      });
    };

    $scope.openEditModal = function(extra) {
      $uibModal.open({
        templateUrl: 'templates/extras/modal_edit.html',
        controller: 'ModalExtraEdit',
        resolve: {
          data: () => ({
            typeId: extra.type.id,
            isBillable: extra.isBillable,
          }),
        },
      }).result.then(function (input) {
        newApiClient.call(`
          mutation($input: UpdateActivityInput!) {
            activities {
              updateActivity(input: $input) {
                id
              }
            }
          }
        `, {
          input: {
            activityId: extra.id,
            typeId: input.typeId,
            isBillable: input.isBillable,
          },
        }).then(function(data) {
          toastr.success('Attività modificata!');
          $scope.search(null, false);
        }, function() {
          toastr.error("Errore!");
        });
      });
    };

    $scope.$watch('filters', function(newValue, oldValue) {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        $scope.search();
      }
    }, true);

    $scope.search(0, true);
  }