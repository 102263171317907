import * as angular from 'angular';
import * as moment from 'moment';

angular
  .module('screeningcanvas')
  .filter('formatDateTime', function () {
    return function (input) {
      if (!!input) {
        return moment(input).format('DD/MM/YYYY HH:mm');
      } else {
        return null;
      }
    };
  });
