import * as angular from 'angular';

function removeHtml(str) {
  var html = str;
  var doc = new DOMParser().parseFromString(html, 'text/html');
  html = doc.body.textContent || "";
  return html;
}

function removeHtmlExtension(editor) {
  editor.eventManager.listen('convertorAfterHtmlToMarkdownConverted', markdown => {
    return removeHtml(markdown);
  });
}

// For some reason this is not working: import Editor from 'tui-editor';
import Editor from '../tui-editor-Editor-full';

Editor.defineExtension('removeHtml', removeHtmlExtension);

angular
  .module('screeningcanvas')
  .directive('markdownEditor', [function ($parse) {
    return {
      restrict: 'E',
      require: '?ngModel',
      template: '<div class="markdown"></div>',
      replace: true,
      scope: {
        ngModel: '='
      },
      link: function (scope, element, attrs, ngModelCtrl) {
        var editor = new Editor({
          el: element[0],
          initialEditType: 'wysiwyg',
          previewStyle: 'vertical',
          height: 'auto',
          minHeight: '60px',
          hideModeSwitch: false,
          exts: ['removeHtml'],
          toolbarItems: [
            'heading',
            'bold',
            'italic',
            'strike',
            'divider',
            'hr',
            'quote',
            'divider',
            'ul',
            'ol',
            // 'task',
            // 'indent',
            // 'outdent',
            'divider',
            'table',
            'image',
            // 'link',
            'divider',
            'code',
            'codeblock'
          ],
          events: {
            change: function () {
              if (ngModelCtrl)
                ngModelCtrl.$setViewValue(editor.getValue());
            }
          }
        });

        if (ngModelCtrl)
          ngModelCtrl.$formatters.push(function (value) {
            return editor.setValue(value);
          });
      }
    };
  }]);
