import * as angular from 'angular';
import { Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { Apollo, gql } from 'apollo-angular';
import { UIRouterState, User } from '../../ajs-upgraded-providers';
import { showApolloError } from '../../alert';

@Component({
  selector: 'layout',
  templateUrl: 'new/shared/components/layout.component.html',
})
export class Layout implements OnInit {
  private user: any = null;

  private menu: any = [
    {
      title: 'Lavori in corso',
      sref: 'lavoriincorso',
      iconClass: 'fa fa-list-ol'
    }, 
    {
      title: 'Dashboard',
      sref: 'dashboard',
      iconClass: 'fa fa-dashboard'
    }, 
    {
      title: 'Progetti',
      iconClass: 'fa fa-th-list',
      submenu: [
        {
          title: 'Progetti',
          sref: 'progetti'
        }, {
          title: 'Ticket',
          sref: 'tickets'
        }, {
          title: 'Extra',
          sref: 'extras',
        }, {
          title: 'Lavori',
          sref: 'projectsinprogress',
        }, {
          title: 'Chiusura veloce',
          sref: 'fatturazioneveloce',
        }
      ]
    }, 
    {
      title: 'Gestione',
      iconClass: 'fa fa-gear',
      submenu: [
        {
          title: 'Gestione Tipologie',
          sref: 'projecttypeslist'
        }, {
          title: 'Gestione Aree',
          sref: 'gestionearee'
        }, {
          title: 'Gestione Anagrafiche',
          sref: 'companies'
        }, {
          title: 'Gestione Utenti',
          sref: 'users'
        },
      ]
    }, 
    {
      title: 'Amministrazione',
      iconClass: 'fa fa-euro',
      submenu: [
        {
          title: 'Previsioni',
          sref: 'previsioni'
        }, {
          title: 'Scadenziario',
          sref: 'scadenzario'
        }, {
          title: 'Costi Fornitori',
          sref: 'costifornitori'
        },
        {
          title: 'Report ore',
          sref: 'timesheet'
        },
        {
          title: 'Scorporamento IVA',
          sref: 'vatseparation',
        }
      ]
    }, 
    {
      title: 'DDT e fatture',
      iconClass: 'fa fa-file-text-o',
      submenu: [
        {
          title: 'Elenco DDT',
          sref: 'elencoddt'
        }, {
          title: 'Nuovo DDT',
          sref: 'createwaybill'
        }, {
          title: 'Elenco fatture di vendita',
          sref: 'elencofatture'
        }, {
          title: 'Nuova fattura di vendita',
          sref: 'fatturatore'
        }, {
          title: 'Nuova nota di credito',
          sref: 'notedicreditonew'
        }, {
          title: 'Elenco fatture di acquisto',
          sref: 'elencofattureacquisto'
        }, {
          title: 'Nuova fattura di acquisto',
          sref: 'fatturaacquisto_new'
        }, {
          title: 'Scarica fatture',
          sref: 'digitalinvoicedownloader'
        },
      ]
    }, 
    {
      title: 'Stats',
      iconClass: 'fa fa-line-chart',
      submenu: [
        {
          title: 'Ore lavorate utenti',
          sref: 'utenti_orelavorate'
        }, {
          title: 'Fatturato annuale',
          sref: 'elencofatturatoperanno'
        }, {
          title: 'Fatturato clienti',
          sref: 'elencofatturato'
        }, {
          title: 'Analisi composizione fatturato',
          sref: 'analisicomposizionefatturato'
        }
      ]
    }
  ];

  constructor(
    @Inject(User) private User, 
    @Inject(UIRouterState) private $state, 
    @Inject(Apollo) private apollo: Apollo
  ) {}

  toggleOpenLi(li) {
    li.isOpen = !li.isOpen;
  }

  async signOut() {
    try {
      await this.apollo.mutate({
        mutation: gql`
          mutation {
            auth {
              signOut
            }
          }
        `,
      }).toPromise();
      this.User.destroy();
      this.$state.go('login');
    } catch (error) {
      showApolloError(error);
    }
  }

  async ngOnInit() {
    try {
      const { data } = await this.apollo.query<any>({
        query: gql`
          {
            auth {
              viewer {
                id
                username
                avatar
                role {
                  id
                }
              }
            }
          }
        `,
        fetchPolicy: 'cache-first',
      }).toPromise();
      this.user = data.auth.viewer;
      if (!this.user) {
        this.User.destroy();
        this.$state.go('login');
      }
      this.menu.map((item) => {
        item.isOpen = false;
        item.isActive = false;
        item.userIsGrantedToView = true;

        if (!!item.sref) {
          const data = this.$state.get(item.sref).data;
          if (!!data.allowAccessToUserRoles) {
            item.userIsGrantedToView = data.allowAccessToUserRoles.includes(this.user.role.id);
          }
        }
    
        if (!!item.submenu) {
          let isCurrent = false;

          item.submenu.map((subitem) => {
            subitem.userIsGrantedToView = true;
            if (!!subitem.sref) {
              const currentName = this.$state.current.name;
              const subitemName = this.$state.get(subitem.sref).name;
              if (currentName === subitemName) {
                isCurrent = true;
              }

              const data = this.$state.get(subitem.sref).data;
              if (!!data.allowAccessToUserRoles) {
                subitem.userIsGrantedToView = data.allowAccessToUserRoles.includes(this.user.role.id);
              }
            }
          });
          item.submenu = item.submenu.filter(x => x.userIsGrantedToView);

          if (isCurrent) {
            item.isOpen = true;
          }
        }
    
        return item;
      });
      this.menu = this.menu.filter(x => x.userIsGrantedToView && (!x.submenu || x.submenu.length > 0));
    } catch (error) {
      showApolloError(error);
    }
  }
}

angular.module('screeningcanvas').directive('layout', downgradeComponent({ component: Layout }));