import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .directive('formatPrice', function () {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attrs, ngModelCtrl) {
        ngModelCtrl.$formatters.push(function (value) {
          return (!!value)
            ? value.toFixed(2).toString().replace(/\./, ',')
            : null;
        });
        ngModelCtrl.$parsers.push(function (value) {
          return parseFloat(value.replace(/,/, '.'));
        });
      }
    };
  });
