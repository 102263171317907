import * as angular from 'angular';
import Inputmask from 'inputmask';

angular
  .module('screeningcanvas')
  .directive('quantityMask', function () {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attrs, ngModelCtrl) {
        Inputmask("decimal", {
          radixPoint: ",",
          digits: 2,
          digitsOptional: true,
          allowPlus: true,
          allowMinus: false,
        }).mask(element);

        ngModelCtrl.$formatters.push(function (value) {
          return (!!value || value === 0)
            ? value.toFixed(2).toString().replace(/\./, ',')
            : null;
        });
        ngModelCtrl.$parsers.push(function (value) {
          if (/(\ ,|,\ |^,|^$)/.test(value))
            return null;
          return parseFloat(value.replace(/,/, '.').replace(/\ |,/g, ''));
        });
      }
    };
  });
