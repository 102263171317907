import * as angular from 'angular';
import * as moment from 'moment';
import toastr from 'toastr';
import { Convert } from '../../utils';

angular
  .module('screeningcanvas')
  .controller('FatturazioneVeloceCtrl', FatturazioneVeloceCtrl);

  FatturazioneVeloceCtrl.$inject = ['$scope', '$uibModal', 'newApiClient'];

  function FatturazioneVeloceCtrl($scope, $uibModal, newApiClient) {
    $scope.progetti = null;
    $scope.info = {
      allSelected: false,
      loading: false,
    };
    $scope.filters = {
      query: null,
      clienteId: null,
    };
    $scope.loadedCount = 0;

    const projectFragment = `
      id
      status {
        type
        updatedAt
      }
      folderCode
      title
      customer {
        name
      }
      price
      quantity
      invoiceReferences {
        id
      }
      waybillReferences {
        id
      }
    `;

    $scope.search = function(loading = true) {
      $scope.loadMore(loading, false);
    };

    $scope.loadMore = function(loading = false, append = true) {
      const filter = {
        statusTypes: ['OPEN'],
        hasWaybills: false,
        maxPrice: 0.0,
        query: Convert.toNonEmptyString($scope.filters.query),
        customerIds: Convert.toSingletonIntList($scope.filters.clienteId),
      };
      const perLoadListSize = 50;
      const pagination = {
        take: perLoadListSize,
        skip: (append) ? $scope.loadedCount : 0,
      };

      $scope.info.loading = loading;
      newApiClient.call(`
        query($filter: ProjectFilter!, $pagination: Pagination!) {
          projects {
            allProjects {
              list(filter: $filter, pagination: $pagination) {
                ${projectFragment}
              }
              count(filter: $filter)
            }
          }
        }`,
        { filter: filter, pagination: pagination, },
      ).then(function(data) {
        $scope.count = data.projects.allProjects.count;
        $scope.info.allSelected = false;
        const loadedProjects = data.projects.allProjects.list.map((p) => ({
          ...p,
          isSelected: false,
        }));
        if (append) {
          $scope.loadedCount += loadedProjects.length;
          $scope.progetti = $scope.progetti.concat(loadedProjects);
        } else {
          $scope.progetti = loadedProjects;
          $scope.loadedCount = loadedProjects.length;
        }
        $scope.info.loading = false;
      }, function () {
        $scope.info.loading = false;
        toastr.error("Impossibile caricare i progetti");
      });
    };

    $scope.$watch('info.allSelected', function(newValue) {
      if (!$scope.progetti) return;
      if (newValue) {
        $scope.progetti.forEach((p) => { p.isSelected = true; });
      } else {
        $scope.progetti.forEach((p) => { p.isSelected = false; });
      }
    });

    $scope.selectedCount = () => (!!$scope.progetti)
      ? $scope.progetti.filter((p) => p.isSelected).length
      : 0;

    $scope.openSetModal = function() {
      var selectedIds = $scope.progetti.filter((p) => p.isSelected).map((p) => p.id);
      $uibModal.open({
        templateUrl: 'templates/fatturazioneveloce/modal_fattura.html',
        controller: 'ModalFatturaEasyCtrl',
        resolve: {},
      }).result.then(function (data) {
        newApiClient.call(`
          mutation($ids: [Int!]!, $input: BatchUpdateProjectInput!) {
            projects {
              batchUpdateProjects(ids: $ids, input: $input) {
                ${projectFragment}
              }
            }
          }`,
          { 
            ids: selectedIds, 
            input: { 
              status: {
                type: 'CLOSED',
                date: moment().format('YYYY-MM-DD'),
              } 
            } 
          },
        ).then(function(data) {
          toastr.success('Progetti aggiornati');
          const newProjects = data.projects.batchUpdateProjects;
          $scope.progetti.forEach(listProject => {
            const newProject = newProjects.find(x => x.id == listProject.id);
            if (!!newProject) {
              angular.extend(listProject, newProject);
            }
            listProject.isSelected = false;
          });
          setTimeout(function(){
            $scope.search(false);
          }, 1000);
        }, function () {
          $scope.info.loading = false;
          toastr.error("Impossibile modificare i progetti");
        });
      });
    };

    $scope.search();
  }