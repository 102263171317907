import * as angular from 'angular';
import * as moment from 'moment';

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

angular
  .module('screeningcanvas')
  .controller('ElencoFatturatoPerAnnoCtrl', ElencoFatturatoPerAnnoCtrl);

  ElencoFatturatoPerAnnoCtrl.$inject = ['$scope', 'newApiClient', 'sogliaFatturato'];

  function ElencoFatturatoPerAnnoCtrl($scope, newApiClient, sogliaFatturato) {
    $scope.resetFilters = () => {
      $scope.filters = {
        fromDate: moment().year(2016).startOf('year').format('YYYY-MM-DD'),
        toDate: moment().endOf('year').format('YYYY-MM-DD'),
        isResale: null,
      };
    };
    $scope.resetFilters();

    $scope.formatYAxis = function(d) {
      return (d / 1000).toFixed(2) + 'k';
    };

    $scope.formatXAxis = function(d) {
      return moment().month(d).format('MMM');
    };

    $scope.chartOptions = {
      chart: {
        type: "multiBarChart",
        width: 1100,
        height: 400,
        yAxis: { axisLabel: 'Ammontare', tickFormat: $scope.formatYAxis },
        xAxis: { axisLabel: 'Mese', tickFormat: $scope.formatXAxis, showMaxMin: false, ticks: 24, },
        showLegend: true,
        showControls: false,
        noData: "Non ci sono dati a disposizione",
        stacked: true,
      },
    };

    function getCharts(monthlyReports) {
      const years = Array.from(new Set(monthlyReports.map((x) => x.year)))
        .reverse();

      return years.map(year => {
        var dataTable = new google.visualization.DataTable();
        dataTable.addColumn('string', 'Month');
        dataTable.addColumn({
          type: 'string',
          role: 'tooltip',
          'p': { 'html': true }
        });
        dataTable.addColumn('number', 'Soglia');
        dataTable.addColumn('number', 'F. Netto');
        dataTable.addColumn('number', 'Costi');
        dataTable.addRows(
          monthlyReports
            .filter((rep) => rep.year == year)
            .map(x => [
              capitalize(moment(`${x.year}-${x.month}`, 'YYYY-MM').format('MMM')),
              '<div style="padding: 12px; font-size: 14px; white-space: nowrap;">' +
                '<strong>' + capitalize(moment(`${x.year}-${x.month}`, 'YYYY-MM').format('MMMM YYYY')) + '</strong>' +
                '<hr>' +
                'Fatturato: &euro; <strong>' + Number(x.netTotal.toFixed(2)).toLocaleString() + '</strong><br />' +
                'Costi: &euro; <strong>' + Number(x.netCost.toFixed(2)).toLocaleString() + '</strong><br />' +
                'F. Netto: &euro; <strong>' + Number(x.netGain.toFixed(2)).toLocaleString() + '</strong>' +
              '</div>',
              sogliaFatturato.value,
              x.netGain,
              x.netCost + ((x.netGain < 0) ? x.netGain : 0),
            ])
        );

        return {
          chartType: 'ComboChart',
          dataTable: dataTable,
          options: {
            title : 'Anno ' + year,
            vAxis: { title: 'Ammontare fatturato' },
            hAxis: { title: 'Mesi' },
            seriesType: 'bars',
            series: {
              0: { type: 'line', color: '#c4005f' },
              1: { color: '#5ea8a7' },
              2: { color: '#283655' },
            },
            isStacked: true,
            height: 500,
            tooltip: { isHtml: true },
            focusTarget: 'category'
          },
        }
      });
    }

    $scope.drawGoogleChart = () => {
      $scope.loading = true;
      newApiClient.call(`
        query($filter: StatsByMonthFilter!) {
          stats {
            statsByMonth(filter: $filter) {
              year
              month
              netTotal
              netCost
              netGain
            }
          }
        }
      `, {
        filter: {
          fromDate: $scope.filters.fromDate,
          toDate: $scope.filters.toDate,
          isResale: ($scope.filters.isResale === null || $scope.filters.isResale === "")
            ? null
            : ($scope.filters.isResale === "1"),
        },
      }).then(function(response) {
        $scope.loading = false;
        $scope.allCharts = getCharts(response.stats.statsByMonth);
      }, function(error) {
        $scope.loading = false;
        console.error(error);
      });
    };

    $scope.drawGoogleChart();

    $scope.$watch('filters.isResale', function(newValue, oldValue) {
      if (newValue !== oldValue)
        $scope.drawGoogleChart();
    });
    $scope.$watch('filters.fromDate', function(newValue, oldValue) {
      if (newValue !== oldValue)
        $scope.drawGoogleChart();
    });
    $scope.$watch('filters.toDate', function(newValue, oldValue) {
      if (newValue !== oldValue)
        $scope.drawGoogleChart();
    });
  }
