import * as angular from 'angular';
import { Convert, Random } from '../../utils';

angular
  .module('screeningcanvas')
  .controller('ModalMergeCompaniesCtrl', ModalMergeCompaniesCtrl);

  ModalMergeCompaniesCtrl.$inject = ['$scope', 'fromCompany', '$uibModalInstance'];

  function ModalMergeCompaniesCtrl($scope, fromCompany, $uibModalInstance) {
    $scope.fromCompany = fromCompany;
    $scope.data = {
      fromCompanyId: fromCompany.id,
      intoCompanyId: null,
      check: null,
    };
    $scope.check = Random.randomString(6).toUpperCase();

    $scope.ok = function() {
      if ($scope.check != $scope.data.check) {
        console.error("Failed check");
        return;
      }
      $uibModalInstance.close({
        fromCompanyId: $scope.data.fromCompanyId,
        intoCompanyId: Convert.toInt($scope.data.intoCompanyId),
      }); 
    };
    $scope.cancel = function() { $uibModalInstance.dismiss('cancel'); };
  }
