import * as angular from 'angular';
import * as moment from 'moment';
import toastr from 'toastr';

angular
  .module('screeningcanvas')
  .factory('User', UserFactory);

UserFactory.$inject = ['$cookies'];

function UserFactory($cookies) {
  return {
    setUser: function (user) {
      const expirationDate = moment().clone().utc().add(28 * 6, 'day').toDate();
      $cookies.putObject('user', user, { 'expires': expirationDate });
    },
    getUser: function () {
      const user = $cookies.getObject('user');
      if (!user)
        return null;
      // @todo: remove in May 2021 when all old cookies expire.
      // This is done to ignore the old cookie's shape.
      if (!user.role)
        return null;
      return {
        ...user,
        isSuperAdmin: user.role.id == 1,
        isAdmin: user.role.id == 2,
      };
    },
    destroy: function () {
      $cookies.remove('user');
    },
  };
}