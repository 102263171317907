import * as angular from 'angular';
import * as moment from 'moment';
import toastr from 'toastr';
import { Convert } from '../../utils';

angular
    .module('screeningcanvas')
    .controller('UpdateWaybillCtrl', UpdateWaybillCtrl);

UpdateWaybillCtrl.$inject = ['$scope', '$uibModal', '$state', '$stateParams', 'newApiClient'];

function UpdateWaybillCtrl($scope, $uibModal, $state, $stateParams, newApiClient) {
    $scope.title = "Modifica DDT";
    $scope.loading = false;

    function loadWaybill(id) {
        newApiClient.call(`
            query($id: Int!) {
                waybills {
                    waybill(id: $id) {
                        id
                        date
                        customer { id }
                        recipientName
                        recipientAddress {
                            streetAddress
                            city
                            zip
                            region { id }
                            country { id }
                        }
                        mean
                        causal
                        deliveryDate
                        carrier
                        appearanceOfTheGoods
                        pieces
                        weight
                        note
                        items {
                            project {
                                id
                                title
                                folderCode
                                quantity
                                deliveredQuantity
                                deliverableQuantity
                                administrativeNote
                                status {
                                    type
                                    updatedAt
                                }
                            }
                            description
                            quantity
                        }
                    }
                }
            }
        `, { id: parseInt(id) }).then(function (data) {
            $scope.waybill = data.waybills.waybill;
            $scope.data = dataFromWaybill($scope.waybill);
        }, function (error) {
            toastr.error("Impossibile caricare i dati del DDT");
        });
    }
    const waybillId = $stateParams.waybillId;
    loadWaybill(waybillId);

    $scope.data = newData(null);
    function newData(customerId) {
        return {
            date: moment().format('YYYY-MM-DD'),
            customerId: customerId || null,
            recipientName: null,
            recipientAddress: {
                streetAddress: null,
                city: null,
                zip: null,
                regionId: null,
                countryId: null,
            },
            mean: 'SENDER',
            causal: null,
            deliveryDate: moment().format('YYYY-MM-DD'),
            deliveryPayer: null,
            carrier: null,
            appearanceOfTheGoods: null,
            pieces: null,
            weight: null,
            note: null,
            items: [],
        };
    }

    function dataFromWaybill(waybill) {
        return {
            date: waybill.date,
            customerId: waybill.customer.id,
            recipientName: waybill.recipientName,
            recipientAddress: {
                streetAddress: waybill.recipientAddress.streetAddress,
                city: waybill.recipientAddress.city,
                zip: waybill.recipientAddress.zip,
                regionId: "" + waybill.recipientAddress.region.id,
                countryId: "" + waybill.recipientAddress.country.id,
            },
            mean: waybill.mean,
            causal: waybill.causal,
            deliveryDate: waybill.deliveryDate,
            deliveryPayer: waybill.deliveryPayer,
            carrier: waybill.carrier,
            appearanceOfTheGoods: waybill.appearanceOfTheGoods,
            pieces: waybill.pieces,
            weight: waybill.weight,
            note: waybill.note,
            items: waybill.items.map(item => ({
                _project: {
                    ...item.project,
                    deliveredQuantity: item.project.deliveredQuantity - item.quantity,
                    deliverableQuantity: item.project.deliverableQuantity + item.quantity,
                },
                projectId: item.project.id,
                description: item.description,
                quantity: item.quantity,
            })),
        };
    }

    $scope.customers = [];
    function loadCustomers() {
        newApiClient.call(`
            query($projectFilter: ProjectFilter!) {
                companies {
                    allCompanies {
                        list(pagination: { take: 1000 }) {
                            id
                            name
                            billingAddress {
                                streetAddress
                                city
                                zip
                                region { id }
                                country { id }
                            }
                            deliverableProjects: projects {
                                count(filter: $projectFilter)
                            }
                        }
                    }
                }
            }
        `, {
            projectFilter: {
                fromDate: moment().clone().subtract(6, 'month').format("YYYY-MM-DD"),
                statusTypes: ['OPEN', 'CLOSED'],
            }
        }).then(function (customersData) {
            $scope.customers = customersData.companies.allCompanies.list.filter(x => x.deliverableProjects.count > 0);
        }, function (error) {
            toastr.error("Impossibile caricare i clienti");
        });
    };
    loadCustomers();

    $scope.customerProjects = [];
    function loadCustomerProjects(customerId) {
        newApiClient.call(`
            query($filter: ProjectFilter!, $pagination: Pagination!, $order: ProjectOrder!) {
                projects {
                    allProjects {
                        list(filter: $filter, pagination: $pagination, order: $order) {
                            id
                            title
                            folderCode
                            quantity
                            deliveredQuantity
                            deliverableQuantity
                            administrativeNote
                            status {
                                type
                                updatedAt
                            }
                        }
                    }
                }
            }
        `, {
            filter: {
                fromDate: moment().clone().subtract(6, 'month').format("YYYY-MM-DD"),
                statusTypes: ['OPEN', 'CLOSED'],
                customerIds: [customerId],
            },
            pagination: {
                take: 1000,
            },
            order: 'CREATED_AT_DESC',
        }).then(function (data) {
            $scope.customerProjects = data.projects.allProjects.list;
            $scope.customerProjects.forEach(proj => {
                const correspondingItem = $scope.waybill.items.find(i => i.project.id == proj.id);
                if (!!correspondingItem) {
                    proj.deliverableQuantity += correspondingItem.quantity;
                    proj.deliveredQuantity -= correspondingItem.quantity;
                }
            });
        }, function (error) {
            toastr.error("Impossibile caricare i progetti del cliente");
        });
    }

    $scope.$watch('data.customerId', function (newCustomerId, oldCustomerId) {
        if (newCustomerId !== '' && newCustomerId !== null && typeof newCustomerId !== 'undefined') {
            if (oldCustomerId != newCustomerId && oldCustomerId !== null) {
                $scope.data = newData(newCustomerId);

                const newCustomer = $scope.customers.find(x => x.id == newCustomerId);
                $scope.data.recipientName = newCustomer.name;
                $scope.data.recipientAddress = {
                    streetAddress: newCustomer.billingAddress.streetAddress,
                    city: newCustomer.billingAddress.city,
                    zip: newCustomer.billingAddress.zip,
                    countryId: "" + newCustomer.billingAddress.country.id,
                    regionId: (!!newCustomer.billingAddress.region)
                        ? "" + newCustomer.billingAddress.region.id
                        : null,
                };
            }
            loadCustomerProjects(newCustomerId);
        }
    });

    $scope.availableProjects = function () {
        const hiddenIds = $scope.data.items.map(function (x) { return x.projectId; });
        return $scope.customerProjects.filter(function (proj) {
            return hiddenIds.indexOf(proj.id) == -1;
        });
    };

    $scope.openAddModal = function () {
        $uibModal.open({
            templateUrl: 'templates/waybills/modal_add.html',
            size: 'lg',
            controller: 'ModalAddProjectToWaybillCtrl',
            resolve: {
                availableProjects: $scope.availableProjects,
            },
        }).result.then(function (selectedProjects) {
            const toAdd = selectedProjects.map(function (proj) {
                return {
                    _project: proj,
                    projectId: proj.id,
                    description: `${proj.folderCode} ${proj.title}`,
                    quantity: proj.deliverableQuantity,
                };
            });
            $scope.data.items = $scope.data.items.concat(toAdd);
        });
    };

    $scope.deselect = function (item) {
        $scope.data.items = $scope.data.items.filter(function (x) { return x._project.id != item._project.id; });
    };

    $scope.canSave = function () {
        return !$scope.data.items.some(x => !$scope.hasValidQuantity(x)) &&
            !!$scope.data.date &&
            !!$scope.data.mean &&
            !!$scope.data.causal &&
            !!$scope.data.deliveryDate &&
            !!$scope.data.recipientName &&
            !!$scope.data.recipientAddress.streetAddress &&
            !!$scope.data.recipientAddress.city &&
            !!$scope.data.recipientAddress.countryId &&
            $scope.data.items.length > 0;
    };

    $scope.hasValidQuantity = function (item) {
        return item.quantity != null &&
            item.quantity > 0 &&
            item.quantity <= item._project.deliverableQuantity;
    };

    $scope.save = function () {
        $scope.submitting = true;
        newApiClient.call(`
            mutation($id: Int!, $input: WaybillInput!) {
                waybills {
                    updateWaybill(id: $id, input: $input) {
                        id
                    }
                }
            }
        `, {
            id: $scope.waybill.id,
            input: {
                date: $scope.data.date,
                customerId: Convert.toInt($scope.data.customerId),
                recipientName: $scope.data.recipientName,
                recipientAddress: {
                    streetAddress: Convert.toNonEmptyString($scope.data.recipientAddress.streetAddress),
                    city: Convert.toNonEmptyString($scope.data.recipientAddress.city),
                    zip: Convert.toNonEmptyString($scope.data.recipientAddress.zip),
                    regionId: Convert.toInt($scope.data.recipientAddress.regionId),
                    countryId: Convert.toInt($scope.data.recipientAddress.countryId),
                },
                mean: $scope.data.mean,
                causal: $scope.data.causal,
                deliveryDate: $scope.data.deliveryDate,
                deliveryPayer: $scope.data.deliveryPayer,
                carrier: Convert.toNonEmptyString($scope.data.carrier),
                appearanceOfTheGoods: Convert.toNonEmptyString($scope.data.appearanceOfTheGoods),
                pieces: $scope.data.pieces,
                weight: $scope.data.weight,
                note: Convert.toNonEmptyString($scope.data.note),
                items: $scope.data.items.map(item => ({
                    projectId: item.projectId,
                    description: item.description,
                    quantity: item.quantity,
                })),
            }
        }).then(function (data) {
            $scope.submitting = false;
            $state.go('elencoddt');
        }, function (errors) {
            $scope.submitting = false;
            console.error(errors);
            const message = errors[0].extensions.userMessage;
            toastr.error(message, "Impossibile modificare il DDT", { timeOut: 0, extendedTimeOut: 0 });
        });
    }
}