import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .filter('invoiceType', function () {
    return function (input) {
      switch (input) {
        case 'INVOICE': return 'Fattura';
        case 'INVOICE_DEPOSIT': return 'Acconto';
        case 'PARCEL': return 'Parcella';
        case 'PARCEL_DEPOSIT': return 'Acconto su parcella';
        case 'CREDIT_NOTE': return 'Nota di credito';
        case 'DEBIT_NOTE': return 'Nota di debito';
      }
    };
  });
