import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .directive('mainloading', function () {
    return {
      restrict: 'E',
      template: '<div class="mainloading" ng-show="loading || vm.loading"><h3 class="font-dark"><i class="fa fa-gear fa-spin"></i> Loading...</h3></div>',
    };
  });
