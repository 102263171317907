import * as angular from 'angular';
import { Component, Inject, ViewChild } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { User } from '../../ajs-upgraded-providers';
import { Apollo, gql } from 'apollo-angular';
import * as moment from 'moment';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import { showApolloError } from '../../alert';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DashboardModal } from './dashboard-modal.component';
import { Convert } from '../../../utils';

@Component({
  selector: 'dashboard-page',
  templateUrl: 'new/pages/dashboard/dashboard-page.component.html',
})
export class DashboardPage {
  @ViewChild(FullCalendarComponent) calendar: FullCalendarComponent;
  calendarOptions: CalendarOptions = {
    initialView: 'timeGridWeek',
    locale: 'it',
    height: 700,
    events: this.fetchEvents.bind(this),
    eventResize: this.onEventResize.bind(this),
    eventClick: this.onEventClick.bind(this),
    eventDrop: this.onEventDrop.bind(this),
    select: this.onSelect.bind(this),
    businessHours: [
      {
        daysOfWeek: [1, 2, 3, 4, 5],
        startTime: '09:00:00',
        endTime: '13:00:00',
      },
      {
        daysOfWeek: [1, 2, 3, 4, 5],
        startTime: '14:00:00',
        endTime: '18:00:00',
      },
    ],
    slotDuration: '00:15:00',
    snapDuration: '00:15:00',
    nowIndicator: true,
    views: {
      week: {
        slotDuration: '00:15:00',
        snapDuration: '00:15:00',
        slotLabelFormat: {
          hour: 'numeric',
          minute: '2-digit',
          omitZeroMinute: false,
          meridiem: 'short'
        },
        hiddenDays: [0],
        slotMinTime: '08:00:00',
        slotMaxTime: '20:00:00',
        selectable: true,
        selectMirror: true,
      }
    },
  };

  private user: any = null;

  constructor(
    @Inject(Apollo) private apollo: Apollo,
    @Inject(User) user,
    @Inject(BsModalService) private modalService: BsModalService,
  ) {
    this.user = user.getUser();
  }

  async onEventResize(info) {
    if (!info.oldEvent.extendedProps.activity)
      return;

    const activity = info.oldEvent.extendedProps.activity;
    const startsAt = moment(info.event.startStr).toISOString();
    const endsAt = moment(info.event.endStr).toISOString();
    try {
      await this.apollo.mutate({
        mutation: gql`
          mutation($input: UpdateActivityInput!) {
            activities {
              updateActivity(input: $input) {
                id
              }
            }
          }
        `,
        variables: {
          input: {
            activityId: activity.id,
            period: {
              startsAt: startsAt,
              endsAt: endsAt,
            },
          },
        }
      }).toPromise();
      this.calendar.getApi().refetchEvents();
    } catch (error) {
      showApolloError(error);
      info.revert();
    }
  }

  async onEventDrop(info) {
    try {
      const task = info.oldEvent.extendedProps.task;
      const activity = info.oldEvent.extendedProps.activity;
      if (!!task) {
        info.revert();
        const startsAt = moment(info.event.startStr).toISOString();
        const endsAt = moment(info.event.startStr).add(1, 'hour').toISOString();
        const input = {
          parent: {
            taskId: task.id,
          },
          typeId: null,
          description: task.name,
          period: {
            startsAt: startsAt,
            endsAt: endsAt,
          }
        };
        await this.apollo.mutate({
          mutation: gql`
            mutation($input: CreateActivityInput!) {
              activities {
                createActivity(input: $input) {
                  id
                }
              }
            }
          `,
          variables: {
            input: input,
          },
        }).toPromise();
        this.calendar.getApi().refetchEvents();
      } else if (!!activity) {
        const startsAt = moment(info.event.startStr).toISOString();
        const endsAt = moment(info.event.endStr).toISOString();
        await this.apollo.mutate({
          mutation: gql`
            mutation($input: UpdateActivityInput!) {
              activities {
                updateActivity(input: $input) {
                  id
                }
              }
            }
          `,
          variables: {
            input: {
              activityId: activity.id,
              period: {
                startsAt: startsAt,
                endsAt: endsAt,
              },
            },
          }
        }).toPromise();
        this.calendar.getApi().refetchEvents();
      } else {
        info.revert();
        console.warn('unknown eventDrop', info);
      }
    } catch (error) {
      showApolloError(error);
    }
  }

  onEventClick(info) {
    const activity = info.event.extendedProps.activity;
    if (!!activity) {
      let companyId = null;
      let projectId = null;
      let taskId = null;
      let typeId = null;
      switch (activity.parent.__typename) {
        case 'Company':
          companyId = activity.parent.id;
          typeId = activity.type?.id;
          break;
        case 'Task':
          companyId = activity.parent.project.customer.id;
          projectId = activity.parent.project.id;
          taskId = activity.parent.id;
          break;
      }
      const modalRef = this.modalService.show(DashboardModal, {
        initialState: {
          title: 'Dettaglio attività',
          showExtraButtons: true,
          activity: {
            companyId: companyId,
            projectId: projectId,
            taskId: taskId,
            typeId: typeId,
            description: activity.description,
            startsAt: activity.startsAt,
            endsAt: activity.endsAt,
            isBillable: activity.isBillable,
          },
        }
      });
      modalRef.content.onClose.subscribe((input) => {
        this.updateActivity(activity.id, input);
      });
      modalRef.content.onDuplicate.subscribe(() => {
        this.duplicateActivity(activity);
      });
      modalRef.content.onRemove.subscribe(() => {
        if (confirm('Sicuro di voler rimuovere questa attività?')) {
          this.removeActivity(activity.id);
        }
      });
    }
  }

  onSelect(info) {
    const startsAt = moment(info.startStr).toISOString();
    const endsAt = moment(info.endStr).toISOString();
    const modalRef = this.modalService.show(DashboardModal, {
      initialState: {
        title: 'Aggiungi attività',
        showExtraButtons: false,
        activity: {
          companyId: null,
          projectId: null,
          taskId: null,
          typeId: null,
          description: null,
          startsAt: startsAt,
          endsAt: endsAt,
          isBillable: true,
        },
      }
    });
    modalRef.content.onClose.subscribe((input) => {
      this.createActivity(input);
    });
  }

  async fetchEvents(fetchInfo, successCallback, failureCallback) {
    const from = moment(fetchInfo.startStr);
    const to = moment(fetchInfo.endStr);
    try {
      const { data } = await this.apollo.query<any>({
        query: DASHBOARD_QUERY,
        variables: {
          taskFilter: {
            assignedUserIds: [this.user.id],
            deadlineFrom: from.format('YYYY-MM-DD'),
            deadlineTo: to.format('YYYY-MM-DD'),
            isCompleted: false,
            shouldBeCompleted: true,
          },
          filter: {
            userIds: [this.user.id],
            from: from.toISOString(),
            to: to.toISOString(),
          }
        }
      }).toPromise();
      const tasks = data.tasks.allTasks.list;
      const activities = data.activities.allActivities.list;
      const eventsFromActivities = activities.map(a => {
        const isExtra = a.parent.__typename == 'Company';
        return {
          title: (isExtra)
            ? [
              a.parent.name,
            ].join("\n")
            : [
              a.parent.project.customer.name,
              `${a.parent.project.folderCode} ${a.parent.project.title}`,
              a.parent.name,
            ].join("\n"),
          start: a.startsAt,
          end: a.endsAt,
          color: (isExtra) ? '#b482c8' : '#6BA5C1',
          textColor: 'white',
          editable: true,
          extendedProps: {
            activity: a,
          },
        };
      });
      const eventsFromTasks = tasks.map(s => {
        return {
          title: [
            s.name,
            `${s.project.folderCode} ${s.project.title}`,
            s.project.customer.name,
          ].join("\n"),
          start: s.deadline,
          allDay: true,
          color: '#F6BF26',
          textColor: 'black',
          editable: true,
          extendedProps: {
            task: s,
          },
        };
      });
      const events = eventsFromTasks.concat(eventsFromActivities);
      successCallback(events);
    } catch (error) {
      showApolloError(error);
      failureCallback();
    }
  }

  async createActivity(input) {
    try {
      await this.apollo.mutate({
        mutation: gql`
          mutation($input: CreateActivityInput!) {
            activities {
              createActivity(input: $input) {
                id
              }
            }
          }
        `,
        variables: {
          input: input,
        }
      }).toPromise();
      this.calendar.getApi().refetchEvents();
    } catch (error) {
      showApolloError(error);
    }
  }

  async updateActivity(activityId: number, input) {
    try {
      await this.apollo.mutate({
        mutation: gql`
          mutation($input: UpdateActivityInput!) {
            activities {
              updateActivity(input: $input) {
                id
              }
            }
          }
        `,
        variables: {
          input: {
            activityId: activityId,
            ...input,
          },
        }
      }).toPromise();
      this.calendar.getApi().refetchEvents();
    } catch (error) {
      showApolloError(error);
    }
  }

  async removeActivity(activityId: number) {
    try {
      await this.apollo.mutate({
        mutation: gql`
          mutation($id: Int!) {
          activities {
            removeActivity(activityId: $id)
          }
        }
        `,
        variables: { id: activityId },
      }).toPromise();
      this.calendar.getApi().refetchEvents();
    } catch (error) {
      showApolloError(error);
    }
  }

  async duplicateActivity(activity) {
    try {
      const isExtra = activity.parent.__typename == 'Company';
      const input = {
        parent: (isExtra)
          ? {
            companyId: Convert.toInt(activity.parent.id),
          }
          : {
            taskId: Convert.toInt(activity.parent.id),
          },
        typeId: Convert.toInt(activity.type?.id),
        description: activity.description,
        period: {
          startsAt: moment(activity.startsAt).add(15, 'minutes').toISOString(),
          endsAt: moment(activity.endsAt).add(15, 'minutes').toISOString(),
        },
        isBillable: activity.isBillable,
      };
      await this.apollo.mutate({
        mutation: gql`
          mutation($input: CreateActivityInput!) {
            activities {
              createActivity(input: $input) {
                id
              }
            }
          }
        `,
        variables: { input: input },
      }).toPromise();
      this.calendar.getApi().refetchEvents();
    } catch (error) {
      showApolloError(error);
    }
  }
}

const DASHBOARD_QUERY = gql`
  query($filter: ActivityFilter!, $taskFilter: TaskFilter!) {
    tasks {
      allTasks {
        list(filter: $taskFilter, pagination: { take: 1000 }) {
          id
          name
          deadline
          project {
            id
            title
            folderCode
            customer {
              id
              name
            }
          }
        }
      }
    }
    activities {
      allActivities {
        list(filter: $filter, pagination: { take: 1000 }) {
          id
          description
          type {
            id
            label
          }
          startsAt
          endsAt
          isBillable
          parent {
            __typename
            ... on Company {
              id
              name
            }
            ... on Task {
              id
              name
              project {
                id
                title
                folderCode
                customer {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

angular.module('screeningcanvas').directive('dashboardPage', downgradeComponent({ component: DashboardPage }));