import * as angular from 'angular';
import * as moment from 'moment';
import toastr from 'toastr';
import { Convert } from '../../utils';

angular
  .module('screeningcanvas')
  .controller('ProgettoNewCtrl', ProgettoNewCtrl);

ProgettoNewCtrl.$inject = ['$scope', '$state', 'newApiClient'];

function ProgettoNewCtrl($scope, $state, newApiClient) {
  $scope.now = moment().format('YYYY-MM-DD');
  $scope.title = 'Nuovo progetto';
  $scope.hasReferentUser = () =>
    !!$scope.data &&
    !!$scope.data.memberships &&
    $scope.data.memberships.some(m => m.isReferent);

  $scope.frozenUsers = [];

  $scope.data = {
    projectTypeId: null,
    title: null,
    customerId: null,
    fixedPrice: 0,
    unitPrice: null,
    isUnitPrice: false,
    quantity: 1,
    hasQuantityAsBillableHours: false,
    unit: 'NOS',
    deadline: moment().endOf('month').format('YYYY-MM-DD'),
    purchaseOrderCode: null,
    purchaseOrderCIG: null,
    contractCode: null,
    customerProductCode: null,
    paymentMethodId: null,
    paymentTermId: null,
    memberships: [],
    isResale: false,
    includeInTimesheet: false,
  };

  $scope.save = function () {
    const input = {
      deadline: Convert.toNonEmptyString($scope.data.deadline),
      customerId: Convert.toInt($scope.data.customerId),
      projectTypeId: Convert.toInt($scope.data.projectTypeId),
      title: $scope.data.title,
      purchaseOrderCode: Convert.toNonEmptyString($scope.data.purchaseOrderCode),
      purchaseOrderCIG: Convert.toNonEmptyString($scope.data.purchaseOrderCIG),
      contractCode: Convert.toNonEmptyString($scope.data.contractCode),
      customerProductCode: Convert.toNonEmptyString($scope.data.customerProductCode),
      pricing: {
        fixedPrice: (!$scope.data.isUnitPrice) ? Convert.toFloat($scope.data.fixedPrice) : null,
        unitPrice: (!!$scope.data.isUnitPrice) ? Convert.toFloat($scope.data.unitPrice) : null,
      },
      unit: Convert.toNonEmptyString($scope.data.unit),
      quantity: (!$scope.data.hasQuantityAsBillableHours)
        ? Convert.toFloat($scope.data.quantity)
        : null,
      quantityAsBillableHours: $scope.data.hasQuantityAsBillableHours,
      isResale: $scope.data.isResale,
      includeInTimesheet: $scope.data.includeInTimesheet,
      paymentInfo: (!!$scope.data.paymentTermId && !!$scope.data.paymentMethodId)
        ? {
          paymentTermId: Convert.toInt($scope.data.paymentTermId),
          paymentMethodId: Convert.toInt($scope.data.paymentMethodId),
        }
        : null,
      note: Convert.toNonEmptyString($scope.data.note),
      memberships: $scope.data.memberships,
    };
    newApiClient.call(`
        mutation($input: CreateProjectInput!) {
          projects {
            createProject(input: $input) {
              id
            }
          }
        }
      `, { input: input })
      .then(function (data) {
        toastr.success("Progetto creato");
        $state.go('progettodettaglio', { id: data.projects.createProject.id });
      }, function (errors) {
        toastr.error(errors[0].extensions.userMessage, "Impossibile creare il progetto", { timeOut: 0, extendedTimeOut: 0 });
      });
  };

}