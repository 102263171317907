import * as angular from 'angular';
import toastr from 'toastr';

angular
  .module('screeningcanvas')
  .controller('ModalSendInvoiceCtrl', ModalSendInvoiceCtrl);

  ModalSendInvoiceCtrl.$inject = ['$scope', 'invoice', '$uibModalInstance'];

  function ModalSendInvoiceCtrl($scope, invoice, $uibModalInstance) {
    $scope.invoice = invoice;
    $scope.check = {
      invoiceNumber: null,
    };

    $scope.save = function () {
      if ($scope.check.invoiceNumber == invoice.yearlyCode) {
        $uibModalInstance.close({});
      } else {
        toastr.error("Progressivo fattura incorretto");
      }
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }
