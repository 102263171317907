import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .directive('filtersCardButtons', ['Utility', '$cookieStore', function (Utility, $cookieStore) {
    return {
      restrict: 'E',
      template: `
        <div class="btn-group">
          <button type="submit" class="btn btn-primary" ng-click="cerca()" ng-if="!loading"><i class="fa fa-fw fa-search"></i> Cerca</button>
          <button type="button" class="btn btn-default" ng-click="" ng-if="loading" disabled><i class="fa fa-fw fa-gear fa-spin"></i> Cerco...</button>
          <button type="button" class="btn btn-default" ng-click="resetFilters()"><i class="fa fa-eraser"></i> Pulisci</button>
        </div>
      `,
      link: function (scope, elem, attrs) {
      }
    };
  }]);
