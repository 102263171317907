import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .controller('ModalDigitalInvoicesForInvoiceCtrl', ModalDigitalInvoicesForInvoiceCtrl);

  ModalDigitalInvoicesForInvoiceCtrl.$inject = ['$scope', 'digitalInvoices', '$uibModalInstance', 'fileClient'];

  function ModalDigitalInvoicesForInvoiceCtrl($scope, digitalInvoices, $uibModalInstance, fileClient) {
    $scope.digitalInvoices = digitalInvoices.map(di => {
      var notifications = di.notifications;
      notifications.sort(function(a, b) {
        if (a.createdAt < b.createdAt) return -1;
        if (a.createdAt > b.createdAt) return 1;
        return 0;
      });
      return {
        ...di,
        lastNotification: (notifications.length > 0)
          ? notifications[0]
          : null,
      };
    });


    $scope.displayStatus = (digitalInvoice) =>
    {
      switch (digitalInvoice.status.type) {
        case 'DISCARDED':
          return { color: 'red', text: 'Scartata', isTerminal: true };
        case 'DELIVERED':
          return { color: 'green', text: 'Consegnata', isTerminal: true };
        case 'NOT_DELIVERED':
          return { color: 'green', text: 'Mancata consegna', isTerminal: true };
        case 'AWAITING':
          return { color: 'orange', text: 'Inviata (in attesa)', isTerminal: false };
      }
    };

    function openURL(url) {
      var win = window.open(url, '_blank');
      win.focus();
    }

    $scope.showDigitalInvoicePDF = function(digitalInvoice) {
      openURL(digitalInvoice.xmlPdfFile);
    }

    $scope.showDigitalInvoiceXML = function(digitalInvoice) {
      const url = digitalInvoice.xmlFile;
      fileClient.displayStyledXML(url, "/xsl/digital_invoice.xsl");
    }
    
    $scope.showNotificationXML = function(type, fileURL) {
      switch (type) {
        case 'RC': return fileClient.displayStyledXML(fileURL, "/xsl/rc_notification.xsl");
        case 'MC': return fileClient.displayStyledXML(fileURL, "/xsl/mc_notification.xsl");
        case 'NE': return fileClient.displayStyledXML(fileURL, "/xsl/ne_notification.xsl");
        case 'NS': return fileClient.displayStyledXML(fileURL, "/xsl/ns_notification.xsl");
      }
    };

    $scope.save = function () {
        $uibModalInstance.close({});
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }