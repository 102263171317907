import * as angular from 'angular';
import { Convert } from '../../../utils';

angular
  .module('screeningcanvas')
  .controller('ModalProjectEditActivityCtrl', ModalProjectEditActivityCtrl);

  ModalProjectEditActivityCtrl.$inject = ['$scope', 'data', '$uibModalInstance', 'title'];

  function ModalProjectEditActivityCtrl($scope, data, $uibModalInstance, title) {
    $scope.title = title;

    $scope.data = {
        description: data.description,
        isBillable: data.isBillable,
    };
    $scope.save = function () {
      $uibModalInstance.close({
        description: Convert.toNonEmptyString($scope.data.description),
        isBillable: $scope.data.isBillable,
      });
    };
  }