import * as angular from 'angular';
import { Js } from '../../../utils';
import { showApolloError } from '../../alert';
import { Component, Inject, OnInit } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { UIRouterLocation, UIRouterState, User } from '../../ajs-upgraded-providers';
import { downgradeComponent } from '@angular/upgrade/static';
import { FormBuilder, Validators } from '@angular/forms';

type PageStatus = 'start' | 'request-sent' | 'signing-in' | 'error';

@Component({
  selector: 'login-page',
  templateUrl: 'new/pages/login/login-page.component.html',
})
export class LoginPage implements OnInit {
  private loading: boolean = false;
  private status: PageStatus = 'start';

  constructor(
    @Inject(Apollo) private apollo: Apollo,
    @Inject(UIRouterState) private $state, 
    @Inject(UIRouterLocation) private $location, 
    @Inject(User) private User,
    @Inject(FormBuilder) private formBuilder: FormBuilder,
  ) {}

  private form = this.formBuilder.group({
    email: ['', Validators.required],
  });

  async ngOnInit() {
    const queryParams = this.$location.search();
    if (!Js.isNull(queryParams.id) && !Js.isNull(queryParams.key)) {
      this.signIn(queryParams.id, queryParams.key);
    }
  
    const { data } = await this.apollo.query<any>({
      query: gql`
        query {
          auth {
            viewer {
              id
            }
          }
        }
      `
    }).toPromise();
    const user = data.auth.viewer;
    if (!!user)
      this.$state.go('dashboard');
  }

  restart() { this.status = 'start'; }

  async requestSignIn() {
    const input = this.form.value;
    this.loading = true;
    try {
      await this.apollo.mutate({
        mutation: gql`
          mutation($input: RequestSignInInput!) {
            auth {
              requestSignIn(input: $input)
            }
          }
        `,
        variables: { input: input }
      }).toPromise();
      this.status = 'request-sent';
    } catch (error) {
      showApolloError(error);
    } finally {
      this.loading = false;
    }
  }

  async signIn(id: string, key: string) {
    try {
      this.status = 'signing-in';
      await this.apollo.mutate({
        mutation: gql`
          mutation($input: SignInInput!) {
            auth {
              signIn(input: $input)
            }
          }
        `,
        variables: {
          input: {
            tokenId: id,
            tokenKey: key,
          }
        }
      }).toPromise();
      this.status = 'request-sent';

      const { data } = await this.apollo.query<any>({
        query: gql`
          query {
            auth {
              viewer {
                id
                username
                role {
                  id
                }
                areas {
                  id
                  name
                }
                avatar
              }
            }
          }
        `
      }).toPromise();
      const user = data.auth.viewer;
      this.User.setUser(user);
      this.$state.go('dashboard');
    } catch (error) {
      showApolloError(error);
      this.status = 'error';
    } finally {
      this.loading = false;
    }
  }
}

angular.module('screeningcanvas').directive('loginPage', downgradeComponent({ component: LoginPage }));