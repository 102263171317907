import * as angular from 'angular';
import toastr from 'toastr';

angular
  .module('screeningcanvas')
  .controller('ProjectsTabsContractsCtrl', ProjectsTabsContractsCtrl);

  ProjectsTabsContractsCtrl.$inject = ['$scope', 'newApiClient', '$stateParams'];

  function ProjectsTabsContractsCtrl($scope, newApiClient, $stateParams) {
    $scope.loading = false;
    $scope.softLoading = false;
    $scope.isEditing = false;
    $scope.contracts = [];
    $scope.currentContract = null;

    $scope.load = (initialLoading) => {
      $scope.loading = initialLoading;
      newApiClient.call(`
        query($id: Int!) {
          projects {
            project(id: $id) {
              contracts {
                id
                title
                body
                createdAt
              }
            }
          }
        }`,
        {
          id: parseInt($stateParams.id),
        },
      ).then(function(data) {
        $scope.loading = false;
        $scope.contracts = data.projects.project.contracts;
        if ($scope.contracts.length > 0)
          $scope.currentContract = $scope.contracts[0];
        $scope.isEditing = false;
      }, function(errors) {
        $scope.loading = false;
        $scope.isEditing = false;
        toastr.error(errors[0].message, "Impossibile caricare le clausole");
      });
    };

    $scope.initEdit = () => {
      $scope.isEditing = true;
    };

    $scope.createNewEmptyContract = () =>
    {
      $scope.softLoading = true;
      newApiClient.call(`
        mutation($input: CreateProjectContractInput!) {
          projectContracts {
            createProjectContract(input: $input) {
              id
            }
          }
        }`,
        {
          input: {
            parent: {
              projectId: parseInt($stateParams.id),
            },
            title: "Contratto",
            body: "Testo del contratto",
          }
        }
      ).then(function(data) {
        $scope.softLoading = false;
        $scope.isEditing = false;
        $scope.load(false);
      }, function(errors) {
        $scope.softLoading = false;
        $scope.isEditing = false;
        toastr.error(errors[0].message, "Impossibile creare il contratto");
      });
    };

    $scope.saveContract = (contract) =>
    {
      $scope.softLoading = true;
      newApiClient.call(`
        mutation($input: UpdateProjectContractInput!) {
          projectContracts {
            updateProjectContract(input: $input) {
              id
            }
          }
        }`,
        {
          input: {
            projectContractId: contract.id,
            title: contract.title,
            body: contract.body,
          }
        },
      ).then(function(data) {
        $scope.softLoading = false;
        $scope.isEditing = false;
        $scope.load(false);
      }, function(errors) {
        $scope.softLoading = false;
        $scope.isEditing = false;
        toastr.error(errors[0].message, "Impossibile modificare il contratto");
      });
    };

    $scope.removeContract = (contract) =>
    {
      $scope.softLoading = true;
      newApiClient.call(`
        mutation($id: Int!) {
          projectContracts {
            removeProjectContract(projectContractId: $id)
          }
        }`,
        { id: contract.id },
      ).then(function(data) {
        $scope.softLoading = false;
        $scope.isEditing = false;
        $scope.load(false);
      }, function(errors) {
        $scope.softLoading = false;
        $scope.isEditing = false;
        $scope.currentContract = null;
        toastr.error(errors[0].message, "Impossibile rimuovere il contratto");
      });
    };

    $scope.selectContract = (contract) =>
    {
      $scope.isEditing = false;
      $scope.currentContract = contract;
    };

    $scope.load(true);
  }
