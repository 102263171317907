import {
  forwardRef,
  Component,
  OnInit,
  Inject,
  Input,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Apollo, gql } from 'apollo-angular';
import { showApolloError } from '../../alert';

@Component({
  selector: 'activity-type-selector',
  templateUrl: 'new/shared/components/activity-type-selector.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR, 
      useExisting: forwardRef(() => ActivityTypeSelector), 
      multi: true
    }
  ]
})
export class ActivityTypeSelector implements OnInit {
  @Input() private optional: string;

  private disabled: boolean = false;
  private selected: number = null;
  private items = [];

  private _onTouched: any = () => {}
  private _onChange: any = () => {}

  constructor(@Inject(Apollo) private apollo: Apollo) {}

  private onChange() {
    this._onChange(this.selected);
    this._onTouched();
  }

  writeValue(value: number) {
    this.selected = value;
    this.onChange();
  }

  registerOnChange(fn: () => any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  async ngOnInit() {
    try {
      const { data } = await this.apollo.query<any>({
        query: gql`
          query {
            activityTypes {
              allActivityTypes {
                label
                value: id
              }
            }
          }
        `
      }).toPromise();
      this.items = data.activityTypes.allActivityTypes;
    } catch (error) {
      showApolloError(error);
    }
  }
}