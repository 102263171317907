import * as angular from 'angular';
import * as moment from 'moment';
import toastr from 'toastr';
import { Convert } from '../utils';

angular
  .module('screeningcanvas')
  .directive('purchaseInvoiceSelector', ['newApiClient', function (newApiClient) {
    return {
      restrict: 'A',
      scope: {
        ngModel: '=',
        supplierId: '=',
        status: '=',
        kind: '@',
      },
      template: `
        <option value="">{{ optionalText }}</option>
        <option value="{{ item.value }}" ng-repeat="item in items">
          {{ item.label }}
        </option>
      `,
      replace: false,
      controller: ['$scope', '$element', function ($scope, $element) {
        $scope.items = [];
        $scope.optionalText = "Nessuna";

        function toItem(purchaseInvoice) {
          return {
            value: purchaseInvoice.id,
            label: (purchaseInvoice.type == 'CREDIT_NOTE')
              ? `Nota di credito n. ${purchaseInvoice.code} del ${moment(purchaseInvoice.date).format('DD/MM/YYYY')}`
              : `Fattura n. ${purchaseInvoice.code} del ${moment(purchaseInvoice.date).format('DD/MM/YYYY')}`,
          };
        }

        $scope.fetch = function (supplierId) {
          if (!supplierId)
            return;
          newApiClient.call(`
            query($hasCurrent: Boolean!, $currentId: Int!, $filter: PurchaseInvoiceFilter!) {
              purchaseInvoices {
                purchaseInvoice(id: $currentId) @include(if: $hasCurrent) {
                  id
                  type
                  code
                  date
                }
                allPurchaseInvoices {
                  list(filter: $filter) {
                    id
                    type
                    code
                    date
                  }
                }
              }
            }`,
            {
              'hasCurrent': (!!$scope.ngModel),
              'currentId': Convert.toInt($scope.ngModel) ?? 0,
              'filter': {
                'supplierIds': [Convert.toInt(supplierId)],
                'status': Convert.toNonEmptyString($scope.status),
              }
            },
          ).then(function (data) {
            $scope.items = data.purchaseInvoices.allPurchaseInvoices.list
              .filter(x => {
                if (!$scope.kind) return true;
                if ($scope.kind === 'debit')
                  return x.type !== 'CREDIT_NOTE';
                if ($scope.kind === 'credit')
                  return x.type === 'CREDIT_NOTE';
              })
              .map(toItem);

            const currentInvoice = data.purchaseInvoices.purchaseInvoice;
            if (!!currentInvoice && !$scope.items.find(x => x.value == currentInvoice.id)) {
              $scope.items.unshift(toItem(currentInvoice));
            }
          }, function () {
            toastr.error("Impossibile caricare le fatture d'acquisto");
          });
        };
        $scope.fetch($scope.supplierId);

        $scope.$watch('supplierId', function (newVal) {
          $scope.fetch(newVal);
        });

        $scope.$watch('items', function (newVal) {
          if (newVal.length > 0) {
            $element.prop("disabled", false);
          } else {
            $element.prop("disabled", true);
          }
        });
      }],
    };
  }]);
