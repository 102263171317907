import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .controller('ProjectsModalPricingCtrl', ProjectsModalPricingCtrl);

  ProjectsModalPricingCtrl.$inject = ['$scope', '$uibModalInstance', 'project'];

  function ProjectsModalPricingCtrl($scope, $uibModalInstance, project) {
    $scope.project = angular.copy(project);
    $scope.project.estimateParts.forEach(part => part.prices.forEach(x => x._isSelected = true));

    $scope.getTotal = () => {
      return $scope.project.estimateParts.reduce((acc, part) =>
        acc + part.prices
          .filter(x => x._isSelected)
          .reduce((a, x) =>
          {
            if (x.unit == 'ITEM')
              return a + (x.amount * $scope.project.quantity);
            return a + x.amount;
          }, 0), 0);
    };

    $scope.save = function () {
      const finalPrice = $scope.getTotal();
      $uibModalInstance.close({ price: finalPrice });
    };
    $scope.skip = function () {
      $uibModalInstance.close({ price: null });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }
