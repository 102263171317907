import * as angular from 'angular';
import toastr from 'toastr';
import { Transaction } from '../../utils';

angular
  .module('screeningcanvas')
  .controller('FatturaAcquistoEditCtrl', EditFatturaAcquisto);

  EditFatturaAcquisto.$inject = ['$scope', 'newApiClient', '$stateParams', '$state'];

  function EditFatturaAcquisto($scope, newApiClient, $stateParams, $state) {
    $scope.isEdit = true;
    $scope.loading = false;
    $scope.purchaseInvoice = null;
    $scope.purchaseInvoiceId = $stateParams.id;
    $scope.paymentTerms = null;
    $scope.calculatedTransactions = [];

    $scope.getData = function() {
      $scope.loading = true;

      newApiClient.call(`
        query {
          paymentTerms {
            allPaymentTerms {
              id
              payments {
                offset
                offsetOrigin
                weight
              }
            }
          }
        }`,
        { id: parseInt($scope.purchaseInvoiceId) },
      ).then(function(data) {
        $scope.paymentTerms = data.paymentTerms.allPaymentTerms;
      }, function () {
        toastr.error("Impossibile caricare le scadenze di pagamento");
      });

      newApiClient.call(`
        query($id: Int!) {
          purchaseInvoices {
            purchaseInvoice(id: $id) {
              id
              type
              code
              date
              status
              category { id }
              supplier { id }
              total {
                netTotal
                taxes
                grossTotal
              }
              paymentMethod { id }
              paymentTerm { id }
              note
              transactions {
                id
                date
                amount
              }
            }
          }
        }`,
        { id: parseInt($scope.purchaseInvoiceId) },
      ).then(function(data) {
        $scope.loading = false;
        const invoice = data.purchaseInvoices.purchaseInvoice;
        $scope.purchaseInvoice = {
          type: invoice.type,
          supplierId: (!!invoice.supplier)
            ? invoice.supplier.id+""
            : null,
          categoryId: (!!invoice.category)
            ? invoice.category.id+""
            : null,
          code: invoice.code,
          date: invoice.date,
          netTotal: Math.abs(invoice.total.netTotal),
          taxes: Math.abs(invoice.total.taxes),
          grossTotal: Math.abs(invoice.grossTotal),
          paymentMethodId: (!!invoice.paymentMethod)
            ? invoice.paymentMethod.id+""
            : null,
          paymentTermId: (!!invoice.paymentTerm)
            ? invoice.paymentTerm.id+""
            : null,
          note: invoice.note,
          transactions: invoice.transactions.map(t =>
            ({
              date: t.date,
              amount: Math.abs(t.amount),
            })
          )
        };
      }, function () {
        $scope.loading = false;
        toastr.error("Impossibile caricare la fattura d'acquisto");
      });
    };
    $scope.getData();

    function calculateGrossTotal() {
      if (!!$scope.purchaseInvoice)
        $scope.purchaseInvoice.grossTotal = 
          $scope.purchaseInvoice.netTotal + 
          $scope.purchaseInvoice.taxes;
    }
    $scope.$watch('purchaseInvoice.netTotal', calculateGrossTotal);
    $scope.$watch('purchaseInvoice.taxes', calculateGrossTotal);

    function updateCalculatedTransactions() {
      if (!$scope.paymentTerms || !$scope.purchaseInvoice)
        return;
      const paymentTerm = $scope.paymentTerms.find(x => x.id == $scope.purchaseInvoice.paymentTermId);
      $scope.calculatedTransactions = (!!$scope.purchaseInvoice.supplierId)
        ? Transaction.generateFrom(paymentTerm, $scope.purchaseInvoice.date, $scope.purchaseInvoice.grossTotal)
        : [];
      $scope.adjustTransactionsCount();
    }
    $scope.transactionsMatch = () => {
      if (!$scope.calculatedTransactions || !$scope.purchaseInvoice)
        return false;
      if ($scope.calculatedTransactions.length != $scope.purchaseInvoice.transactions.length)
        return false;
      const areEqual = $scope.calculatedTransactions.map(trans =>
        !!$scope.purchaseInvoice.transactions.find(x => x.date == trans.date && x.amount == trans.amount)
      ).reduce((a, b) => a && b, true);
      return areEqual;
    };
    $scope.adjustTransactionsCount = () => {
      const targetLength = $scope.calculatedTransactions.length;
      const currentLength = $scope.purchaseInvoice.transactions.length;
      if (currentLength > targetLength) {
        const itemsToRemoveCount = currentLength - targetLength;
        $scope.purchaseInvoice.transactions
          .splice(currentLength - itemsToRemoveCount, itemsToRemoveCount);
      }
      if (currentLength < targetLength) {
        const itemsToAddCount = targetLength - currentLength;
        const itemsToAdd = Array(itemsToAddCount).fill({ date: null, amount: null });
        $scope.purchaseInvoice.transactions = [
          ...$scope.purchaseInvoice.transactions,
          ...itemsToAdd,
        ];
      }
      $scope.purchaseInvoice.transactions.forEach((v, i) => {
        v.amount = $scope.calculatedTransactions[i].amount;
      });
    };
    $scope.adjustTransactions = () => {
      $scope.purchaseInvoice.transactions = angular.copy($scope.calculatedTransactions);
    };
    $scope.$watch('paymentTerms', updateCalculatedTransactions);
    $scope.$watch('purchaseInvoice.supplierId', updateCalculatedTransactions);
    $scope.$watch('purchaseInvoice.date', updateCalculatedTransactions);
    $scope.$watch('purchaseInvoice.grossTotal', updateCalculatedTransactions);
    $scope.$watch('purchaseInvoice.paymentTermId', updateCalculatedTransactions);

    function adjustSign(type, amount) {
      return (type === 'CREDIT_NOTE')
        ? -amount
        : amount;
    }

    $scope.save = function() {
      newApiClient.call(`
        mutation($id: Int!, $input: UpdatePurchaseInvoiceInput!) {
          purchaseInvoices {
            updatePurchaseInvoice(purchaseInvoiceId: $id, input: $input) {
              id
            }
          }
        }`,
        { 
          id: parseInt($scope.purchaseInvoiceId), 
          input: {
            type: $scope.purchaseInvoice.type,
            code: $scope.purchaseInvoice.code,
            date: $scope.purchaseInvoice.date,
            netTotal: adjustSign($scope.purchaseInvoice.type, $scope.purchaseInvoice.netTotal),
            taxes: adjustSign($scope.purchaseInvoice.type, $scope.purchaseInvoice.taxes),
            categoryId: parseInt($scope.purchaseInvoice.categoryId),
            paymentTermId: parseInt($scope.purchaseInvoice.paymentTermId),
            paymentMethodId: parseInt($scope.purchaseInvoice.paymentMethodId),
            supplierId: (!!$scope.purchaseInvoice.supplierId) 
              ? parseInt($scope.purchaseInvoice.supplierId)
              : null,
            note: (!!$scope.purchaseInvoice.note)
              ? $scope.purchaseInvoice.note
              : null,
            transactions: $scope.purchaseInvoice.transactions.map(trans => ({
              date: trans.date,
              amount: adjustSign($scope.purchaseInvoice.type, trans.amount),
              paymentMethodId: parseInt($scope.purchaseInvoice.paymentMethodId),
            })),
          }
        },
      ).then(function(data) {
        $scope.loading = false;
        toastr.success("Fattura modificata");
        $state.go('elencofattureacquisto');
      }, function (errors) {
        $scope.loading = false;
        toastr.error(errors[0].extensions.userMessage, "Impossibile modificare fattura", { timeOut: 0, extendedTimeOut: 0 });
      });
    }

    $scope.updateFatturaCategory = function() {
      if (!$scope.purchaseInvoice.supplierId)
        return;
        
      newApiClient.call(`
        query($id: Int!) {
          companies {
            company(id: $id) {
              purchaseInvoiceCategory { id }
            }
          }
        }`,
        { id: parseInt($scope.purchaseInvoice.supplierId) },
      ).then(function(data) {
        $scope.loading = false;
        $scope.purchaseInvoice.categoryId = data.companies.company.purchaseInvoiceCategory?.id;
      }, function () {
        $scope.loading = false;
        toastr.error("Impossibile caricare la categoria fattura d'acquisto per il cliente");
      });
    }
  }
