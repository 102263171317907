import * as angular from 'angular';
import * as moment from 'moment';

  angular
    .module('screeningcanvas')
    .controller('ProjectsModalAcceptCtrl', ProjectsModalAcceptCtrl);

    ProjectsModalAcceptCtrl.$inject = ['$scope', '$uibModalInstance'];

    function ProjectsModalAcceptCtrl($scope, $uibModalInstance) {
      $scope.data = { date: moment().format("YYYY-MM-DD") };
      $scope.save = function () {
        $uibModalInstance.close($scope.data);
      };
      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };
    }
