import * as angular from 'angular';
import { parseDuration } from '../utils';

angular
  .module('screeningcanvas')
  .filter('formatDuration', function () {
    return function (input) {
      if (!input)
        return null;
      const duration = parseDuration(input);
      return (duration.minutes() > 0)
        ? `${Math.floor(duration.asHours())}h ${duration.minutes()}m`
        : `${Math.floor(duration.asHours())}h`;
    };
  });