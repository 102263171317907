import { Component, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import * as angular from 'angular';

@Component({
  selector: 'boolean-icon',
  templateUrl: 'new/shared/components/boolean-icon.component.html',
})
export class BooleanIcon {
  @Input() value: boolean;
}

angular.module('screeningcanvas').directive('booleanIcon', downgradeComponent({ 
  component: BooleanIcon,
  inputs: ['value'], 
}));