import * as angular from 'angular';
import toastr from 'toastr';
import { Js } from '../utils';

angular
  .module('screeningcanvas')
  .directive('companySelector', ['newApiClient', function (newApiClient) {
    return {
      restrict: 'A',
      scope: {
        optional: '=?',
        optionalText: '@',
        excludeIds: '=?',
      },
      template: `
        <option ng-if="optional" value="">{{ (!!optionalText) ? optionalText : "Tutte le aziende" }}</option>
        <option value="{{ item.id }}" ng-repeat="item in items">
          {{ item.text }}
        </option>
      `,
      replace: false,
      controller: ['$scope', function ($scope) {
        $scope.items = [];

        $scope.fetch = function () {
          newApiClient.call(`
            query($filter: CompanyFilter!, $order: CompanyOrder!, $pagination: Pagination!) {
              companies {
                allCompanies {
                  list(filter: $filter, order: $order, pagination: $pagination) {
                    id
                    name
                  }
                }
              }
            }`,
            {
              filter: {},
              order: 'NAME_ASC',
              pagination: { skip: 0, take: 10000 }
            },
          ).then(function (data) {
            $scope.items = data.companies.allCompanies.list
              .filter(x => Js.isNull($scope.excludeIds) || $scope.excludeIds.length == 0 || !$scope.excludeIds.includes(x.id))
              .map(x => {
                return {
                  id: x.id,
                  text: x.name,
                };
              });
          }, function () {
            toastr.error("Impossibile caricare le aziende");
          });
        };

        $scope.fetch();
      }],
    };
  }]);
