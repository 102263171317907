import * as angular from 'angular';
import * as moment from 'moment';
import toastr from 'toastr';

angular
  .module('screeningcanvas')
  .controller('TicketsCtrl', TicketsCtrl);

TicketsCtrl.$inject = ['$scope', '$uibModal', 'newApiClient', 'User'];

function TicketsCtrl($scope, $uibModal, newApiClient, User) {
  const user = User.getUser();

  $scope.count = 0;
  $scope.paginator = {
    page: 0,
    perPage: 25,
  };
  $scope.pagesCount = () => Math.ceil($scope.count / $scope.paginator.perPage);

  $scope.filter = {
    hasQuantityAsBillableHours: true,
    memberUserIds: [user.id],
  };
  $scope.projects = [];

  $scope.init = function (page = 0, loading = true) {
    page = (page == null) ? $scope.paginator.page : page;
    $scope.loading = loading;
    newApiClient.call(`
          query($filter: ProjectFilter!, $pagination: Pagination!) {
            projects {
              allProjects {
                list(filter: $filter, pagination: $pagination, order: CREATED_AT_DESC) {
                  id
                  title
                  folderCode
                  status {
                    type
                    updatedAt
                  }
                  customer {
                    id
                    name
                  }
                  price
                  pricing {
                    unitPrice
                  }
                  quantity
                  note
                  createdAt
                }
                count(filter: $filter)
              }
            }
          }
        `, {
      filter: $scope.filter,
      pagination: {
        take: $scope.paginator.perPage,
        skip: $scope.paginator.perPage * page,
      },
    }).then(function (data) {
      $scope.loading = false;
      $scope.projects = data.projects.allProjects.list;
      $scope.count = data.projects.allProjects.count;
      $scope.paginator.page = page;
    }, function (error) {
      $scope.loading = false;
      toastr.error("Non è stato possibile caricare i progetti");
    });
  };

  $scope.showAll = function () {
    $scope.filter.memberUserIds = [];
    $scope.init();
  };

  $scope.showMine = function () {
    $scope.filter.memberUserIds = [user.id];
    $scope.init();
  };

  $scope.openEditModal = function (proj) {
    $uibModal.open({
      templateUrl: 'templates/tickets/modal_edit.html',
      controller: 'ModalEditTicketCtrl',
      resolve: {
        title: function () { return 'Modifica ticket'; },
        data: function () {
          return {
            projectId: proj.id,
            title: proj.title,
            note: proj.note,
            customerId: proj.customer.id,
            pricing: {
              unitPrice: proj.pricing.unitPrice,
            },
            unit: 'HOUR',
          };
        }
      },
    }).result.then(function (input) {
      newApiClient.call(`
            mutation($input: UpdateProjectInput!) {
              projects {
                updateProject(input: $input) {
                  id
                }
              }
            }
          `,
        { input: input }).then(function (response) {
          toastr.success('Ticket modificato!');
          $scope.init();
        }, function (error) {
          toastr.error('Non è stato possibile modificare il ticket!');
          console.error(error);
        });
    });
  };

  $scope.openNewModal = function () {
    $uibModal.open({
      templateUrl: 'templates/tickets/modal_edit.html',
      controller: 'ModalEditTicketCtrl',
      resolve: {
        title: function () { return 'Creazione ticket'; },
        data: function () {
          return {
            title: '',
            note: '',
            customerId: null,
            quantity: 0,
            quantityAsBillableHours: true,
            projectTypeId: 19, // Ticket
            pricing: {
              unitPrice: 50,
            },
            unit: 'HOUR',
            memberships: [
              { isReferent: true, userId: user.id },
            ],
            deadline: moment().format('YYYY-MM-DD'),
          };
        }
      },
    }).result.then(function (input) {
      newApiClient.call(`
            mutation($input: CreateProjectInput!) {
              projects {
                createProject(input: $input) {
                  id
                }
              }
            }
          `,
        { input: input }).then(function (response) {
          toastr.success('Ticket creato!');
          $scope.init();
        }, function (error) {
          toastr.error('Non è stato possibile creare il ticket!');
          console.error(error);
        });
    });
  };

  $scope.updateProjectStatus = function (ticket, statusType) {
    $uibModal.open({
      templateUrl: 'templates/tickets/modal_chiudi.html',
      controller: 'ModalChiusuraCtrl',
      resolve: {
        statusType: function () { return statusType; },
      },
    }).result.then(function (data) {
      newApiClient.call(`
            mutation ($input: UpdateProjectInput!) {
              projects {
                updateProject(input: $input) {
                  id
                }
              }
            }
          `, {
        input: {
          projectId: parseInt(ticket.id),
          status: {
            type: statusType,
            date: statusType != 'OPEN'
              ? moment().format('YYYY-MM-DD')
              : null,
          },
        }
      }).then(function (data) {
        toastr.success("Stato ticket aggiornato");
        $scope.init();
      }, function (error) {
        toastr.error("Impossibile modificare stoto del ticket");
        console.error(error);
      });
    });
  };

  $scope.showMine();
}
