import * as angular from 'angular';

angular
  .module('screeningcanvas')
  .directive('waybillMeanSelector', function () {
    return {
      restrict: 'A',
      scope: {
        optional: '=?',
        optionalText: '@',
      },
      template: '<option ng-if="optional" value="">{{ (!!optionalText) ? optionalText : "Seleziona..." }}</option><option value="{{ item.key }}" ng-repeat="item in items">{{ item.name }}</option>',
      replace: false,
      controller: ['$scope', function ($scope) {
        $scope.items = [
          { name: 'Mittente', key: 'SENDER' },
          { name: 'Destinatario', key: 'RECIPIENT' },
        ];
      }],
    };
  });
