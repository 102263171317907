import * as angular from 'angular';
import Inputmask from 'inputmask';

angular
  .module('screeningcanvas')
  .directive('vatMask', function () {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attrs, ngModelCtrl) {
        Inputmask("99", {
          numericInput: true,
          greedy: false,
          placeholder: '',
        }).mask(element);

        ngModelCtrl.$formatters.push(function (value) {
          return value.toFixed(0).toString();
        });
        ngModelCtrl.$parsers.push(function (value) {
          return parseFloat(value.replace(/_/g, ''));
        });
      }
    };
  });
